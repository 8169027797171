import React, { ChangeEvent, ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import { useField } from 'formik'

import Button from '@objects/button'
import Icon from '@objects/icon'
import { makeStyles } from '@material-ui/core/styles'
import { InputLabel } from '@material-ui/core'
import FormHelperText from '@objects/formfields/helperText'
import { func } from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '16px',
    lineHeight: '1.65',
    display: 'flex',
  },
  labelWrapper: {
    position: 'relative',
    flexGrow: 1,
    marginRight: '16px',
    display: ' flex',
  },
  label: {
    flexGrow: 1,
    background: theme.palette.background.default,
    color: theme.palette.text.hint,
    fontSize: '16px',
    lineHeight: '1.65',
    padding: '10px',
  },
  closeButtonPlaceholder: {
    fontSize: '16px',
    lineHeight: 1.65,
    background: theme.palette.background.default,
    height: '46px',
    width: '50px',
  },
  fileAddedLabel: {
    color: theme.palette.text.primary,
  },
  fileFieldWrapper: {
    position: 'relative',
  },
  hiddenFileField: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 'calc(100% - 30px)',
    height: '100%',
    opacity: 0,
    zIndex: 1,
  },
  errorMsg: {
    color: theme.palette.error.dark,
  },
  removeFileBtn: {
    position: 'absolute',
    right: '0',
    top: '50%',
    height: '46px',
    transform: 'translateY(-50%)',
    border: 0,
    background: 'none',
    cursor: 'pointer',
    zIndex: 1000,
  },
  placeholder: {},
}))

type FileFieldProps = RvG.IReactDefaultProps &
  React.HTMLProps<HTMLInputElement> & {
    infoText?: string | undefined
    label: string
    id: string
    name: string
  }

export default function FileField({ ...props }: FileFieldProps): ReactElement {
  const { label, name, id, infoText, placeholder, ...other } = props
  const [field, meta, helper] = useField(name)
  const classes = useStyles()

  function onFileChange(event: ChangeEvent<HTMLInputElement>) {
    const file = event?.currentTarget?.files

    if (file?.length) {
      helper.setValue(file[0])
      helper.setTouched(true)
    } else {
      helper.setValue([])
    }
  }

  function removeFile() {
    helper.setValue([])
  }

  return (
    <>
      <div className={classes.root}>
        {label && (
          <div className={classes.labelWrapper}>
            <InputLabel
              className={clsx(classes.label, {
                [classes.fileAddedLabel]: !!field.value?.name,
              })}
              htmlFor={id}
            >
              {field.value?.name || label}
            </InputLabel>
            {field.value?.name && (
              <>
                <div className={classes.closeButtonPlaceholder} />
                <button
                  className={classes.removeFileBtn}
                  onClick={(event) => {
                    removeFile()
                    event.preventDefault()
                    event.stopPropagation()
                    event.nativeEvent.stopImmediatePropagation()
                  }}
                >
                  <Icon name="Close" />
                </button>
              </>
            )}
          </div>
        )}
        <div>
          <div
            className={clsx(classes.fileFieldWrapper, {
              error: meta.error && meta.touched,
            })}
          >
            <input
              className={classes.hiddenFileField}
              id={id}
              type="file"
              accept=".png, .jpeg, .jpg, .gif, .svg"
              aria-invalid={!!meta.error}
              onChange={(event) => {
                onFileChange(event)
              }}
              {...other}
            />
          </div>
          <Button type="label" htmlFor={id}>
            <FormattedMessage id="form.file.upload" />
          </Button>
        </div>
        {infoText && (
          <FormHelperText type="info" spaced>
            {infoText}
          </FormHelperText>
        )}
        {meta.touched && meta.error ? (
          <FormHelperText className={classes.errorMsg}>
            {meta.error}
          </FormHelperText>
        ) : null}
      </div>
    </>
  )
}

import { useContext } from 'react'

import {
  ToggleComponentContext,
  IToggleComponentContext,
} from '@providers/toggleComponentProvider'

export default function useToggleComp(): IToggleComponentContext {
  const { OverlayIsActive, toggleOverlay, customProps } = useContext(
    ToggleComponentContext
  )

  return { OverlayIsActive, toggleOverlay, customProps }
}

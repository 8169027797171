import React, { ReactElement, useEffect, useRef } from 'react'
import clsx from 'clsx'

import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'

import useGlobalText from '@hooks/useGlobalText'
import { useTracking, useToggleComp } from '@hooks/index'
import Search from './types/search'
import Popup from './types/popup'
import Gallery from './types/gallery'
import DownloadShopOverlay from './types/downloadShop'
import QuizKnowledgeOverlay from './types/quizKnowledge'
import Button from '@objects/button'
import { PaperProps } from '@material-ui/core'
import MaterialShopOverlay from './types/materialShop'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  close: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(4),
    '.dark &': {
      color: 'white',
    },
  },
  closeFullscreen: {
    [theme.breakpoints.up('xl')]: {
      top: theme.spacing(12),
      right: '50%',
      transform: `translateX(calc(${theme.container.lg} / 2))`,
    },
    '&:focus': {
      border: '2px dotted currentColor',
      outline: 'none',
      borderRadius: '0',
    },
  },
  container: {
    position: 'relative',
    width: '100%',
  },
}))

export type OverlayProps = RvG.IReactDefaultProps

export default function Overlay({ className }: OverlayProps): ReactElement {
  const overlayRef = useRef(null)
  const { PushMessage } = useTracking()
  const { OverlayIsActive, toggleOverlay, customProps } = useToggleComp()
  const classes = useStyles()
  const { getText } = useGlobalText()
  const intl = useIntl()

  useEffect(() => {
    if (OverlayIsActive) {
      PushMessage('custom.overlay-show', {
        message: customProps.trackingID,
      })
    }
  }, [OverlayIsActive])

  const handleClose = () => {
    toggleOverlay(false)
  }

  function renderOverlayContent() {
    switch (customProps.type) {
      case 'search':
        return <Search index={customProps.index} />
      case 'gallery':
        return (
          <Gallery {...customProps.galleryProps} handleClose={handleClose} />
        )
      case 'downloadShop':
        return (
          <DownloadShopOverlay
            {...customProps.downloadProps}
            handleClose={handleClose}
          />
        )
      case 'materialShop':
        return customProps.materialShopProps ? (
          <MaterialShopOverlay
            {...customProps.materialShopProps}
            handleClose={handleClose}
          />
        ) : null
      case 'quiz knowledge':
        return (
          <QuizKnowledgeOverlay
            {...customProps.quizKnowledgeProps}
            handleClose={handleClose}
          />
        )
      default:
        return (
          <Popup
            contentType={customProps.type}
            onAcceptance={customProps.onAcceptance}
          />
        )
    }
  }

  const paperProps = () => {
    let props: Partial<PaperProps> = { className: 'light' }
    if (customProps.paperProps) {
      props = customProps.paperProps
    }
    return props
  }

  return (
    <Dialog
      ref={overlayRef}
      data-testid="overlay"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className={clsx(className, {
        active: OverlayIsActive,
      })}
      onClose={handleClose}
      open={OverlayIsActive}
      keepMounted
      fullScreen={customProps.dialogType === 'fullscreen'}
      maxWidth={customProps.width || 'lg'}
      fullWidth
      disableBackdropClick={
        !customProps?.type || customProps.type !== 'gallery'
      }
      onBackdropClick={handleClose}
      PaperProps={paperProps()}
      aria-label={intl.formatMessage({ id: 'overlay.dialog.aria.label' })}
    >
      {customProps.closeButton !== 'hide' && (
        <Button
          className={clsx(classes.close, {
            [classes.closeFullscreen]: customProps.dialogType === 'fullscreen',
          })}
          onClick={handleClose}
          icon="Close"
          iconPosition="right"
          type="underlined"
        >
          {customProps.dialogType === 'fullscreen' ? getText('closeText') : ''}
        </Button>
      )}

      {OverlayIsActive && renderOverlayContent()}
    </Dialog>
  )
}

import { FeedbackFormValues } from '@/types/feedback'
import React, { ReactElement, useState } from 'react'

export interface IFeedbackContext {
  setFormData: React.Dispatch<React.SetStateAction<FeedbackFormValues[]>>
  formData: FeedbackFormValues[]
  setFriendlyCaptcha: React.Dispatch<React.SetStateAction<string>>
  friendlyCaptcha: string
}

const FeedbackFormContext = React.createContext<IFeedbackContext>({
  setFormData: () => {
    return null
  },
  formData: [],
  setFriendlyCaptcha: () => {
    return null
  },
  friendlyCaptcha: '',
})

export type IFeedbackFormProviderProps = {
  children: React.ReactElement
}

export function FeedbackFormProvider({
  children,
}: IFeedbackFormProviderProps): ReactElement {
  const [formData, setFormData] = useState<FeedbackFormValues[]>(() => {
    return []
  })
  const [friendlyCaptcha, setFriendlyCaptcha] = useState<string>('')

  return (
    <FeedbackFormContext.Provider
      value={{
        setFormData,
        formData,
        setFriendlyCaptcha,
        friendlyCaptcha,
      }}
    >
      {children}
    </FeedbackFormContext.Provider>
  )
}

export default FeedbackFormContext

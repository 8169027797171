import React, { ReactElement, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@objects/icon'
import clsx from 'clsx'
import { useIntl, FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

const useStyles = makeStyles(
  (theme): Record<string, CSSProperties> => ({
    postInput: {
      width: '100%',
      position: 'relative',
      height: '48px',
      marginBottom: theme.spacing(7),
    },
    nomargin: {
      marginBottom: 0,
    },
    postInputField: {
      width: '100%',
      height: '48px',
      border: `2px solid ${theme.palette.background.grey}`,
      borderRadius: '24px',
      padding: theme.spacing(0, 5),
      outline: 'none',
      fontSize: '16px',
      cursor: 'pointer',

      '&:focus': {
        border: `${theme.palette.background.grey} 2px solid`,
      },
    },
    postInputIcon: {
      position: 'absolute',
      right: theme.spacing(5),
      top: '50%',
      transform: 'translateY(-50%)',
      color: theme.palette.background.grey,
    },
    netiquetteInfo: {
      // visibility: 'hidden',
      opacity: 0,
      transition: 'opacity 0.3s ease',
      color: theme.palette.text.hint,
      marginLeft: theme.spacing(6),
      marginTop: theme.spacing(2),
    },
    infoIcon: {
      marginRight: theme.spacing(2),
      top: theme.spacing(1),
      position: 'relative',
    },
    link: {
      color: theme.palette.error.light,
      fontWeight: 'bold',
    },
    show: {
      visibility: 'visible',
      opacity: 1,
    },
  })
)

export type PostInputFieldProps = RvG.IReactDefaultProps & {
  nomargin?: boolean
  placeholder?: string
}

export default function PostInputField({
  placeholder,
  nomargin,
}: PostInputFieldProps): ReactElement {
  const classes = useStyles()
  const intl = useIntl()
  const [focus, setFocus] = useState(false)

  return (
    <div
      className={clsx(classes.postInput, {
        [classes.nomargin]: nomargin,
      })}
    >
      <input
        placeholder={placeholder}
        className={classes.postInputField}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <Icon className={classes.postInputIcon} name={'Pen'} />
      <div
        className={clsx(classes.netiquetteInfo, {
          [classes.show]: focus,
        })}
      >
        <Icon className={classes.infoIcon} name={'Info'} />
        <FormattedMessage
          id={'dialogue.observe.netiquette'}
          values={{
            a: function netiquetteLink2(netiquette: string) {
              return (
                <Link
                  className={classes.link}
                  to={intl.formatMessage({ id: 'netiquette.path' })}
                >
                  {netiquette}
                </Link>
              )
            },
          }}
        />
      </div>
    </div>
  )
}

import { useEffect } from 'react'
import { useFormikContext } from 'formik'

export default function ScrollToFormikFieldError() {
  const { errors, isSubmitting, isValidating } = useFormikContext()

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      let keys = Object.keys(errors)
      if (keys.length > 0) {
        let firstErrKey = Object.keys(errors[keys[0]])[0]
        const selector = `${keys[0]}.${firstErrKey}`
        const errorElement = document.getElementById(selector).getBoundingClientRect().top
        if (errorElement !== null) {
          const offset = window.scrollY + errorElement - 80
          window.scrollTo({top: offset, behavior: 'smooth'})
        }
      }
    }
  }, [errors, isSubmitting, isValidating])

  return null
}

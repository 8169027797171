import React, { useState, useEffect, useRef, ReactElement } from 'react'
import clsx from 'clsx'
import MUIRichTextEditor from 'mui-rte'
import {
  convertFromHTML,
  ContentState,
  convertToRaw,
  EditorState,
} from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { useField } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'

import { TMUIRichTextEditorRef } from 'mui-rte'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  richtext: {
    position: 'relative',
    cursor: 'pointer',
    '& .MuiIconButton-root': {
      padding: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:first-of-type': {
        marginLeft: theme.spacing(2),
      },
      '&:not(:first-of-type)': {
        position: 'relative',
        '&:before': {
          content: '""',
          cursor: 'pointer',
          position: 'absolute',
          left: '-5px',
          top: '25%',
          height: '50%',
          width: '2px',
          backgroundColor: theme.palette.background.default,
        },
      },
    },
    '& .MuiIconButton-colorPrimary': {
      color: theme.palette.error.light,
    },
    '& .MuiTypography-colorPrimary': {
      color: theme.palette.error.light,
    },
    '& #text-input-editor': {
      border: `1px solid ${theme.palette.text.hint}`,
    },
  },

  errormessage: {
    width: '100%',
    padding: theme.spacing(2.5, 4, 3, 4),
    marginBottom: theme.spacing(4),
    color: theme.palette.error.dark,
    ...theme.typography.helpertext,
  },
  link: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  error: {
    '& $chars': {
      bottom: theme.spacing(13),
    },
    '& > div:first-child': {
      marginBottom: 0,
      '& .MuiPaper-root': {
        color: theme.palette.error.dark,
      },
      '& .MuiIconButton-root': {
        '&:not(:first-of-type)': {
          '&:before': {
            backgroundColor: theme.palette.error.dark,
          },
        },
      },
    },
  },
  focus: {
    '& > div:first-child': {
      borderColor: theme.palette.text.input,
    },
  },
  chars: {
    position: 'absolute',
    right: theme.spacing(3),
    bottom: theme.spacing(2.5),
    color: theme.palette.text.input,
    ...theme.typography.body1,
    [theme.breakpoints.up('sm')]: {
      top: 'auto',
      bottom: theme.spacing(2.5),
    },
  },
  inputlabel: {
    transition: 'all 0.2s ease',
    ...theme.typography.label,
    padding: theme.spacing(4),
    color: theme.palette.text.hint,
    pointerEvents: 'none',
    position: 'absolute',
    fontSize: '16px',
    zIndex: 1,
    '&$reducedLabel': {
      padding: 0,
      transform: 'translate(0px, -25px)',
      fontSize: '12px',
      [theme.breakpoints.down('lg')]: {
        transform: 'translate(0px, -23px)',
        fontSize: '10px',
      },
    },
  },
  longLabel: {
    '& + div > div > div > :nth-child(2)': {
      minHeight: '10em',
    },
  },
  reducedLabel: {},
}))

export type RichTextEditorProps = RvG.IReactDefaultProps & {
  id: string
  label?: string
  placeholderLabel?: string
  name: string
  maxLength: number | 20000
  showInfoText?: boolean | false
  isLongLabel?: boolean | false
}

export default function RichTextEditor({
  id,
  label,
  placeholderLabel,
  name,
  maxLength,
  showInfoText,
  isLongLabel,
  ...props
}: RichTextEditorProps): ReactElement {
  const classes = useStyles()
  const [char, setChar] = useState(maxLength)
  const [initialized, setInitialized] = useState(false)
  const [defaultValue, setDefaultValue] = useState('')
  const [focused, setFocused] = useState(false)
  const [field, meta, helper] = useField(name)
  const itemDescId = `${name?.replace(/\s+/g, '').toLowerCase()}_error_desc`

  const rtRef = useRef<TMUIRichTextEditorRef>(null)

  function rteChange(data: EditorState) {
    const text = stateToHTML(data.getCurrentContent())
    helper.setValue(text === '<p><br></p>' ? '' : text, true)
    setChar(maxLength - data.getCurrentContent().getPlainText().length)
  }

  function rteBlur() {
    setFocused(false)
  }

  function rteFocus() {
    setFocused(true)
  }

  function setFocus() {
    rtRef.current?.focus()
  }

  function parseValue(value: string): string {
    if (undefined === value) {
      return ''
    }
    const contentHTML = convertFromHTML(value)
    const state = ContentState.createFromBlockArray(
      contentHTML.contentBlocks,
      contentHTML.entityMap
    )
    return JSON.stringify(convertToRaw(state))
  }

  useEffect(() => {
    if (field.value !== '' && !initialized) {
      setDefaultValue(parseValue(field.value))
    }
    setInitialized(true)
  }, [])

  useEffect(() => {
    if (field.value === '' && initialized) {
      // delete content of field, when form is reset with initial values
      setDefaultValue(parseValue(field.value))
    }
  }, [field.value])

  return (
    <div className={classes.root}>
      {label && (
        <InputLabel
          className={clsx(classes.inputlabel, {
            [classes.reducedLabel]: field.value || focused,
            [classes.longLabel]: !!isLongLabel,
          })}
          onClick={setFocus}
          htmlFor={`${id}-editor`}
        >
          {label}
        </InputLabel>
      )}
      <div
        className={clsx(classes.richtext, {
          [classes.error]: meta.touched && meta.error,
          [classes.focus]: focused,
        })}
        style={{
          fontWeight: '700',
        }}
      >
        <MUIRichTextEditor
          id={id}
          ref={rtRef}
          controls={['bold', 'italic', 'link', 'numberList', 'bulletList']}
          maxLength={maxLength}
          inlineToolbar={true}
          inlineToolbarControls={['bold', 'italic', 'link']}
          onChange={rteChange}
          onBlur={rteBlur}
          onFocus={rteFocus}
          defaultValue={defaultValue}
          {...props}
        />
        <div className={classes.chars}>
          <FormattedMessage id={'RTE.count'} values={{ char }} />
        </div>
        {meta.touched && meta.error && (
          <div className={classes.errormessage} aria-describedby={itemDescId}>
            {meta.error}
          </div>
        )}
      </div>
    </div>
  )
}

import React, { ReactElement } from 'react'
import {
  makeStyles,
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles'
import clsx from 'clsx'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Headline from '@objects/headline'
import Icon from '@objects/icon'
import Container from '@objects/container'
import Copy from '@components/copy'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(20, 0),
    },
  },
  text: {
    [theme.breakpoints.up('lg')]: {
      width: '66.666%',
    },
  },
  copy: {
    [theme.breakpoints.up('lg')]: {
      width: '80%',
    },
  },
  tableHeadline: {},
  table: {
    width: '100%',
    minWidth: 650,
  },
  legend: {
    margin: theme.spacing(8, 0, 0, 0),
    '& a': {
      ...theme.mixins.linkStyleUnderlined,
    },
  },
  hook: {
    color: theme.palette.primary.main,
  },
  cross: {
    color: theme.palette.error.dark,
  },
}))

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.background.greyDark,
      color: theme.palette.text.invert,
      fontWeight: 700,
      padding: theme.spacing(2, 4),
      '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
      },
      '&:last-child': {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      },
    },
    body: {
      border: 0,
      padding: theme.spacing(2, 4),
      overflow: 'hidden',
      '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
      },
      '&:last-child': {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      },
    },
  })
)(TableCell)

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: 700,

      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.background.light,
      },
    },
  })
)(TableRow)

export type TableProps = RvG.IReactDefaultProps & {
  hasContainer?: boolean
  headline?: string
  copy?: RvG.Contentful.BasicRichTextType
  table: {
    tableData: Array<Array<string>>
  }
  tableCellAlignment?: 'left' | 'center' | 'right'
  legend?: RvG.Contentful.BasicRichTextType
}

export default function TableRoot({
  hasContainer,
  headline,
  copy,
  table,
  tableCellAlignment,
  legend,
  className,
}: TableProps): ReactElement {
  const { tableData } = table
  const classes = useStyles()
  const tableHead = tableData[0]
  const tableBody = tableData.slice(1)
  const alignment = tableCellAlignment ? tableCellAlignment : 'left'
  const intl = useIntl()

  function getIcon(item: string): ReactElement {
    if (item === '--') {
      return <Icon name="ChartNoCheck" className={classes.cross} />
    }
    return <Icon name="ChartCheck" className={classes.hook} />
  }

  function InnerComponent() {
    return (
      <div className={clsx(className, classes.root)}>
        {headline && (
          <Headline className={classes.tableHeadline}>{headline}</Headline>
        )}
        {copy && <Copy className={classes.copy} richtext={copy} />}

        <TableContainer>
          <Table
            className={classes.table}
            aria-label={intl.formatMessage({ id: 'table.aria.label' })}
            role="table"
          >
            <TableHead>
              <TableRow>
                {tableHead.map((item: string, i: number) => (
                  <StyledTableCell
                    key={`table-head-row-${i}`}
                    align={i > 0 ? alignment : 'left'}
                  >
                    {item}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableBody.map((tableBodyRow: string[], i: number) => (
                <StyledTableRow key={`table-body-row-${i}`}>
                  {tableBodyRow.map((item: string, k: number) => (
                    <StyledTableCell
                      key={`table-head-cell-${k}`}
                      align={k > 0 ? alignment : 'left'}
                    >
                      {item === '++' || item === '--' ? getIcon(item) : item}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {legend && (
          <Copy
            className={clsx(classes.text, classes.legend)}
            richtext={legend}
          />
        )}
      </div>
    )
  }

  return hasContainer ? (
    <Container type="nomargin">
      <InnerComponent />
    </Container>
  ) : (
    <InnerComponent />
  )
}

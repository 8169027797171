import React, {
  useState,
  useEffect,
  ReactElement,
  KeyboardEvent,
  SyntheticEvent,
  useRef,
} from 'react'
import clsx from 'clsx'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'

import { INavItemData } from './drawerNav'
import SubNav from './horizontalSubNav'

import Icon from '@objects/icon'
import Typography from '@material-ui/core/Typography'
import Copy from '@components/copy'

export interface INavItem {
  item: INavItemData
  isOpen: boolean
  isFocused: boolean
  toggleMenu: (open: boolean) => void
  tabIndex: number
}

export interface IMenuState {
  open: boolean
  trigger: 'default' | 'key' | 'keyDown' | 'keyUp' | 'keyDownInitial'
}

const useStyles = makeStyles((theme) => ({
  navItemRoot: {
    '&:not(:first-child)': {
      marginLeft: theme.spacing(10),
    },
    '& > $item': {
      whiteSpace: 'nowrap',
      '&:focus $label, & $label:focus': {
        borderBottomColor: 'currentColor',
        //textDecoration: 'underline',
      },
    },
    '&:hover > $item': {
      '&, & $label, & $label:hover, & $icon': {
        textDecoration: 'none',
      },
    },
  },
  item: {
    display: 'inline-block',
    outline: 'none',
    ...theme.typography.navigation,
  },
  link: {
    position: 'relative',
    borderBottom: '2px dotted transparent',
    '&:after': {
      content: `''`,
      position: 'absolute',
      left: 0,
      bottom: -2,
      width: '0%',
      height: '2px',
      backgroundColor: theme.palette.button.secondaryTextHover,
      transition: `width .3s ${theme.transitions.easing.easeInOut}`,
    },
    '&:hover': {
      '&:after': {
        width: '100%',
      },
    },
    '&:focus': {
      borderBottom: '2px dotted currentColor',
      outline: 'none',
    },
  },
  label: {
    position: 'relative',
    display: 'inline-block',
    padding: theme.spacing(0, 0, 5, 0),
    cursor: 'pointer',
    '&:after': {
      content: `''`,
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '0%',
      height: theme.spacing(1),
      backgroundColor: theme.palette.button.secondaryTextHover,
      transition: `width 0.3s ${theme.transitions.easing.easeInOut}`,
    },
    '&:hover, :focus > &': {
      '&:after': {
        width: '100%',
      },
    },
  },
  itemOpen: {
    '&:after': {
      width: '100%',
    },
  },
  itemActive: {
    '&:after': {
      width: '100%',
    },
  },
  icon: {
    verticalAlign: '-15%',
  },
  toggle: {
    marginLeft: theme.spacing(1),
  },
  flyout: {
    display: 'flex',
    position: 'absolute',
    background: `linear-gradient(to right, ${theme.palette.background.light} 0%, ${theme.palette.background.light} 50%, ${theme.palette.background.grey} 50%)`,
    left: 0,
    right: 0,
    top: '100%',
    zIndex: 1,
    alignItems: 'stretch',
    maxHeight: 0,
    overflow: 'hidden',
    transition: `max-height 0s ${theme.transitions.easing.easeIn}`,
  },
  flyoutOpen: {
    maxHeight: 1000,
    transition: `max-height 0.6s ${theme.transitions.easing.easeIn}, padding 0.1s ${theme.transitions.easing.easeIn}`,
    padding: theme.spacing(8, 0, 0, 0),
    '& $headline': {
      opacity: 1,
      transition: `opacity 0.3s ${theme.transitions.easing.easeIn} 0.2s`,
    },
    '& $teaserCopy': {
      opacity: 1,
      transition: `opacity 0.3s ${theme.transitions.easing.easeIn} 0.3s`,
    },
    '& $flyoutInner': {
      // padding: theme.spacing(8, 4),
    },
    '@media (prefers-contrast: more)': {
      border: `1px solid ${theme.palette.text.hint}`,
    },
  },
  flyoutInner: {
    display: 'flex',
    width: theme.container.lg,
    padding: theme.spacing(0, 4),
    margin: '0 auto',
    transition: `padding 0.1s ${theme.transitions.easing.easeIn}`,
  },
  copy: {
    width: theme.spacing(125),
    background: theme.palette.background.light,
    padding: theme.spacing(0, 30, 0, 0),
    minHeight: theme.spacing(100),
  },
  headline: {
    opacity: 0.4,
    transition: `opacity 0s ${theme.transitions.easing.easeIn} 0s`,
  },
  teaserCopy: {
    opacity: 0.4,
    transition: `opacity 0s ${theme.transitions.easing.easeIn} 0s`,
  },
  subNav: {
    height: `calc(100% + ${theme.spacing(16)}px)`,
    marginTop: theme.spacing(-8),
  },
}))

export default function NavItem({
  item,
  isFocused,
  isOpen,
  toggleMenu,
  tabIndex,
}: INavItem): ReactElement {
  const classes = useStyles()
  const location = useLocation()
  const [menu, setMenu] = useState<IMenuState>({
    open: isOpen || false,
    trigger: 'default',
  })
  const navItemRef = useRef<HTMLDivElement>(null)
  // TODO: correct GatsbyLink type must be set/ imported somehow
  // not working Link<{}>, Link<unknown>, HTMLAnchorElement
  const navLinkRef = useRef<any>(null)
  const itemLabelId = item.title.replace(/\s+/g, '').toLowerCase()
  const itemDescId = `${item.title.replace(/\s+/g, '').toLowerCase()}_desc`

  useEffect(() => {
    // close menu, when user clicked outside/ other item
    if (!isOpen) {
      setMenu({ open: false, trigger: 'default' })
    }
  }, [isOpen])

  useEffect(() => {
    if (isFocused) {
      navItemRef.current?.focus()
    } else {
      toggleMenu(false)
    }
  }, [isFocused])

  useEffect(() => {
    if (menu.open) {
      if (menu.trigger === 'keyDownInitial') {
        navLinkRef.current?.focus()
      }
    } else {
    }
  }, [menu])

  function handleItemKeyDown(ev: KeyboardEvent) {
    // key ' ' equals spacebar according to spec correct value here
    if ([' ', 'ArrowDown', 'ArrowUp'].includes(ev.key)) {
      if (ev.key === 'ArrowUp') {
        setMenu({ open: true, trigger: 'keyUp' })
      } else {
        setMenu({ open: true, trigger: 'keyDownInitial' })
      }
      toggleMenu(true)
      ev.stopPropagation()
      ev.preventDefault()
    }
  }

  function handleItemClick(ev: SyntheticEvent) {
    if (menu.open) {
      toggleMenu(false)
    } else {
      setMenu({ open: true, trigger: 'default' })
      toggleMenu(true)
      ev.preventDefault()
    }
  }

  function handleLinkKeyDown(ev: KeyboardEvent) {
    // key ' ' equals spacebar according to spec correct value here
    if ([' ', 'ArrowDown', 'ArrowUp'].includes(ev.key)) {
      if (ev.key === 'ArrowUp') {
        setMenu({ open: true, trigger: 'keyUp' })
      } else {
        setMenu({ open: true, trigger: 'keyDown' })
      }
      ev.stopPropagation()
      ev.preventDefault()
    }
  }

  return (
    <li
      role="menuitem"
      aria-label={item.title}
      className={classes.navItemRoot}
      aria-expanded={!!item.nextlevel ? (menu.open ? true : false) : undefined}
    >
      <div
        ref={navItemRef}
        className={clsx(classes.item, {})}
        tabIndex={tabIndex}
        aria-haspopup={!!item.nextlevel ? true : false}
        aria-expanded={
          !!item.nextlevel ? (menu.open ? true : false) : undefined
        }
        onClick={handleItemClick}
        onKeyDown={handleItemKeyDown}
      >
        <span
          className={clsx(classes.label, {
            [classes.itemActive]:
              item.path && location.pathname.startsWith(item.path),
            [classes.itemOpen]: menu.open,
          })}
        >
          {item.title}
        </span>
        <Icon
          className={clsx(classes.icon, classes.toggle)}
          name={menu.open ? 'ChevronUp' : 'ChevronDown'}
        />
      </div>

      <div
        role="none"
        className={clsx(classes.flyout, {
          [classes.flyoutOpen]: menu.open,
        })}
        aria-expanded={
          !!item.nextlevel ? (menu.open ? true : false) : undefined
        }
      >
        <div role="none" className={classes.flyoutInner}>
          <div role="none" className={classes.copy}>
            <Typography
              variant={'h4'}
              component={'p'}
              id={itemLabelId}
              className={clsx(classes.headline)}
            >
              <Link
                className={classes.link}
                ref={navLinkRef}
                to={item.path}
                onClick={() => {
                  toggleMenu(false)
                }}
                onKeyDown={handleLinkKeyDown}
              >
                {item.title}
              </Link>
            </Typography>
            {item.teaserCopy && (
              <Copy
                id={itemDescId}
                type="teaser"
                richtext={item.teaserCopy}
                className={clsx(classes.teaserCopy)}
                ariaRole="paragraph"
              />
            )}
          </div>
          {!!item.nextlevel && (
            <SubNav
              items={item.nextlevel}
              level={2}
              menuState={menu}
              returnFocus={(close: boolean) => {
                if (close) {
                  toggleMenu(false)
                  navItemRef.current?.focus()
                } else {
                  // focus headline
                  navLinkRef.current?.focus()
                }
              }}
              onNavigate={() => toggleMenu(false)}
              isVisible={menu.open}
              aria-labelledby={itemLabelId}
              aria-describedby={itemDescId}
              className={classes.subNav}
            />
          )}
        </div>
      </div>
    </li>
  )
}

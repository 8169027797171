import React, { useState } from 'react'
import { TextareaAutosize, TextareaAutosizeProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useField } from 'formik'

const useStyles = makeStyles((theme) => ({
  textAreaRoot: {
    marginTop: theme.spacing(4),
    position: 'relative',
  },
  textAreaInput: {
    width: '100%',
    maxWidth: '336px',
  },
  textAreaLabelBase: {
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'all 0.2s ease',
    fontSize: '16px',
    pointerEvents: 'none',
  },
  reducedLabel: {
    top: theme.spacing(-4),
    fontSize: '12px',
  },
}))

type TextAreaFieldProps = RvG.IReactDefaultProps &
  TextareaAutosizeProps & {
    name: string
    value?: string
  }

export default function TextAreaField({ ...props }: TextAreaFieldProps) {
  const classes = useStyles()
  const { label, name } = props
  const [focusInput, setFocusInput] = useState(false)
  const [field] = useField(name)

  return (
    <div className={classes.textAreaRoot}>
      {label && (
        <label
          className={clsx(classes.textAreaLabelBase, {
            [classes.reducedLabel]: focusInput || field.value,
          })}
        >
          {label}
        </label>
      )}
      <TextareaAutosize
        className={classes.textAreaInput}
        {...props}
        onFocus={() => setFocusInput(() => true)}
        onBlur={() => setFocusInput(() => false)}
      />
    </div>
  )
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import Container from '@objects/container'
import Teaser from '@objects/teaser'

export type TeaserSingleProps = {
  teaserCopy?: RvG.Contentful.BasicRichTextType
  teaserImage?: RvG.Contentful.IAsset
  teaserTitle?: string
  fields?: {
    fullPath?: string
  }
}

export default function TeaserSingle({
  teaserCopy,
  teaserImage,
  teaserTitle,
  fields,
}: TeaserSingleProps) {
  return (
    <Container>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Teaser
            headline={teaserTitle}
            copy={teaserCopy}
            image={teaserImage}
            fullPath={fields?.fullPath}
            horizontal
          />
        </Grid>
      </Grid>
    </Container>
  )
}

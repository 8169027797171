import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'
import Button from '@objects/button'

import {
  materialShopAddItem,
  $materialShopCart,
  IMaterialShopCartItemValues,
  materialShopConfig,
  $materialShopBigEventsOrdersInCart,
} from '@services/stores/materialShop'
import { FormattedMessage, useIntl } from 'react-intl'
import { useStore } from '@nanostores/react'
import { Formik } from 'formik'
import MaterialShopFormProductDetailsSection from '../materialShopFormProductDetailsSection'

export interface IMaterialShopFormProductDetailsProps {
  id: string
  item: RvG.Contentful.IContentfulModuleMaterialShopItem
  onSubmit: () => void
}

const useStyles = makeStyles((theme) => ({
  base: {},
  footer: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(12),
    },
  },
  sections: {
    marginTop: theme.spacing(8),
    '& > * + *': {
      marginTop: theme.spacing(8),
    },
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    ...theme.typography.h4,
    fontWeight: 700,
    margin: 0,
  },
  buttonDelete: {
    background: 'none',
    color: theme.palette.common.black,
    '& > svg': {
      fontSize: theme.spacing(5.75),
    },
  },
  buttonAddAnother: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    background: 'none',
    color: theme.palette.common.black,
    '& > svg': {
      fontSize: theme.spacing(5.75),
    },
  },
  hintLabel: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  errorText: {
    // color: theme.palette.warning.main,
    margin: 0,
    marginBottom: theme.spacing(6),
    '& a': {
      fontWeight: 700,
      textDecoration: 'underline',
    },
  },
}))

export default function MaterialShopFormProductDetails(
  props: IMaterialShopFormProductDetailsProps
): React.ReactElement {
  const { item, onSubmit } = props
  const intl = useIntl()
  const classes = useStyles()
  const store = useStore($materialShopCart)
  const storeItem = store.items.find((item) => item.id === props.id)
  const formConfig = useMemo(() => {
    const config = materialShopConfig.categories[item.category.identifier]
    if (!config.form) {
      return undefined
    }
    const { labelId, fields, sections } = config.form
    return {
      withSections:
        item.category.identifier === 'bigEvents' ||
        item.category.identifier === 'smallEvents',
      hint: config.hintId
        ? (intl.messages[config.hintId] as string)
        : undefined,
      form: {
        label: labelId ? (intl.messages[labelId] as string) : undefined,
        fields: fields
          ? fields.map((field) => {
              return {
                ...field,
                label: intl.messages[field.labelId] as string,
              }
            })
          : undefined,
        sections: sections
          ? sections.map((section) => {
              return {
                ...section,
                label: intl.messages[section.labelId] as string,
                fields: section.fields.map((field) => {
                  return {
                    ...field,
                    label: intl.messages[field.labelId] as string,
                  }
                }),
              }
            })
          : undefined,
      },
    }
  }, [item])

  const materialShopCart = $materialShopCart.get()
  const allBigEventsSections = useMemo(() => {
    return materialShopCart.items
      .filter((item) => item.category.identifier === 'bigEvents')
      .flatMap((item) => item.values.sections).length
  }, [item, formConfig])

  const maxSections = useMemo(() => {
    if (!formConfig?.withSections) {
      return
    }
    return item.category.identifier === 'bigEvents' ? 3 : item.maxAmount
  }, [item, formConfig])

  useEffect(() => {
    $materialShopBigEventsOrdersInCart.set(allBigEventsSections)
  }, [allBigEventsSections])

  const defaultValues = useMemo(() => {
    if (!formConfig) {
      return {}
    }

    return {
      fields: formConfig.form.fields?.reduce((acc, field) => {
        acc[field.id] = field.defaultValue || ''
        return acc
      }, {} as { [key: string]: string }),
      sections: formConfig.form.sections
        ? [
            formConfig.form.sections.reduce((acc, section) => {
              return {
                ...acc,
                ...section.fields.reduce((acc, field) => {
                  acc[field.id] = field.defaultValue || ''
                  return acc
                }, {} as { [key: string]: string }),
              }
            }, {} as { [key: string]: string }),
          ]
        : undefined,
    }
  }, [formConfig])

  const validationSchema = useMemo(() => {
    const getValidation = (field: any) => {
      let validation = Yup.string().nullable()

      if (field.isRequired) {
        validation = validation.required(
          intl.formatMessage({
            id: `materialshop.form.error.required`,
          })
        )
      }

      if (field.type === 'email') {
        validation = validation.email(
          intl.formatMessage({ id: 'materialshop.form.error.email' })
        )
      }

      if (field.type === 'tel') {
        validation = validation.matches(
          /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/,
          intl.formatMessage({ id: 'materialshop.form.error.digits.matches' })
        )
      }

      if (field.type === 'date') {
        validation = validation
          .matches(
            /(^(((19|[2-9][0-9])\d\d)[-]((0[1-9]|1[012]))[-](0[1-9]|1[0-9]|2[0-8])$))|(^(((19|[2-9][0-9])\d\d)[-]((0[13578]|1[02]))[-](29|30|31)$))|(^(((19|[2-9][0-9])\d\d)[-]((0[4,6,9]|11))[-](29|30)$)|(^(((19|[2-9][0-9])))))/,
            intl.formatMessage({ id: 'materialshop.form.error.date.matches' })
          )
          .nullable()
      }

      return validation
    }

    const fields = formConfig?.form.fields
      ? Yup.object().shape(
          formConfig.form.fields.reduce(
            (acc, field) => {
              return {
                ...acc,
                [field.id]: getValidation(field),
              }
            },

            {}
          )
        )
      : undefined

    const sections = formConfig?.form.sections
      ? Yup.array().of(
          Yup.object().shape(
            formConfig?.form.sections?.reduce((acc, parts) => {
              return {
                ...acc,
                ...parts.fields.reduce((acc, field) => {
                  return {
                    ...acc,
                    [field.id]: getValidation(field),
                  }
                }, {}),
              }
            }, {})
          )
        )
      : undefined

    return Yup.object({
      fields: fields || Yup.object(),
      sections: sections || Yup.array(),
    })
  }, [])

  const [initialValues] = useState<IMaterialShopCartItemValues>({
    fields: storeItem?.values?.fields || defaultValues.fields,
    sections: storeItem?.values?.sections || defaultValues.sections,
  })

  const isExistingSocialMediaOrPressPackage =
    !!storeItem && !!storeItem.orderInCart

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validate={(values) => {
        const errors: any = {}

        switch (item.category.identifier) {
          case 'bigEvents':
            if (
              maxSections &&
              maxSections === 0 &&
              values.sections &&
              values.sections.length <= maxSections
            ) {
              errors.global = intl.formatMessage({
                id: `materialshop.form.error.tooManyEvents`,
              })
            }
            break
          case 'poster':
          case 'promoStuff':
          case 'bridgeBanners':
          case 'promotionBanners':
            const sum = Object.values(values.fields || {}).reduce(
              (acc, cur) => {
                return acc + Number(cur)
              },
              0
            )

            if (sum <= 0) {
              errors.global = intl.formatMessage({
                id: `materialshop.form.error.tooFew`,
              })
            }

            if (!!item?.maxAmount && sum > item.maxAmount) {
              errors.global = intl.formatMessage({
                id: `materialshop.form.error.tooManyPieces`,
              })
            }
            break
          default:
            break
        }

        return errors
      }}
      onSubmit={(values) => {
        materialShopAddItem(item, values)
        onSubmit()
      }}
      validateOnBlur
    >
      {({
        values,
        isValid,
        handleSubmit,
        setValues,
        errors,
        dirty,
        touched,
      }) => {
        const forms = useMemo(() => {
          if (!formConfig) {
            return {}
          }
          const sectionsConfig = formConfig.form.sections
          return {
            fields: formConfig.form.fields
              ? {
                  label: formConfig.form.label,
                  fields: formConfig.form.fields || [],
                  values: values.fields || {},
                }
              : undefined,
            sections: sectionsConfig
              ? values.sections?.map((sectionValues) => {
                  return sectionsConfig.map((entry) => {
                    return {
                      label: entry.label,
                      fields: entry.fields,
                      values: sectionValues,
                    }
                  })
                })
              : undefined,
          }
        }, [formConfig, values])

        const bigEventsOrdersInCart = $materialShopBigEventsOrdersInCart.get()

        const addSection = () => {
          setValues((prev) => {
            if (!defaultValues.sections) {
              return prev
            }
            const sections = [...(prev.sections || [])]
            sections.push(defaultValues.sections?.[0])

            return { ...values, sections }
          })
        }

        function doesCartContainCurrentSection(currentId: string) {
          return !!materialShopCart.items.find((item) => item.id === currentId)
        }

        function howManyBigEventsInCart() {
          const bigEventsItems = materialShopCart.items.filter(
            (item) => item.category.identifier === 'bigEvents'
          )
          return bigEventsItems.length
        }

        function isButtonVisibleCheck(
          sectionLength: number,
          currentId: string
        ) {
          const doesCartContainSection =
            doesCartContainCurrentSection(currentId)
          const howManyBigEventsInCartCheck = howManyBigEventsInCart()
          switch (sectionLength) {
            case 1:
              return bigEventsOrdersInCart < 2
                ? true
                : bigEventsOrdersInCart <= 2 && !!doesCartContainSection
                ? true
                : false
            case 2:
              return bigEventsOrdersInCart === 0 &&
                !doesCartContainSection &&
                howManyBigEventsInCartCheck === 0
                ? true
                : bigEventsOrdersInCart <= 1 &&
                  doesCartContainSection &&
                  howManyBigEventsInCartCheck === 2
                ? false
                : bigEventsOrdersInCart < 2 && doesCartContainSection
                ? true
                : bigEventsOrdersInCart === 2 &&
                  howManyBigEventsInCartCheck === 1
                ? true
                : false
            case 3:
            default:
              return false
          }
        }

        const isAddButtonVisible =
          item.category.identifier === 'bigEvents'
            ? values.sections &&
              isButtonVisibleCheck(values.sections.length, item.id)
            : true

        const doesCartContainSectionFromItemId = doesCartContainCurrentSection(
          item.id
        )

        let hideCurrentSection = false
        if (!isAddButtonVisible) {
          hideCurrentSection =
            !doesCartContainSectionFromItemId && bigEventsOrdersInCart === 3
        }
        return (
          <div className={classes.base}>
            {(forms.fields || forms.sections) && (
              <div className={classes.sections}>
                {forms.fields && (
                  <MaterialShopFormProductDetailsSection
                    id="fields"
                    parts={[forms.fields]}
                  />
                )}
                {!hideCurrentSection &&
                  forms.sections &&
                  forms.sections.map((parts, index) => (
                    <div key={index}>
                      {index > 0 && (
                        <div className={classes.sectionHeader}>
                          <p className={classes.sectionTitle}>
                            <FormattedMessage
                              id="materialshop.form.numberedEvent"
                              values={{ value: index + 1 }}
                            />
                          </p>
                          <Button
                            icon="Trash"
                            className={classes.buttonDelete}
                            onClick={() => {
                              setValues((prev) => {
                                if (bigEventsOrdersInCart > 0) {
                                  $materialShopBigEventsOrdersInCart.set(
                                    bigEventsOrdersInCart - 1
                                  )
                                }
                                const sections = [...(prev.sections || [])]
                                sections.splice(index, 1)
                                return { ...values, sections }
                              })
                            }}
                          >
                            <FormattedMessage id="materialshop.form.remove" />
                          </Button>
                        </div>
                      )}

                      <MaterialShopFormProductDetailsSection
                        id={`sections[${index}]`}
                        parts={parts}
                      />
                    </div>
                  ))}
              </div>
            )}
            {forms.sections && (
              <div>
                {formConfig?.hint && (
                  <div>
                    <p className={classes.hintLabel}>
                      <FormattedMessage id="materialshop.form.hint" />:
                    </p>
                    <div
                      dangerouslySetInnerHTML={{ __html: formConfig.hint }}
                    />
                  </div>
                )}
                {isAddButtonVisible && (
                  <Button
                    className={classes.buttonAddAnother}
                    icon="Add"
                    iconPosition="right"
                    onClick={addSection}
                  >
                    <FormattedMessage id="materialshop.form.addAnotherAppointment" />
                  </Button>
                )}
              </div>
            )}
            <div className={classes.footer}>
              {(errors as any).global && (
                <p
                  className={classes.errorText}
                  dangerouslySetInnerHTML={{
                    __html: (errors as any).global,
                  }}
                />
              )}
              <Button
                onClick={() => handleSubmit()}
                disabled={
                  (errors as any).global ||
                  isExistingSocialMediaOrPressPackage ||
                  (!isValid && Object.keys(touched).length > 0) ||
                  hideCurrentSection
                }
              >
                <FormattedMessage
                  id={
                    isExistingSocialMediaOrPressPackage
                      ? 'materialshop.form.alreadyAdded'
                      : storeItem
                      ? 'materialshop.form.applyChanges'
                      : 'materialshop.form.addToCart'
                  }
                />
              </Button>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

import React, { ReactNode, ReactElement } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export interface IGlobalTextContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getText: (key: string, searchValue?: string, newValue?: string) => any
}

const GlobalTextContext = React.createContext<IGlobalTextContext>({
  getText: () => {
    return null
  },
})

export type GlobalTextProviderProps = {
  children: ReactNode
}

export default function GlobalTextProvider({
  children,
}: GlobalTextProviderProps): ReactElement {
  const { globalText } = useStaticQuery(graphql`
    query {
      globalText: allContentfulGlobalText {
        nodes {
          key
          value
        }
      }
    }
  `)

  const globalTexts: Record<string, string> = Object.assign(
    {},
    ...globalText?.nodes.map(({ key, value }: RvG.PageHelpers.IGlobalText) => ({
      [key]: value,
    }))
  )

  function getText(
    key: string,
    searchValue?: string,
    newValue?: string
  ): string {
    if (!globalTexts[key]) {
      console.info(
        '%c[RVGRE]',
        'color:#BFD107;',
        'Missing Global Text - key :: ',
        key
      )
    }

    if (searchValue && newValue) {
      return globalTexts[key].replace(`{{${searchValue}}}`, newValue)
    }
    return globalTexts[key]
  }

  return (
    <GlobalTextContext.Provider value={{ getText }}>
      {children}
    </GlobalTextContext.Provider>
  )
}

export { GlobalTextContext }

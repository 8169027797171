import {
  ColorPartial,
  PaletteOptions,
} from '@material-ui/core/styles/createPalette'

const Colors: Record<string, ColorPartial> = {
  black: {
    500: '#000000',
  },
  green: {
    200: '#E5ED9C',
    300: '#D9E36A',
    400: '#C0CF00',
    500: '#BFD02B',
    600: '#BFD107',
  },
  red: {
    100: '#FF3000',
  },
  grey: {
    100: '#F5F2F0',
    200: '#DCD9D8',
    300: '#B2B2B2',
    400: '#767676',
    500: '#575556',
    600: '#404040',
    700: '#262626',
  },
  teal: {
    300: '#D9ECE1',
    400: '#CDE6D7',
    500: '#C0E0CD',
  },
  turquoise: {
    300: '#AADCDB',
    400: '#8DD1CF',
    500: '#71C5C3',
  },
  white: {
    500: '#FFFFFF',
  },
}

const Palette: PaletteOptions = {
  background: {
    default: Colors.white[500],
    grey: Colors.grey[200],
    greyDark: Colors.grey[700],
    light: Colors.grey[100],
    focus: Colors.black[500],
    accent: Colors.green[600],
    accentLight: Colors.green[300],
    teal: Colors.teal[500],
    tealMedium: Colors.teal[400],
    tealLight: Colors.teal[300],
  },
  button: {
    disabled: Colors.grey[300],
    disabledText: Colors.grey[400],
    main: Colors.grey[700],
    mainHover: Colors.grey[700],
    mainText: Colors.white[500],
    mainTextHover: Colors.white[500],

    mainInvert: Colors.grey[100],
    mainHoverInvert: Colors.grey[100],
    mainTextInvert: Colors.grey[700],
    mainTextHoverInvert: Colors.grey[700],

    mainWhiteInvert: Colors.white[500],
    mainWhiteHoverInvert: Colors.white[500],
    mainWhiteTextInvert: Colors.grey[700],
    mainWhiteTextHoverInvert: Colors.grey[700],


    secondary: Colors.grey[100],
    secondaryHover: Colors.grey[100],
    secondaryText: Colors.black[500],
    secondaryTextHover: Colors.black[500],
    tertiary: Colors.green[500],
    tertiaryHover: Colors.green[500],
    tertiaryText: Colors.black[500],
    tertiaryTextHover: Colors.black[500],
    quaternaryHover: Colors.grey[700] + '!important',
  },
  error: {
    light: Colors.grey[100],
    main: Colors.black[500] || '',
    dark: Colors.red[100],
  },
  primary: {
    light: Colors.green[300],
    main: Colors.green[600] || '',
  },
  secondary: {
    light: Colors.turquoise[300],
    main: Colors.turquoise[400] || '',
    dark: Colors.turquoise[500],
  },
  text: {
    primary: Colors.grey[700],
    secondary: Colors.black[500],
    tertiary: Colors.grey[500],
    disabled: Colors.grey[600],
    highlight: Colors.green[500],
    hint: Colors.grey[400],
    input: Colors.grey[700],
    invert: Colors.white[500],
    teaser: Colors.grey[600],
  },
}

export default Palette

import React, { Fragment, ReactElement, ReactNode, ReactNodeArray } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paragraph from '@objects/paragraph'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(7),
    margin: 0,
    marginBottom: theme.spacing(8),
    listStyleType: 'none',
  },
  item: {
    paddingLeft: theme.spacing(1),
    position: 'relative',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: theme.spacing(2),
      left: -theme.spacing(7),
      height: theme.spacing(2),
      width: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(0.5),
    },
  },
}))

export type ListProps = RvG.IReactDefaultProps

const stripWrapper = (item: ReactNode) => {
  const itemElement = (item as ReactNodeArray)[0] as ReactElement

  if (Paragraph === itemElement.type) {
    return itemElement.props.children
  }
  return item
}

export default function UnorderedList({
  children,
  className,
}: ListProps): ReactElement {
  const classes = useStyles()
  return (
    <ul className={clsx(className, classes.root)} role="list">
      {(children as ReactNodeArray).map((item: ReactNode, i: number) => {
        if (item) {
          return (
            <Typography
              component="li"
              className={classes.item}
              key={i}
              role="listitem"
            >
              {/* TODO: check for better solution to remove paragraph wrapping li children */}
              {/* see: https://github.com/contentful/rich-text/issues/126 */}
              {/* pull request to contentful .net - duplicate for js? */}
              {stripWrapper(item)}
            </Typography>
          )
        } else {
          return <Fragment key={i} />
        }
      })}
    </ul>
  )
}

import React, { useEffect, ReactElement } from 'react'
import { useLocation } from '@reach/router'

export type TrackingProviderProps = RvG.IReactDefaultProps

export interface ITrackingContext {
  PushMessage: (evt: string, message: Record<string, unknown>) => void
}

const TrackingContext = React.createContext<ITrackingContext>({
  PushMessage: () => {
    return null
  },
})

export default function TrackingProvider({
  children,
}: TrackingProviderProps): ReactElement {
  const location = useLocation()

  useEffect(() => {
    customPageviewEvent(location.pathname)
  }, [location.pathname])

  function customPageviewEvent(path: string) {
    if (!window._mtm) return
    window._mtm.push({
      event: 'custom.pageview',
      path: path,
    })
  }

  function PushMessage(evt: string, message: Record<string, unknown>): void {
    if (!window._mtm) return
    window._mtm.push({
      event: evt || 'custom.message',
      ...message,
    })
  }

  return (
    <TrackingContext.Provider value={{ PushMessage }}>
      {children}
    </TrackingContext.Provider>
  )
}

export { TrackingContext }

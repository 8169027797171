import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Checkbox as FormCheckbox, Box } from '@material-ui/core'
import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    backgroundColor: theme.palette.background.main,
    borderRadius: 2,
    width: theme.spacing(6),
    height: theme.spacing(6),
    position: 'relative',
    '&.error': {
      backgroundColor: theme.palette.error.light,
      borderColor: theme.palette.error.dark,
    },
    '&.disabled': {
      backgroundColor: theme.palette.background.light,
    },
  },
  checkedIcon: {
    color: theme.palette.text.secondary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  checkedIconBox: {
    borderColor: theme.palette.text.secondary,
  },
}))

export type CheckboxProps = RvG.IReactDefaultProps & {
  value?: string | undefined
  name?: string
  error?: boolean | string
  disabled?: boolean | false
  checked?: boolean
}

export default function Checkbox({
  className,
  name,
  error,
  ...props
}: CheckboxProps): ReactElement {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <>
      <FormCheckbox
        data-testid="checkbox"
        className={clsx(className, classes.checkbox, { error: error })}
        name={name}
        checkedIcon={
          <Box
            component={'span'}
            className={clsx(classes.icon, classes.checkedIconBox)}
            border={2}
            borderColor={theme.palette.button.main}
          >
            <Icon
              name="Checkmark"
              className={classes.checkedIcon}
              size="inherit"
            />
          </Box>
        }
        icon={
          <Box
            component={'span'}
            className={clsx(classes.icon, {
              error: error,
              disabled: props.disabled,
            })}
            border={2}
            borderColor={
              props.disabled
                ? theme.palette.button.disabledText
                : theme.palette.button.main
            }
            borderRadius={2}
          />
        }
        {...props}
      />
    </>
  )
}

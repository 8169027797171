import React, { Fragment, ReactElement, ReactNode, ReactNodeArray } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paragraph from '@objects/paragraph'

const useStyles = makeStyles((theme) => ({
  root: {
    listStyle: 'none',
    paddingLeft: theme.spacing(13),
    margin: 0,
    marginBottom: theme.spacing(8),
  },
  simple: {
    listStyle: 'decimal',
    paddingLeft: theme.spacing(6),
    '& $item': {
      padding: theme.spacing(0, 0, 0, 2),
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
    },
  },
  item: {
    position: 'relative',
    padding: theme.spacing(1, 0),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(7),
    },
  },
  bulletNumber: {
    backgroundColor: theme.palette.primary.main,
    display: 'table-cell',
    textAlign: 'center',
    verticalAlign: 'middle',
    position: 'absolute',
    top: 0,
    left: -theme.spacing(13),
    width: theme.spacing(8),
    height: theme.spacing(8),
    lineHeight: `${theme.spacing(8)}px`,
    borderRadius: theme.spacing(0.5),
  },
}))
const stripWrapper = (item: ReactNode) => {
  const itemElement = (item as ReactNodeArray)[0] as ReactElement

  if (Paragraph === itemElement.type) {
    return itemElement.props.children
  }
  return item
}

export type ListProps = {
  children: ReactNode
  className?: string
}

export default function OrderedList({
  children,
  className,
}: ListProps): ReactElement {
  const classes = useStyles()

  let count = 0

  return (
    <ol className={clsx(className, classes.root)} role="list">
      {(children as ReactNodeArray).map((item: ReactNode, i: number) => {
        if (item) {
          count += 1
          return (
            <Typography
              component="li"
              className={classes.item}
              key={i}
              role="listitem"
            >
              <div className={classes.bulletNumber}>&nbsp;{count}.</div>
              {/* TODO: check for better solution to remove paragraph wrapping li children */}
              {/* see: https://github.com/contentful/rich-text/issues/126 */}
              {/* pull request to contentful .net - duplicate for js? */}
              {stripWrapper(item)}
            </Typography>
          )
        } else {
          return <Fragment key={i} />
        }
      })}
    </ol>
  )
}

import React, { ReactElement } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  SwiperOptions,
  Parallax,
  A11y,
  Autoplay,
  Navigation,
} from 'swiper'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  carouselRoot: {
    overflow: 'hidden',
    '&.swiper-container': {
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      overflow: 'hidden',
      listStyle: 'none',
      padding: 0,
      /* Fix of Webkit flickering */
      zIndex: 1,
    },
    '& .swiper-wrapper': {
      position: 'relative',
      width: '100%',
      height: '100%',
      zIndex: 1,
      display: 'flex',
      transitionProperty: 'transform',
      boxSizing: 'content-box',
    },
    '& .swiper-container-android .swiper-slide, .swiper-wrapper': {
      transform: 'translate3d(0px, 0, 0)',
    },
    '& .swiper-slide': {
      flexShrink: 0,
      width: '100%',
      height: 'auto',
      position: 'relative',
      transitionProperty: 'transform',
    },
    '& .swiper-pagination-bullet': {
      position: 'relative',
      display: 'inline-block',
      cursor: 'pointer',
      height: '20px',
      width: '20px',
      border: '2px solid black',
      borderRadius: '50%',
      margin: theme.spacing(0, 4, 0, 1),
      opacity: '0.2',

      '&:focus, &:hover': {
        opacity: '.9',
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        top: '2px',
        bottom: '2px',
        left: '2px',
        right: '2px',
        transform: 'scale(0.01)',
        background: 'transparent',
        transition: 'transform 0.3s ease, background 0.3s ease',
        borderRadius: 999,
      },

      '&.swiper-pagination-bullet-active': {
        opacity: 1,
        '&::after': {
          background: theme.palette.error.main,
          transform: 'scale(1)',
        },
        '&:focus::after': {
          background: theme.palette.background.focus,
        },
        '&:hover::after': {
          background: theme.palette.error.light,
        },
      },
    },
    '& .swiper-notification': {
      position: 'absolute',
      left: 0,
      top: 0,
      pointerEvents: 'none',
      opacity: 0,
      zIndex: -1000,
    },
    '& .swiper-container-fade': {
      '&.swiper-container-free-mode': {
        '.swiper-slide': {
          transitionTimingFunction: 'ease-out',
        },
      },
      '& .swiper-slide': {
        pointerEvents: 'none',
        transitionProperty: 'opacity',
        '& .swiper-slide': {
          pointerEvents: 'none',
        },
      },
      '& .swiper-slide-active': {
        '&, & .swiper-slide-active': {
          pointerEvents: 'auto',
        },
      },
    },
  },
  nav: {
    cursor: 'pointer',
    zIndex: 1010,
    padding: theme.spacing(2),
    borderRadius: '50%',
    '&:focus': {
      background: theme.palette.background.focus,
      color: theme.palette.text.invert,
    },
  },
}))

export type CarouselProps = RvG.IReactDefaultProps &
  SwiperOptions & {
    onSwiper?: Swiper['onSwiper']
    onActiveIndexChange?: Swiper['onActiveIndexChange']
  }

export default function Carousel({
  className,
  children,
  ...props
}: CarouselProps): ReactElement {
  const classes = useStyles()
  const intl = useIntl()

  SwiperCore.use([Parallax, A11y, Autoplay, Navigation])

  return (
    <Swiper
      observer={true}
      observeParents={true}
      data-testid="carousel"
      className={clsx(className, classes.carouselRoot)}
      a11y={{
        enabled: true,
        itemRoleDescriptionMessage: intl.formatMessage({
          id: 'carousel.label.itemRoleDescriptionMessage',
        }),
        paginationBulletMessage: intl.formatMessage({
          id: 'carousel.label.paginationBulletMessage',
        }),
        nextSlideMessage: intl.formatMessage({
          id: 'carousel.label.nextSlideMessage',
        }),
        prevSlideMessage: intl.formatMessage({
          id: 'carousel.label.prevSlideMessage',
        }),
        firstSlideMessage: intl.formatMessage({
          id: 'carousel.label.firstSlideMessage',
        }),
        lastSlideMessage: intl.formatMessage({
          id: 'carousel.label.lastSlideMessage',
        }),
        notificationClass: 'swiper-notification',
      }}
      {...props}
    >
      {(() => {
        return React.Children.map(children, (child, index) => {
          return React.cloneElement(
            <SwiperSlide key={`carousel-slide-${index}`} virtualIndex={index}>
              {child}
            </SwiperSlide>
          )
        })
      })()}
    </Swiper>
  )
}

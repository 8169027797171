import { useLocation, WindowLocation } from '@reach/router'
import useGlobalText from '@hooks/useGlobalText'
import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import Icon from '@objects/icon'
import InViewAnimation from '@objects/inViewAnimation'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  endPlate: {
    display: 'flex',
    width: '100%',
    '& ul': {
      width: '100%',
      display: 'flex',
      listStyleType: 'none',
      padding: '0px',
      margin: '0px',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
      '& li': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
      },
    },
  },
  endPlateItem: {
    flexBasis: 'calc(50% - 2px)',
    color: theme.palette.text.invert,
    width: 'calc(50% - 2px)',
    padding: theme.spacing(4),
    background: theme.palette.background.greyDark,
    margin: '1px',
    height: '180px',
    [theme.breakpoints.up('md')]: {
      width: 'calc(25% - 2px)',
      flexBasis: 'calc(25% - 2px)',
    },
  },
  endPlateIcon: {
    margin: '0 auto',
    fontSize: '40px!important',
  },
  endPlateName: {
    margin: '0 auto',
    fontFamily: theme.typography.fontFamily2,
    fontSize: '18px',
    textAlign: 'center',
  },
}))

type pageActionItem = {
  name: string
  path: string
  icon:
    | 'About'
    | 'Megaphone'
    | 'Description'
    | 'Mail'
    | 'DownloadMaterial'
    | 'EasyLang'
    | 'Gebaerdensprache'
  id: string
}

const getEndPlateLinks = (location: WindowLocation) => {
  const { getText } = useGlobalText()
  let items

  const about: pageActionItem = {
    name: getText('navigationLabelAbout') ?? 'Über uns',
    path: getText('navigationUrlAbout') ?? '/ueber-uns/',
    icon: 'About',
    id: 'About',
  }

  const baseActions: Record<string, pageActionItem> = {
    current: {
      name: getText('navigationLabelCurrent') ?? 'navigationLabelCurrent',
      path: getText('navigationUrlCurrent') ?? '/',
      icon: 'Megaphone',
      id: 'Megaphone',
    },
    press: {
      name: getText('navigationLabelPress') ?? 'navigationLabelPress',
      path: getText('navigationUrlPress') ?? '/',
      icon: 'Description',
      id: 'Description',
    },
    contact: {
      name: getText('navigationLabelContact') ?? 'navigationLabelContact',
      path: getText('navigationUrlContact') ?? '/',
      icon: 'Mail',
      id: 'Mail',
    },
    action: {
      name: getText('navigationLabelAction') ?? 'navigationLabelAction',
      path: getText('navigationUrlAction') ?? '/',
      icon: 'DownloadMaterial',
      id: 'DownloadMaterial',
    },
  }

  const currentPageActions: Record<string, pageActionItem> = {
    about: {
      name: getText('navigationLabelAbout') ?? 'navigationLabelAbout',
      path: getText('navigationUrlAbout') ?? '/',
      icon: 'About',
      id: 'About',
    },
    english: {
      name:
        getText('navigationLabelSimpleLanguage') ??
        'navigationLabelSimpleLanguage',
      path: getText('navigationUrlSimpleLanguage') ?? '/',
      icon: 'EasyLang',
      id: 'EasyLang',
    },
    signLang: {
      name:
        getText('navigationLabelGebaerdensprache') ??
        'navigationLabelGebaerdensprache',
      path: getText('navigationUrlGebaerdensprache') ?? '/',
      icon: 'Gebaerdensprache',
      id: 'Gebaerdensprache',
    },
    contact: {
      name: getText('navigationLabelContact') ?? 'navigationLabelContact',
      path: getText('navigationUrlContact') ?? '/',
      icon: 'Mail',
      id: 'Mail',
    },
  }

  if (location.pathname === getText('navigationUrlCurrentDownloads')) {
    items = currentPageActions
  } else {
    items = { ...baseActions }
    switch (location.pathname) {
      case getText('navigationUrlContact'):
        items.contact = about
        break
      case getText('navigationUrlCurrent'):
        items.current = about
        break
      case getText('navigationUrlPress'):
        items.press = about
        break
      case getText('navigationUrlAction'):
        items.action = about
    }
  }

  const result: pageActionItem[] = Object.values(items)

  return result
}

export default function EndPlate(): ReactElement {
  const classes = useStyles()
  const location = useLocation()
  const intl = useIntl()
  return (
    <InViewAnimation>
      <section
        className={classes.endPlate}
        role="region"
        aria-label={intl.formatMessage({ id: 'endplate.aria.label' })}
      >
        <ul
          role="list"
          aria-label={intl.formatMessage({ id: 'endplate.list.aria.label' })}
        >
          {getEndPlateLinks(location).map((endPlate: pageActionItem) => (
            <li
              key={`endplateItem-${endPlate.id}`}
              className={classes.endPlateItem}
              role="listitem"
            >
              <Link to={endPlate.path}>
                <Icon className={classes.endPlateIcon} name={endPlate.icon} />
                <div
                  className={classes.endPlateName}
                  dangerouslySetInnerHTML={{ __html: endPlate.name }}
                />
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </InViewAnimation>
  )
}

import React, { ReactElement } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import Button from '@objects/button'
import FriendlyCaptcha from '@objects/formfields/friendlycaptcha'
import TextField from '@objects/formfields/textField'
import CheckboxField from '@objects/formfields/checkboxField'
import RichTextEditor from '@objects/formfields/richtext'
import { useToggleComp } from '@hooks/index'
import Container from '@objects/container'
import Headline from '@objects/headline'
import { navigate } from 'gatsby'
import FileField from '@objects/formfields/fileField'
import reportBarrier from '@api/reportBarrier'
import ScrollToFormikFieldError from '@tools/ScrollToFormikFieldError'

const useStyles = makeStyles((theme) => ({
  headline: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(20),
    },
  },
  formContainer: {
    padding: theme.spacing(20, 0),
  },
  button: {
    margin: theme.spacing(7, 'auto', 0, 'auto'),
    display: 'inherit',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(7, 0, 0, 0),
    },
    '&:focus': {
      border: '2px dotted currentColor',
    },
  },
}))

export type ContactFormValues = {
  url: string
  file: Array<any>
  description: string
  email: string
  termsOfPrivacy: boolean
  friendlyCaptchaCode: string
}

export default function ReportBarrierForm(): ReactElement {
  const intl = useIntl()
  const classes = useStyles()
  const { toggleOverlay } = useToggleComp()
  const validationSchema = Yup.object({
    url: Yup.string().required(
      intl.formatMessage({ id: 'reportBarrier.url.error.required' })
    ),
    file: Yup.mixed()
      .test(
        'fileSize',
        intl.formatMessage({ id: 'reportBarrier.file.error.size' }),
        function (value) {
          return value.size ? value.size < 7 * 1024 * 1024 : true
        }
      )
      .test(
        'type',
        intl.formatMessage({ id: 'reportBarrier.file.error.type' }),
        function (value) {
          const allowed = ['jpg', 'jpeg', 'png', 'svg', 'gif']
          let match = ''
          allowed.forEach((i) => {
            if (i.match(value)) {
              match = i
            }
          })
          return match
        }
      ),
    description: Yup.string().required(
      intl.formatMessage({ id: 'reportBarrier.description.error.required' })
    ),
    email: Yup.string().email(
      intl.formatMessage({ id: 'reportBarrier.email.error' })
    ),

    termsOfPrivacy: Yup.boolean().oneOf(
      [true],
      intl.formatMessage({ id: 'contact.error.required' })
    ),
    friendlyCaptchaCode: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: 'contact.error.required' })),
  })

  const initialValues: ContactFormValues = {
    url: '',
    file: [],
    description: '',
    email: '',
    termsOfPrivacy: false,
    friendlyCaptchaCode: '',
  }

  //@TODO: unexpected type 'any'
  function _handleSubmit(
    values: ContactFormValues,
    { setSubmitting }: FormikHelpers<ContactFormValues>
  ): void {
    reportBarrier(values)
      .then((response: Response) => {
        if (response.status === 202) {
          navigate('/barrierefreiheit/danke/')
        } else {
          toggleOverlay(true, {
            type: 'contactError',
            trackingID: 'contactError',
            width: 'sm',
            dialogType: 'small',
            onAcceptance: () => toggleOverlay(false),
          })
        }
        setSubmitting(false)
      })
      .catch(() => {
        toggleOverlay(true, {
          type: 'contactError',
          trackingID: 'contactError',
          width: 'sm',
          dialogType: 'small',
          onAcceptance: () => toggleOverlay(false),
        })
        setSubmitting(false)
      })
  }

  return (
    <>
      <Container width={'sm'} type="section">
        <Headline className={classes.headline} level={1}>
          <FormattedMessage id={'reportBarrier.headline'} />
        </Headline>
        <p>
          <FormattedMessage id={'reportBarrier.subline'} />
        </p>
      </Container>
      <Container
        className={classes.formContainer}
        width={'sm'}
        background={'light'}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_handleSubmit}
          validateOnBlur={false}
        >
          {({ isSubmitting }) => (
            <Form id="contact">
              <ScrollToFormikFieldError />
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Headline className={classes.headline} level={5}>
                    <FormattedMessage id={'reportBarrier.intro.headline'} />
                  </Headline>
                  <p>
                    <FormattedMessage id={'reportBarrier.intro.subline'} />
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="url.0"
                    name="url"
                    type="text"
                    variant="outlined"
                    label={intl.formatMessage({
                      id: 'reportBarrier.url.label',
                    })}
                    customVariant={'form'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FileField
                    id="file-input"
                    name="file"
                    label={intl.formatMessage({
                      id: 'reportBarrier.file.label',
                    })}
                  />
                </Grid>
                <Grid item xs={12} id="description.0">
                  <RichTextEditor
                    id="description"
                    name="description"
                    maxLength={750}
                    label={intl.formatMessage({
                      id: `reportBarrier.description.label`,
                    })}
                    showInfoText
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    data-testid="mail-input"
                    id="email.0"
                    type="email"
                    name="email"
                    variant="outlined"
                    label={intl.formatMessage({
                      id: 'reportBarrier.email.label',
                    })}
                    autoComplete="email"
                    customVariant={'form'}
                  />
                </Grid>
                <Grid item id="termsOfPrivacy.0">
                  <CheckboxField
                    name="termsOfPrivacy"
                    labelId="reportBarrier.privacy.label"
                    labelLink="dataprivacy.path"
                  />
                  <div id="friendlyCaptchaCode.0">
                    <FriendlyCaptcha name="friendlyCaptchaCode" />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={classes.button}
                    disabled={isSubmitting}
                    type="main"
                    formSubmit
                  >
                    {intl.formatMessage({ id: 'contact.button' })}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

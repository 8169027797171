import { ContactFormValues } from '@components/fixedmodule/types/reportbarrierform'

const axios = require('axios')

export default async function reportBarrier(
  values: ContactFormValues
): Promise<any> {
  const formData = new FormData()
  formData.append('File', values.file)
  formData.append('Url', values.url)
  formData.append('Description', values.description)
  formData.append('Email', values.email)
  formData.append('FriendlyCaptchaCode', values.friendlyCaptchaCode)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }
  return axios.post(
    process.env.GATSBY_CONTACT_SERVICE_URL + '/api/contact/accessibility',
    formData,
    config
  )
}

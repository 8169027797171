/* eslint-disable react/display-name */
import React, { ReactElement, ReactNode } from 'react'
import { BLOCKS, MARKS, INLINES, Block } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Options } from '@contentful/rich-text-react-renderer'

import { makeStyles } from '@material-ui/core/styles'

import ContentfulComponent from './ContentfulComponent'
import Headline from '@objects/headline'
import Paragraph from '@objects/paragraph'
import UnorderedList from '@objects/unorderedlist'
import OrderedList from '@objects/orderedlist'
import Separator from '@objects/separator'
import Link from '@objects/link'
import Button from '@objects/button'
import Video from '@objects/video'

const useStyles = makeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(8),
  },
}))

export type ContentfulComponentArrayProps = {
  componentarray: RvG.Contentful.ArticleRichTextType
  simple?: boolean
  button?: boolean
  theme?: 'light' | 'dark'
}

export default function ContenfulComponentArray({
  componentarray,
  simple,
  button,
  theme,
}: ContentfulComponentArrayProps): ReactElement {
  const classes = useStyles()

  const options: Options = {
    renderMark: {
      // eslint-disable-next-line react/display-name
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    },
    renderText: (text) => {
      return text
        .split('\n')
        .reduce((children: ReactNode[], textSegment: string, index: number) => {
          return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (simple) {
          return children
        } else if (
          React.Children.toArray(children).includes('{{usercentric-data}}')
        ) {
          return <div className="uc-embed" uc-data="all"></div>
        } else {
          return <Paragraph>{children}</Paragraph>
        }
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),
      // eslint-disable-next-line react/display-name
      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedList>{children}</OrderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => children,
      // eslint-disable-next-line react/display-name
      [BLOCKS.HR]: () => <Separator />,
      // eslint-disable-next-line react/display-name
      [BLOCKS.QUOTE]: (node) => {
        return !!node?.content ? (
          <ContentfulComponent
            component={
              {
                __typename: 'blockquote',
                ...node,
              } as Block & RvG.Contentful.INodeDefaults
            }
          />
        ) : (
          <></>
        )
      },

      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_2]: (node, children) => (
        <Headline
          level={2}
          // @ts-expect-error "Value does not exist", but it does exist...
          id={node.content?.[0].value.replace(/\s+/g, '').toLowerCase()}
        >
          {children}
        </Headline>
      ),
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_3]: (node, children) => (
        <Headline
          level={3}
          id={node.content?.[0].value.replace(/\s+/g, '').toLowerCase()}
        >
          {children}
        </Headline>
      ),
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_4]: (node, children) => (
        <Headline
          level={4}
          id={node.content?.[0].value.replace(/\s+/g, '').toLowerCase()}
        >
          {children}
        </Headline>
      ),
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_5]: (node, children) => (
        <Headline
          level={5}
          id={node.content?.[0].value.replace(/\s+/g, '').toLowerCase()}
        >
          {children}
        </Headline>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (node.data.target) {
          return <ContentfulComponent component={node.data.target} />
        } else {
          console.log('Missing Component', node)
          return <></>
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return <Video src={node.data.target?.file?.url} />
      },
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: (node, children) => {
        if (node.data.uri === '#usercentrics-revoke') {
          return (
            <Button
              type="underlined"
              onClick={() => window.UC_UI.showSecondLayer()}
              role="button"
            >
              {children}
            </Button>
          )
        }
        if (button) {
          return (
            <Button
              {...(theme === 'dark' ? { type: 'secondary' } : {})}
              to={node.data.uri}
              isExternal
              role="button"
            >
              {children}
            </Button>
          )
        }
        return (
          <Link to={node.data.uri} isExternal underlined>
            <span role="link">{children}</span>
          </Link>
        )
      },
      // eslint-disable-next-line react/display-name
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const link = node.data?.target?.fields?.fullPath
        if (button && link) {
          return (
            <Button
              {...(theme === 'dark' ? { type: 'secondary' } : {})}
              to={link}
              role="button"
            >
              {children}
            </Button>
          )
        }
        return link ? (
          <Link to={link} underlined>
            <span role="link">{children}</span>
          </Link>
        ) : (
          children
        )
      },
    },
  }
  return (
    <>
      {!!componentarray ? (
        renderRichText(componentarray, options)
      ) : (
        <div className={classes.margin}></div>
      )}
    </>
  )
}

import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  progressBar: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  progressBarTitle: {
    fontWeight: 'bold',
  },
  progressBarStatus: {
    background: '#ffffff',
    width: '100%',
    height: '10px',
  },
  progressBarStatusBar: {
    height: '100%',
    background: '#bfd107',
    transition: 'width 0.5s ease',
  },
}))

export type IProgressProps = {
  active?: number
  all?: number
}

export default function Progress({
  active = 0,
  all = 1,
}: IProgressProps): React.ReactElement {
  const classes = useStyles()
  const percent: number = Math.ceil((active / all) * 100) | 0
  return (
    <div className={classes.progressBar}>
      {active && all && (
        <div className={classes.progressBarTitle}>
          Frage {active} von {all}
        </div>
      )}
      <div className={classes.progressBarStatus}>
        <div
          style={{ width: `${percent}%` }}
          className={classes.progressBarStatusBar}
        ></div>
      </div>
    </div>
  )
}

import React, {
  useState,
  useEffect,
  ReactElement,
  KeyboardEvent,
  useRef,
} from 'react'
import clsx from 'clsx'
import { Link } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'

import { INavItemData } from './drawerNav'
import { IMenuState } from './horizontalNavItem'
import SubNav from './horizontalSubNav'

import Icon from '@objects/icon'

export interface ISubNavItem {
  item: INavItemData
  isOpen: boolean
  isFocused: boolean
  onNavigate: () => void
  level: number
  onMouseEnter: () => void
  isVisible?: boolean
  transitionDelay: number
}

const useStyles = makeStyles((theme) => ({
  itemSub: {
    padding: theme.spacing(2, 3, 2, 4),
    opacity: 0.4,
    lineHeight: 1.1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: `opacity 0s ${theme.transitions.easing.easeOut} 0s`,
  },
  isVisible: {
    opacity: 1,
    transition: ({ transitionDelay }: { transitionDelay: number }) =>
      `opacity 1s ${theme.transitions.easing.easeOut} ${transitionDelay}s`,
  },
  subActive: {
    background: theme.palette.background.grey,
    '@media (prefers-contrast: more)': {
      borderTop: `1px solid ${theme.palette.text.hint}`,
      borderBottom: `1px solid ${theme.palette.text.hint}`,
    },
  },
  labelSub: {
    position: 'relative',
    '& > span': {
      backgroundImage:
        'linear-gradient(to bottom, transparent 20%, currentColor 21%)',
      backgroundPosition: '0 1.1em',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '0% 2px',
      transition: 'background-size 0.3s ease-in-out 0.2s',
    },
    '&:hover > span': {
      backgroundSize: '100% 2px',
      transitionDelay: '0s',
    },
    '&:hover:focus > span, &:focus > span': {
      // color: 'blue',
      backgroundSize: '5px 1.1em',
      backgroundImage:
        'radial-gradient(1px 1px at center, currentColor 1px, transparent 2px)',
      transitionDelay: '0s',
      backgroundPosition: '0px 10px',
      backgroundRepeat: 'repeat-x',
      transition: 'none',
    },
  },
  active: {},
}))

export default function SubNavItem({
  item,
  isOpen,
  isFocused,
  onNavigate,
  level,
  onMouseEnter,
  isVisible,
  transitionDelay,
}: ISubNavItem): ReactElement {
  const classes = useStyles({ transitionDelay })
  const [subMenu, setSubMenu] = useState<IMenuState>({
    open: isOpen || false,
    trigger: 'default',
  })
  // TODO: correct GatsbyLink type must be set/ imported somehow
  // not working Link<{}>, Link<unknown>, HTMLAnchorElement
  const navItemRef = useRef<any>(null)
  const itemLabelId = item.title?.replace(/\s+/g, '').toLowerCase()
  const itemDescId = `${item.title?.replace(/\s+/g, '').toLowerCase()}_desc`

  function handleItemKeyDown(ev: KeyboardEvent) {
    if (ev.key === 'ArrowRight') {
      setSubMenu({ open: true, trigger: 'key' })
      ev.stopPropagation()
      ev.preventDefault()
    }
  }

  useEffect(() => {
    if (isFocused) {
      navItemRef.current?.focus()
    }
  }, [isFocused])

  useEffect(() => {
    setSubMenu({ open: isOpen, trigger: 'default' })
  }, [isOpen])

  useEffect(() => {
    if (!subMenu.open && subMenu.trigger === 'key' && isFocused) {
      // Escape or ArrowLeft on subMenu
      navItemRef.current?.focus()
    }
  }, [subMenu])

  if (!!item.title) {
    return (
      <li role="none">
        <div
          className={clsx(classes.itemSub, {
            [classes.subActive]: subMenu.open && !!item.nextlevel,
            [classes.isVisible]: isVisible,
          })}
          onMouseEnter={onMouseEnter}
        >
          <Link
            ref={navItemRef}
            role="menuitem"
            aria-haspopup={item.nextlevel ? true : false}
            aria-expanded={
              item.nextlevel ? (subMenu.open ? true : false) : undefined
            }
            className={classes.labelSub}
            to={item.path ? item.path : '#'}
            activeClassName={classes.active}
            partiallyActive
            tabIndex={-1}
            onKeyDown={(ev) => {
              if (item.nextlevel && item.nextlevel?.length > 0) {
                handleItemKeyDown(ev)
              }
            }}
            onClick={onNavigate}
          >
            <span>{item.title}</span>
          </Link>
          {!!item.nextlevel?.length && <Icon name="ChevronRight" />}
        </div>
        {item.nextlevel && (
          <SubNav
            items={item.nextlevel}
            level={level + 1}
            menuState={subMenu}
            returnFocus={() => {
              setSubMenu({ open: false, trigger: 'key' })
            }}
            onNavigate={onNavigate}
            aria-labelledby={itemLabelId}
            aria-describedby={itemDescId}
            isVisible
          />
        )}
      </li>
    )
  } else {
    return <></>
  }
}

import useResizeObserver from '@react-hook/resize-observer'
import React from 'react'

const useSize = (
  target: React.RefObject<HTMLDivElement>
): DOMRectReadOnly | undefined => {
  const [size, setSize] = React.useState<DOMRectReadOnly>()

  React.useLayoutEffect(() => {
    if (!target.current) return
    setSize(target.current.getBoundingClientRect())
  }, [target])

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect))
  return size
}

export default useSize

import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import FontSize from '@config/theme/definitions/fontSize'

const useStyles = makeStyles((theme) => ({
  questionsBlock: {
    '&.answered .right .answer': {
      border: `5px solid ${theme.palette.primary.main}`,
      borderTop: 'none',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '100px',
        borderTop: `5px solid ${theme.palette.primary.main}`,
        borderLeft: 'none',
      },
    },
    '&.answered .wrong .answer': {
      background: 'none',
      borderTop: 'none',
      border: `5px solid #ff1259`,
      [theme.breakpoints.up('md')]: {
        paddingLeft: '100px',
        borderTop: `5px solid #ff1259`,
        borderLeft: 'none',
      },
    },

    '&.answered .wrong .char': {
      background: '#ff1259',
      [theme.breakpoints.up('md')]: {
        width: '74px',
      },
    },

    '&.answered .right .char': {
      [theme.breakpoints.up('md')]: {
        width: '74px',
      },
    },
  },
  qBlock: {
    cursor: 'pointer',
    background: '#ffffff',
    position: 'relative',
    border: 'none',
    padding: 0,
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  qBlockChar: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    background: '#bfd107',
    fontWeight: 800,
    fontSize: FontSize['iconLarge'],
    width: '100%',
    height: '48px',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: '54px',
      height: '100%',
    },
  },
  qBlockAnswer: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingLeft: '80px',
      minHeight: '90px',
      paddingRight: theme.spacing(8),
    },
  },
}))

export type IQuestionsBlockProps = {
  char?: string
  copy?: string
}

export type IAnswer = {
  id?: number
  char?: string
  answer: string
  leadin: string
  explanation?: RvG.Contentful.BasicRichTextType | string
  correct: boolean | null
}

export default function QuestionsBlock({
  data,
  onSelect,
  answered,
}: {
  data: Array<IAnswer>
  onSelect: CallableFunction
  answered: boolean
}): React.ReactElement {
  const classes = useStyles()

  const [selected, setSelected] = useState<number>()

  const onSelectHandler = (item: IAnswer) => {
    if (answered === false) {
      onSelect(item)
      setSelected(item.id)
      // setSelected(true);
    }
  }

  const getAnswerStatus = (item: IAnswer) => {
    if (!answered || item.id !== selected) return null
    return item.correct === true ? 'right' : 'wrong'
  }

  return (
    <div className={clsx(classes.questionsBlock, { answered: answered })}>
      {data.map((item, i) => (
        <button
          key={`questions-block-${i}`}
          className={clsx(classes.qBlock, getAnswerStatus(item))}
          onClick={() => onSelectHandler(item)}
        >
          <div className={clsx(classes.qBlockChar, 'char')}>
            <span>{item.char}</span>
          </div>
          <div className={clsx(classes.qBlockAnswer, 'answer')}>
            <span>{item.answer}</span>
          </div>
        </button>
      ))}
    </div>
  )
}

import React, { useState, useRef, useEffect, ReactElement } from 'react'
import { Link } from 'gatsby'
import clsx from 'clsx'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Hidden from '@material-ui/core/Hidden'
import Backdrop from '@material-ui/core/Backdrop'
import { INavItemData } from '@components/navigation/drawerNav'

import Search from '@components/search'
import MetaNav from '@components/navigation/metaNav'
import HorizontalNav from '@components/navigation/horizontalNav'
import DrawerNav from '@components/navigation/drawerNav'
import Icon from '@objects/icon'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  root: {},
  rootSticky: {
    top: 0,
    left: 'auto',
    right: 0,
    position: 'relative',
    '@supports (position: sticky)': {
      position: 'sticky',
    },
  },
  elevate: {
    zIndex: theme.zIndex.modal,
  },
  sticky: {
    marginBottom: '64px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(6, 4),
    },
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'column',
      padding: theme.spacing(6, 8, 0, 8),
    },
    transition: 'padding 300ms',
    '$sticky &': {
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: 0,
    },
  },
  toolbarRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& + &': {
      [theme.breakpoints.up('xl')]: {
        marginTop: theme.spacing(5),
      },
    },
    '&:first-child': {
      flex: 1,
      '$sticky &': {
        flex: '0 0 auto',
      },
    },
    '$sticky &': {
      flex: 1,
    },
  },
  navigationdesktop: {
    display: 'block',
    flex: '0 1 1072px',
    // navgiation cannot be hidden by <Hidden></Hidden> component. Because hidden-componen is not SSR. Navigation needs to bee SSR for SEO.
    [theme.breakpoints.down('lg')]: {
      display: 'none',
      visibility: 'hidden',
    },
  },
  spacer: {
    flex: '0 1 90px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.up('xl')]: {
      alignItems: 'flex-start',
    },
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    cursor: 'pointer',
  },
  menuButton: {
    border: 0,
    outline: 0,
    background: 0,
    padding: theme.spacing(0),
    marginLeft: theme.spacing(4),
  },
  logo: {
    '&:focus': {
      outline: `1px dotted ${theme.palette.text.primary}`,
    },
    '&:first-child': {
      marginRight: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(4),
      },
    },
  },
  logos: {
    marginRight: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(10),
    },
    [theme.breakpoints.up('xl')]: {
      margin: 0,
    },
  },
  logoImg: {
    height: theme.spacing(6),
    verticalAlign: 'top',
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing(10),
    },
    transition: 'height 300ms',

    '$sticky &': {
      height: theme.spacing(8),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.appBar,
  },
  stickyHidden: {
    transition: 'all 300ms',
    '$sticky &': {
      width: 0,
      height: 0,
      overflow: 'hidden',
    },
  },
}))

export type HeaderProps = {
  searchIndex: RvG.PageHelpers.SearchIndex
  mainnav: Array<INavItemData>
  metanav: Array<INavItemData>
}

export default function Header({
  searchIndex,
  mainnav,
  metanav,
}: HeaderProps): ReactElement {
  const theme = useTheme()
  const classes = useStyles()
  const CompRef = useRef<HTMLDivElement>(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [navOpen, setNavOpen] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const intl = useIntl()

  function toggleDrawer(open: boolean) {
    setDrawerOpen(open)
  }

  useEffect(() => {
    let lastScrollPos = window.scrollY
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { offsetHeight } = CompRef.current

    function scrollHandler() {
      const st = window.scrollY
      if (st > offsetHeight + 50) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
      lastScrollPos = st
    }

    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [CompRef])

  useEffect(() => {
    const body = document.body
    const htmlEl = document.getElementsByTagName('html')[0]
    // ios fix background
    if (drawerOpen) {
      body.style.height = '100%'
      htmlEl.style.height = '100%'
      body.style.position = 'relative'
      htmlEl.style.position = 'relative'
      htmlEl.style.overflow = 'hidden'
    } else {
      body.style.height = ''
      htmlEl.style.height = ''
      body.style.position = ''
      htmlEl.style.position = ''
      htmlEl.style.overflow = ''
    }
  }, [drawerOpen])
  return (
    <>
      <Hidden lgDown>
        <Backdrop className={classes.backdrop} open={navOpen} />
      </Hidden>
      <AppBar
        ref={CompRef}
        data-testid="header"
        className={clsx(classes.root, {
          [classes.sticky]: isDesktop && isSticky,
          [classes.elevate]: drawerOpen,
          [classes.rootSticky]: isDesktop,
        })}
        position="relative"
        color="inherit"
        elevation={0}
        aria-label={intl.formatMessage({ id: 'header.aria.label' })}
      >
        <div className={classes.toolbar}>
          <div className={classes.toolbarRow}>
            <Link className={classes.logo} to="/">
              <img
                className={classes.logoImg}
                src="/img/logo/rvg_logo.svg"
                alt="Logo von Runter vom Gas"
              />
            </Link>

            <Hidden lgDown>
              <MetaNav className={classes.stickyHidden} items={metanav} />
            </Hidden>

            <div className={clsx(classes.logos, classes.stickyHidden)}>
              <a
                className={classes.logo}
                href="https://bmdv.bund.de/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.logoImg}
                  src="/img/logo/bmvi.svg"
                  alt="Logo des Bundesministeriums für Digitales und Verkehr"
                />
              </a>
              <a
                className={classes.logo}
                href="https://www.dvr.de"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.logoImg}
                  src="/img/logo/DVR_Logo.png"
                  alt="Logo des deutschen Verkehrssicherheitsrats (DVR)"
                />
              </a>
            </div>
          </div>
          <div className={classes.toolbarRow}>
            <Hidden lgDown>
              <div className={classes.spacer} />
            </Hidden>
            <HorizontalNav
              className={classes.navigationdesktop}
              items={mainnav}
              onOpen={setNavOpen}
            />
            <div className={classes.spacer}>
              <Search className={classes.search} searchIndex={searchIndex} />
              <Hidden xlUp>
                <button
                  className={classes.menuButton}
                  onClick={() => toggleDrawer(!drawerOpen)}
                >
                  <Icon name="Menu" />
                </button>
                <DrawerNav
                  items={mainnav}
                  openState={drawerOpen}
                  toggleDrawer={toggleDrawer}
                  searchIndex={searchIndex}
                >
                  <MetaNav
                    items={metanav}
                    toggleDrawer={toggleDrawer}
                    showLogos
                  />
                  {/* ToDo: new layout.. logos? */}
                </DrawerNav>
              </Hidden>
            </div>
          </div>
        </div>
      </AppBar>
    </>
  )
}

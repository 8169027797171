import React, { useState, ReactElement, ReactNode } from 'react'
import clsx from 'clsx'

import { useLocation } from '@reach/router'
import { Link } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Search from '@components/search'

import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  navItemRoot: {
    padding: 0,
  },
  navItemLevel1: {
    ...theme.typography.navigation,
    marginTop: theme.spacing(6),
    '& $title': {
      padding: theme.spacing(6, 0, 5, 0),
      borderBottom: '4px solid transparent',
    },
  },
  navItemLevel2: {
    marginTop: theme.spacing(2),
    '& $title': {
      padding: theme.spacing(3, 0),
      borderBottom: '2px solid transparent',
    },
  },
  link: {
    padding: theme.spacing(0, 4, 0, 12),
    flex: 1,
  },
  title: {
    display: 'inline-block',
  },
  active: {
    '& $title': {
      borderBottomColor: 'currentColor',
    },
  },
  open: {
    '& $title': {
      borderBottomColor: theme.palette.primary.main,
    },
  },
  back: {
    padding: theme.spacing(4),
    marginRight: 'auto',
  },
  next: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  icon: {
    verticalAlign: 'top',
  },
  action: {
    border: 0,
    outline: 0,
    background: 'none',
    padding: theme.spacing(2),
    zIndex: 1,
  },
  actions: {
    display: 'flex',
    padding: theme.spacing(0, 2, 0, 8),
  },
}))

export interface IDrawerNavItem extends RvG.IReactDefaultProps {
  to?: string | null
  activeSlug?: string
  title: string
  drawerClass?: string
  level: number
  onClick: (open: boolean) => void
  searchIndex: RvG.PageHelpers.SearchIndex
}

interface IWrapper {
  children: ReactNode
  hasChildren: boolean
  className?: string
}

export default function DrawerNavItem({
  children,
  className,
  to,
  title,
  drawerClass,
  level,
  onClick,
  searchIndex,
}: IDrawerNavItem): ReactElement {
  const classes = useStyles()
  const location = useLocation()

  const [open, setOpen] = useState(false)

  function toggleSubMenu(open: boolean) {
    setOpen(open)
  }

  function closeMenu() {
    onClick(false)
  }

  function Wrapper({ children, className, hasChildren }: IWrapper) {
    if (to && !hasChildren) {
      return (
        <Link
          data-testid="link"
          className={clsx(className, classes.link)}
          color="inherit"
          to={to}
          onClick={closeMenu}
          activeClassName={classes.active}
          partiallyActive
          role="link"
          aria-label={children}
        >
          {children}
        </Link>
      )
    } else {
      return (
        <div
          data-testid={`toggle-${hasChildren ? '1' : '0'}`}
          className={clsx(classes.link, classes.next, {
            [classes.active]: to && location.pathname.startsWith(to),
          })}
          onClick={() => toggleSubMenu(true)}
        >
          {children}
          <Icon className={classes.icon} name="ChevronRight" />
        </div>
      )
    }
  }

  return (
    <>
      <ListItem
        data-testid={`item-${level}`}
        className={clsx(className, classes.navItemRoot, {
          [classes.navItemLevel1]: level === 0,
          [classes.navItemLevel2]: level !== 0,
        })}
        button
      >
        <Wrapper hasChildren={!!children}>
          <span className={classes.title}>{title}</span>
        </Wrapper>
      </ListItem>
      {children && (
        <SwipeableDrawer
          data-testid="submenu"
          anchor={'right'}
          variant="persistent"
          open={open}
          onClose={() => toggleSubMenu(false)}
          onOpen={() => toggleSubMenu(true)}
          PaperProps={{
            classes: {
              root: drawerClass,
            },
          }}
        >
          <div className={classes.actions}>
            <div
              className={clsx(classes.navItemRoot, classes.back)}
              onClick={() => toggleSubMenu(false)}
            >
              <Icon className={classes.icon} name="ArrowLeft" />
            </div>
            <Search
              className={classes.action}
              searchIndex={searchIndex}
              onClick={closeMenu}
            />
            <button className={classes.action} onClick={closeMenu}>
              <Icon name="Close" />
            </button>
          </div>
          <div className={clsx(classes.navItemRoot, classes.navItemLevel1)}>
            <Wrapper className={clsx(classes.open)} hasChildren={false}>
              <span className={classes.title}>{title}</span>
            </Wrapper>
          </div>
          {children}
        </SwipeableDrawer>
      )}
    </>
  )
}

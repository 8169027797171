import React, { ReactElement, useState } from 'react'
import { useField } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField'
import FormHelperText from './helperText'
import TextInput from './textInput'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    position: 'relative',
    marginBottom: theme.spacing(3),
  },
  inputlabel: {
    padding: theme.spacing(0, 5, 3, 0),
    color: theme.palette.text.hint,
    transition: 'all 0.2s ease',
    position: 'absolute',
    cursor: 'text',
  },
  inputEl: {
    width: '100%',
  },
  baseLabel: {
    position: 'absolute',
    transform: 'translate(0px, 5px)',
    fontSize: '16px',
    paddingLeft: 0,
    zIndex: 1,
  },
  reducedLabel: {
    transform: 'translate(0px,-5px)',
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },
  disabledLabel: {
    color: theme.palette.text.disabled,
    cursor: 'not-allowed',
  },
  errorMsg: {
    color: theme.palette.error.dark,
  },
  inputError: {
    '& input': {
      borderColor: theme.palette.error.dark + ' !important',
    },
  },
  errorLabel: {
    '&$reducedLabel': {
      color: theme.palette.error.dark,
      zIndex: 1,
    },
  },
  variant_form: {
    '& $inputEl': {
      borderBottom: `1px solid ${theme.palette.text.disabled}`,
      '& input': {
        paddingLeft: 0,
      },

      '&$inputError': {
        borderBottom: `1px solid ${theme.palette.error.dark}`,
      },
    },
  },
  variant_outlined: {},
}))

type variantClassType = 'variant_form' | 'variant_outlined'

export type TextFieldProps = MuiTextFieldProps &
  RvG.IReactDefaultProps &
  React.HTMLProps<HTMLInputElement> & {
    label: string
    infoText?: string | undefined
    id: string
    name: string
    type: string
    customVariant?: 'form' | undefined
    placeholder?: string | undefined
    autoComplete?: string | undefined
  }

export default function TextField(props: TextFieldProps): ReactElement {
  const {
    className,
    label,
    infoText,
    id,
    name,
    type,
    disabled,
    customVariant,
    ...other
  } = props
  const classes = useStyles()
  const [field, meta] = useField(name)
  const [focusField, setFocusField] = useState(false)

  return (
    <div
      className={clsx(classes.inputRoot, {
        [classes[`variant_${customVariant}` as variantClassType]]:
          !!customVariant,
      })}
    >
      {label && (
        <InputLabel
          className={clsx(classes.inputlabel, {
            [classes.baseLabel]: !field.value,
            [classes.reducedLabel]: field.value || focusField,
            [classes.errorLabel]: meta.touched && !!meta.error,
            [classes.disabledLabel]: disabled,
          })}
          htmlFor={id}
        >
          {label}
        </InputLabel>
      )}
      <TextInput
        className={clsx(className, classes.inputEl, {
          [classes.inputError]: meta.touched && !!meta.error,
        })}
        id={id}
        type={type}
        error={meta.touched && !!meta.error}
        {...field}
        {...other}
        onFocus={() => {
          setFocusField(() => true)
        }}
        onBlur={() => {
          setFocusField(() => false)
        }}
      />
      {infoText && (
        <FormHelperText spaced type="info">
          {infoText}
        </FormHelperText>
      )}
      {meta.touched && meta.error ? (
        <FormHelperText className={classes.errorMsg} type="error" name={name}>
          {meta.error}
        </FormHelperText>
      ) : null}
    </div>
  )
}

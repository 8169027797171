import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/de'
import '@src/styles/cookiebot.css'

import React, { ReactElement } from 'react'
import * as ReactIntl from 'react-intl'

import { WrapPageElementNodeArgs } from 'gatsby'
import elasticlunr from 'elasticlunr'

import Layout from './../src/components/layout'
import Metatags from './../src/objects/metatags'

import { messages } from './../src/translations'
import { defaultLang } from './../src/translations'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'

import TrackingProvider from '@providers/trackingProvider'
import ToggleComponentProvider from '@providers/toggleComponentProvider'
import GlobalTextProvider from '@providers/globalTextProvider'
import BasketComponentProvider from '@providers/basketProvider'

import createMuiTheme from '../config/theme'

const { FeedbackFormProvider } = require('@providers/feedbackFormProvider')

const muitheme = createMuiTheme()

elasticlunr.tokenizer.setSeperator(/[\s]+/)

type WrapPageArgs = WrapPageElementNodeArgs & {
  props: {
    pageContext: RvG.PageContext
    location: Record<string, boolean>
  }
}

export function wrapPage({ element, props }: WrapPageArgs): ReactElement {
  return (
    <ReactIntl.IntlProvider
      locale={props.pageContext.locale || defaultLang}
      defaultLocale={defaultLang}
      messages={messages[props.pageContext.locale] || messages[defaultLang]}
    >
      <ThemeProvider theme={muitheme}>
        <TrackingProvider {...props}>
          <BasketComponentProvider>
            <ToggleComponentProvider {...props}>
              <FeedbackFormProvider>
                <GlobalTextProvider>
                  <>
                    {props.pageContext?.page?.metaTags && (
                      <Metatags
                        locale={props.pageContext.locale}
                        meta={props.pageContext.page.metaTags}
                      />
                    )}
                    <CssBaseline />
                    <Layout {...props}>{element}</Layout>
                  </>
                </GlobalTextProvider>
              </FeedbackFormProvider>
            </ToggleComponentProvider>
          </BasketComponentProvider>
        </TrackingProvider>
      </ThemeProvider>
    </ReactIntl.IntlProvider>
  )
}

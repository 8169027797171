import React, {
  ReactElement,
  useState,
  useEffect,
  KeyboardEvent,
  ChangeEvent,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import RadioGroup from '@material-ui/core/RadioGroup'
import { FormControl, FormLabel } from '@material-ui/core'
import clsx from 'clsx'

import useGlobalText from '@hooks/useGlobalText'
import { useIntl } from 'react-intl'

import Radio from '@objects/formfields/radio'
import InfoBox from '@components/infoBox'

import { ContentTypes } from '../'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTabs-root': {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.text.secondary,
      margin: 0,
      padding: theme.spacing(3, 0),
      '& .MuiTab-wrapper': {
        fontWeight: 'bold',
        '&:before': {
          borderColor: theme.palette.text.invert,
        },
        '&::after': {
          backgroundColor: theme.palette.text.invert,
        },
      },
      '& .MuiTabs-flexContainer': {
        justifyContent: 'center',
      },
    },
  },
  filter: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.text.secondary,
    margin: 0,
    padding: theme.spacing(3, 0),
  },
  formControl: {
    display: 'flex',
    // this hides the legend except for screen readers:
    '& legend': {
      width: '0px',
      margin: '0px',
      height: '0px',
      overflow: 'hidden',
    },
  },
  formLabel: {},
  radioGroup: {
    justifyContent: 'center',
    '& .MuiTab-wrapper': {
      fontWeight: 'bold',
      '&:before': {
        borderColor: theme.palette.text.invert,
      },
    },
    // RADIO RING
    '& .MuiRadio-colorSecondary.Mui-checked, & .Mui-checked input + div > svg:first-child':
      {
        color: theme.palette.text.invert,
        stroke: 'black',
        strokeWidth: '2',
      },
    // RADIO CIRCLE
    '& .MuiRadio-colorSecondary.Mui-checked, & .Mui-checked input + div > svg:nth-child(2)':
      {
        stroke: 'black',
        strokeWidth: '2',
      },
    '& .MuiFormControlLabel-label': {
      fontWeight: 'bold',
      borderBottom: '2px solid currentColor',
    },
  },
  innerRoot: {},
  interaction: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  interactionItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6),
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
      padding: theme.spacing(10),
    },
    '&:first-child': {
      backgroundColor: theme.palette.secondary.main,
      '& $counterBtn': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    '&:last-child': {
      backgroundColor: theme.palette.secondary.light,
      '& $counterBtn': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  },
  results: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(4),
  },
  resultsItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      marginBottom: 1,
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%',
    },
    '&:nth-child(2), &:nth-child(4)': {
      '& $resultsItemInner': {
        marginLeft: 1,
      },
    },
    '& ~ &': {
      '& $resultsItemInner': {
        [theme.breakpoints.up('lg')]: {
          marginLeft: 1,
        },
      },
    },
  },
  resultsItemInner: {
    padding: theme.spacing(8),
    backgroundColor: theme.palette.background.tealLight,
    width: '100%',
    height: '100%',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
  },
  itemCentered: {
    fontSize: 60,
    lineHeight: 1,
  },
  counter: {
    display: 'flex',
    alignItems: 'center',
  },
  counterValue: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 160,
    },
    margin: theme.spacing(0, 6, 2, 6),
    borderBottom: '3px dotted currentColor',
  },
  counterValueInput: {
    fontSize: 70,
    lineHeight: 1,
    textAlign: 'center',
    width: '100%',
    border: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  counterBtn: {
    position: 'relative',
    width: 36,
    height: 36,
    border: 0,
    borderRadius: '50%',
    cursor: 'pointer',
    flexShrink: 0,
    transition: `transform 0.3s ${theme.transitions.easing.easeOut}`,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.background.greyDark,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '&:before': {
      width: 12,
      height: 2,
    },
    '&:hover': {
      [theme.breakpoints.up('lg')]: {
        transform: 'scale(1.2)',
      },
    },
  },
  counterBtnMinus: {},
  counterBtnPlus: {
    '&::after': {
      width: 2,
      height: 12,
    },
  },
  infoBox: {
    [theme.breakpoints.up('lg')]: {
      width: '66.666%',
      margin: '0 auto',
    },
  },
  cta: {
    marginTop: theme.spacing(10),
    textAlign: 'center',
    '& p': {
      margin: 0,
    },
  },
}))

type InnerProps = {
  labelTop: string
  centered: string | ReactElement
  labelBottom?: string
}

type CounterProps = {
  value: number
  onChange: (value: number) => void
}

type ResultStripProps = {
  items: Array<{
    labelTop: string
    centered: string
    labelBottom: string
  }>
}

export type ScheduleOfFinesProps = ContentTypes & {
  active?: string
  cta?: ReactElement
}

function Inner({ labelTop, centered, labelBottom }: InnerProps): ReactElement {
  const classes = useStyles()

  return (
    <div className={classes.inner}>
      <div>{labelTop}</div>
      {typeof centered === 'string' ? (
        <div className={classes.itemCentered}>{centered}</div>
      ) : (
        centered
      )}
      {labelBottom && <div>{labelBottom}</div>}
    </div>
  )
}

function Counter({ value, onChange }: CounterProps): ReactElement {
  const classes = useStyles()

  const intl = useIntl()

  return (
    <div className={classes.counter}>
      <button
        className={clsx(classes.counterBtn, classes.counterBtnMinus)}
        onClick={() => onChange(value - 1 > 0 ? value - 1 : 0)}
        aria-label={intl.formatMessage({
          id: 'fineCalculator.button.label.increase',
        })}
      />
      <div className={classes.counterValue}>
        <input
          type="text"
          value={value}
          className={classes.counterValueInput}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const inputValue = parseInt(e.target.value, 10)
            if (isNaN(inputValue)) {
              onChange(0)
            } else if (inputValue < 350) {
              onChange(inputValue >= 0 ? inputValue : 0)
            }
          }}
          onKeyDown={(e: KeyboardEvent) => {
            if (e.key === 'ArrowDown') {
              onChange(value - 1 > 0 ? value - 1 : 0)
            }
            if (e.key === 'ArrowUp') {
              onChange(value + 1)
            }
          }}
        />
      </div>
      <button
        className={clsx(classes.counterBtn, classes.counterBtnPlus)}
        onClick={() => onChange(value + 1)}
        aria-label={intl.formatMessage({
          id: 'fineCalculator.button.label.decrease',
        })}
      />
    </div>
  )
}

function ResultStrip({ items }: ResultStripProps) {
  const classes = useStyles()

  return (
    <div className={classes.results}>
      {items.map((item, i) => (
        <div key={`${item.labelTop}-${i}`} className={classes.resultsItem}>
          <div className={classes.resultsItemInner}>
            <Inner {...item} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default function ScheduleOfFines(
  props: ScheduleOfFinesProps
): ReactElement {
  const { Overspeeding, Distance, active, cta } = props
  const classes = useStyles()
  const { getText } = useGlobalText()

  function OverspeedingComponent(): ReactElement {
    const [filter, setFilter] = useState('Innerorts')
    const [drivenSpeed, setDrivenSpeed] = useState(100)
    const [permittedSpeed, setPermittedSpeed] = useState(80)
    const [differenceSpeed, setDifferenceSpeed] = useState(0)
    const [fine, setFine] = useState(0)
    const [points, setPoints] = useState(0)
    const [drivenBan, setDrivenBan] = useState(0)
    const [infoHeadline, setInfoHeadline] = useState('')
    const [infoCopy, setInfoCopy] = useState('')

    function handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
      const val = (event.target as HTMLInputElement).value
      setFilter(val)
    }

    useEffect(() => {
      const items = Overspeeding.filter((o) => o.filter === filter).sort(
        (a, b) => {
          if (parseInt(a.cells[0].label) > parseInt(b.cells[0].label)) {
            return 1
          } else if (parseInt(a.cells[0].label) < parseInt(b.cells[0].label)) {
            return -1
          }
          return 0
        }
      )

      const deltaSpeed = drivenSpeed - permittedSpeed

      let matchIntex = 0
      items.map((item, i) => {
        const from = parseInt(item.cells[0].label, 10)
        if (deltaSpeed >= from) {
          matchIntex = i
        }
      })

      /**
       * Übertretung
       */
      setDifferenceSpeed(deltaSpeed >= 0 ? deltaSpeed : 0)

      /**
       * Matched results
       */
      const matchedCell = items[matchIntex].cells

      setFine(parseInt(matchedCell[1].label, 10))
      setPoints(parseInt(matchedCell[2].label, 10))
      setDrivenBan(parseInt(matchedCell[3].label, 10))

      /**
       * set info text
       */
      if (infoHeadline === '') {
        setInfoHeadline(items[0].cells[4].label)
      } else {
        setInfoHeadline(matchedCell[4].label || infoHeadline)
      }

      if (infoCopy === '') {
        setInfoCopy(items[0].cells[5].label)
      } else {
        setInfoCopy(matchedCell[5].label || infoCopy)
      }
    }, [filter, drivenSpeed, permittedSpeed])

    return (
      <div className={classes.innerRoot}>
        <form className={classes.filter}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Geschwindigkeitsbereich</FormLabel>
            <RadioGroup
              value={filter}
              onChange={handleRadioChange}
              className={classes.radioGroup}
              row
            >
              <Radio
                value="Innerorts"
                label={getText('fineCalculatorInTown')}
              />
              <Radio
                value="Außerorts"
                label={getText('fineCalculatorOutOfTown')}
              />
            </RadioGroup>
          </FormControl>
        </form>
        <div className={classes.interaction}>
          <div className={classes.interactionItem}>
            <Inner
              labelTop={getText('fineCalculatorDrivenSpeed')}
              centered={
                <Counter value={drivenSpeed} onChange={setDrivenSpeed} />
              }
              labelBottom={getText('fineCalculatorUnitSpeed')}
            />
          </div>
          <div className={classes.interactionItem}>
            <Inner
              labelTop={getText('fineCalculatorPermittedSpeed')}
              centered={
                <Counter value={permittedSpeed} onChange={setPermittedSpeed} />
              }
              labelBottom={getText('fineCalculatorUnitSpeed')}
            />
          </div>
        </div>

        <ResultStrip
          items={[
            {
              labelTop: getText('fineCalculatorTransgression'),
              centered: differenceSpeed.toString(),
              labelBottom: getText('fineCalculatorUnitSpeed'),
            },
            {
              labelTop: getText('fineCalculatorFine'),
              centered: differenceSpeed > 0 ? fine.toString() : (0).toString(),
              labelBottom: getText('fineCalculatorUnitEuro'),
            },
            {
              labelTop: getText('fineCalculatorPoints'),
              centered:
                differenceSpeed > 0 ? points.toString() : (0).toString(),
              labelBottom: getText('fineCalculatorUnitFlensburg'),
            },
            {
              labelTop: getText('fineCalculatorDrivingBan'),
              centered:
                differenceSpeed > 0 ? drivenBan.toString() : (0).toString(),
              labelBottom: getText('fineCalculatorUnitMonth'),
            },
          ]}
        />

        <div className={classes.cta}>{cta}</div>

        {differenceSpeed > 0 && (
          <div className={classes.infoBox}>
            <InfoBox type="InfoBox" headline={infoHeadline} copy={infoCopy} />
          </div>
        )}
      </div>
    )
  }

  function DistanceComponent(): ReactElement {
    const [drivenSpeed, setDrivenSpeed] = useState(80) // Default "gefahrene Geschwindigkeit"
    const [distance, setDistance] = useState(50) // Default "eingehaltener Abstand"
    const [fallingBelow, setFallingBelow] = useState(0)
    const [fine, setFine] = useState(0)
    const [points, setPoints] = useState(0)
    const [drivenBan, setDrivenBan] = useState(0)
    const [infoHeadline, setInfoHeadline] = useState('')
    const [infoCopy, setInfoCopy] = useState('')

    useEffect(() => {
      if (drivenSpeed <= 80) {
        setFallingBelow(0)
        return
      }

      let tableData = '>130'
      if (drivenSpeed > 100 && drivenSpeed <= 130) {
        tableData = '>100'
      } else if (drivenSpeed <= 100) {
        tableData = '>80'
      }

      const items = Distance.filter((o) => o.filter === tableData).sort(
        (a, b) => {
          if (parseFloat(a.cells[0].label) < parseFloat(b.cells[0].label)) {
            return 1
          } else if (
            parseFloat(a.cells[0].label) > parseFloat(b.cells[0].label)
          ) {
            return -1
          }
          return 0
        }
      )

      let matchIntex = 0
      items.map((item, i) => {
        const tatbestand = (drivenSpeed / 2) * parseFloat(item.cells[0].label)
        if (distance < tatbestand) {
          matchIntex = i
        }
      })

      const matchedCell = items[matchIntex].cells

      /**
       * Formel:
       * Halber Tacho mal den Wert, bei dem die Stafe beginnt (5/10 = 0.5) minus aktuelle Distanz
       * Wenn man die Untschreitung vom aktuellen Strafmaß anzeigen möchte,
       * also zB von 3/10 des halben Tachowertes, müsste die Formel lauten:
       * const fb = Math.ceil((drivenSpeed / 2) * parseFloat(matchedCell[0].label)) - distance
       * Hinweis: Auf der vorherigen Website wurde es wie folgt berechnet (Logik wurde hier übernommen):
       */
      const fb = Math.ceil((drivenSpeed / 2) * 0.5) - distance
      setFallingBelow(fb >= 0 ? fb : 0)

      setFine(parseInt(matchedCell[1].label, 10))
      setPoints(parseInt(matchedCell[2].label, 10))
      setDrivenBan(parseInt(matchedCell[3].label, 10))

      /**
       * Set Info Text
       * Wenn jede Tabellenzeile einen InfoText bekommen soll
       * dann müsste man "items[0].cells[4]?.label" ersetzen durch matchedCell[4].label
       */
      setInfoHeadline(items[0].cells[4]?.label || '')
      setInfoCopy(items[0].cells[5]?.label || '')
    }, [drivenSpeed, distance])

    return (
      <div className={classes.innerRoot}>
        <div className={classes.interaction}>
          <div className={classes.interactionItem}>
            <Inner
              labelTop={getText('fineCalculatorDrivenSpeed')}
              centered={
                <Counter value={drivenSpeed} onChange={setDrivenSpeed} />
              }
              labelBottom={getText('fineCalculatorUnitSpeed')}
            />
          </div>
          <div className={classes.interactionItem}>
            <Inner
              labelTop={getText('fineCalculatorMaintainedDistance')}
              centered={<Counter value={distance} onChange={setDistance} />}
              labelBottom="(Meter)"
            />
          </div>
        </div>

        <ResultStrip
          items={[
            {
              labelTop: getText('fineCalculatorFallingBelow'),
              centered: fallingBelow.toString(),
              labelBottom: getText('fineCalculatorUnitMeter'),
            },
            {
              labelTop: getText('fineCalculatorFine'),
              centered: fallingBelow > 0 ? fine.toString() : (0).toString(),
              labelBottom: getText('fineCalculatorUnitEuro'),
            },
            {
              labelTop: getText('fineCalculatorPoints'),
              centered: fallingBelow > 0 ? points.toString() : (0).toString(),
              labelBottom: getText('fineCalculatorUnitFlensburg'),
            },
            {
              labelTop: getText('fineCalculatorDrivingBan'),
              centered:
                fallingBelow > 0 ? drivenBan.toString() : (0).toString(),
              labelBottom: getText('fineCalculatorUnitMonth'),
            },
          ]}
        />

        <div className={classes.cta}>{cta}</div>

        {fallingBelow > 0 && (
          <div className={classes.infoBox}>
            <InfoBox type="InfoBox" headline={infoHeadline} copy={infoCopy} />
          </div>
        )}
      </div>
    )
  }

  return (
    <section className={classes.root}>
      {active === 'overspeeding' && <OverspeedingComponent />}
      {active === 'distance' && <DistanceComponent />}
    </section>
  )
}

import React, { useState } from 'react'
import { makeStyles, useMediaQuery, useTheme, Grid } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import Container from '@objects/container'
import Image from '@objects/image'
import Button from '@objects/button'
import clsx from 'clsx'
import Headline from '@objects/headline'
import Copy from '@components/copy'
import FontSize from '@config/theme/definitions/fontSize'
import Progress from './Progress'
import QuestionsBlock, { IAnswer } from './QuestionBlock'
import ResultBlock from './ResultBlock'

const useStyles = makeStyles((theme) => ({
  quizRoot: {
    background: '#f5f2f0',
    paddingTop: theme.spacing(18),
    paddingBottom: theme.spacing(8),
  },
  desktopOnly: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  mobileOnly: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  image: {
    margin: 0,
  },
  content: {},
  questionTitle: {
    fontSize: FontSize['xl'],
    // marginTop: theme.spacing(12),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(12),
      fontSize: FontSize['5xl'],
    },
  },
  question: {
    fontSize: FontSize['base'],
    [theme.breakpoints.up('md')]: {
      fontSize: FontSize['xl'],
    },
  },
  copy: {},

  button: {
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    '& + &': {
      marginLeft: theme.spacing(3),
    },
    '& span::after': {
      background: theme.palette.text.primary,
    },
  },

  explanationContainer: {
    '&:empty': {
      padding: 0,
    },
    background: '#ffffff',
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(10),
    },
  },
  explanation: {
    display: 'none',
    '&.show': {
      display: 'block',
    },
  },
  explanationHeadline: {
    color: theme.palette.primary.main,
    '&.wrong': {
      color: '#ff1259',
    },
  },
  explanationCopy: {},
}))

export interface IQuizKnowledgeOverlayProps {
  endscreenImage?: RvG.Contentful.IAsset
  endscreenSuccessNumber?: number
  endscreenCopySuccess?: RvG.Contentful.BasicRichTextType | string
  endscreenCopyFailed?: RvG.Contentful.BasicRichTextType | string
  questions?: Array<RvG.Contentful.IContentfulModuleQuizKnowledgeQuestion>
  handleClose?: () => void
}

export default function QuizKnowledgeOverlay({
  endscreenImage,
  endscreenSuccessNumber,
  endscreenCopySuccess,
  endscreenCopyFailed,
  questions,
}: IQuizKnowledgeOverlayProps): React.ReactElement {
  const classes = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const all: number = questions?.length | 0
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const activeQuestion: RvG.Contentful.IContentfulModuleQuizKnowledgeQuestion =
    questions[currentQuestion] || {}
  const [answerCorrect, setAnswerStatus] = useState(false)
  const [leadin, setLeadin] = useState('')
  const [explanation, setExplanation] = useState('')
  const [answered, setAnswered] = useState(false)
  const [quizResult, setQuizResult] = useState(0)
  const [correct, addCorrect] = useState(0)

  const [newArr, setQuestionsArray] = useState(null)

  const onAnswerHandler = (item: IAnswer) => {
    setAnswerStatus(item.correct)
    setLeadin(item.leadin)
    setExplanation(item.explanation)
    setAnswered(true)
    if (item.correct === true) {
      addCorrect(correct + 1)
    }
  }

  const nextQuestionHandler = () => {
    if (currentQuestion < all - 1) {
      setCurrentQuestion(currentQuestion + 1)
      setAnswered(false)
      setQuestionsArray(null)
    }
  }
  const onShowResultHandler = () => {
    setQuizResult(1)
  }

  const restartHandler = () => {
    setCurrentQuestion(0)
    setAnswered(false)
    setQuestionsArray(null)
    setQuizResult(0)
    addCorrect(0)
  }

  const shuffleElements = (
    data: RvG.Contentful.IContentfulModuleQuizKnowledgeQuestion
  ) => {
    const arr: Array<RvG.Contentful.IContentfulModuleQuizKnowledgeQuestion> = []
    if (data.answer1) {
      arr.push({
        id: 1,
        char: '',
        answer: data.answer1,
        leadin: data.answer1leadin,
        explanation: data.answer1explanation,
        correct: data.answer1correct,
      })
    }
    if (data.answer2) {
      arr.push({
        id: 2,
        char: '',
        answer: data.answer2,
        leadin: data.answer2leadin,
        explanation: data.answer2explanation,
        correct: data.answer2correct,
      })
    }
    if (data.answer3) {
      arr.push({
        id: 3,
        char: '',
        answer: data.answer3,
        leadin: data.answer3leadin,
        explanation: data.answer3explanation,
        correct: data.answer3correct,
      })
    }

    if (data.answer4) {
      arr.push({
        id: 4,
        char: '',
        answer: data.answer4,
        leadin: data.answer4leadin,
        explanation: data.answer4explanation,
        correct: data.answer4correct,
      })
    }

    const nArr: Array<RvG.Contentful.IModuleQuizKnowledgeQuestion> = arr.sort(
      () => 0.5 - Math.random()
    )
    const abcOrder = ['A', 'B', 'C', 'D']
    for (let i = 0; i < nArr.length; i++) {
      nArr[i].char = abcOrder[i]
    }
    return nArr
  }

  if (!newArr) {
    const el: Array<RvG.Contentful.IContentfulModuleQuizKnowledgeQuestion> =
      shuffleElements(activeQuestion)
    setQuestionsArray(el)
  }

  return (
    <div className={classes.quizRoot}>
      <Container type="nomargin">
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Progress active={currentQuestion + 1} all={all} />
          </Grid>
        </Grid>
        <Grid container spacing={isSmall ? 4 : 8} alignItems="flex-start">
          {activeQuestion.image !== null ? (
            <Grid item xs={4} md={4}>
              <Image
                image={
                  quizResult === 0
                    ? activeQuestion.image.fluid
                    : endscreenImage.fluid
                }
                alt="Quizz-Head"
                className={classes.image}
              />
            </Grid>
          ) : (
            ''
          )}

          {quizResult <= 0 && (
            <Grid item xs={8} className={classes.mobileOnly}>
              <Headline level={2} className={classes.questionTitle}>
                Frage {currentQuestion + 1}
              </Headline>
              <Headline level={3} className={classes.question}>
                {activeQuestion.question}
              </Headline>
            </Grid>
          )}

          {quizResult > 0 && (
            <Grid item xs={8} className={classes.mobileOnly}>
              <Headline
                level={2}
                className={clsx(classes.questionTitle, classes.mobileOnly)}
              >
                {correct >= endscreenSuccessNumber ? (
                  <FormattedMessage id="quiz.headline.success" />
                ) : (
                  <FormattedMessage id="quiz.headline.fail" />
                )}
              </Headline>
            </Grid>
          )}

          <Grid item xs={12} md={8}>
            <div className={classes.content}>
              {activeQuestion && quizResult === 0 && (
                <div>
                  <div className={classes.desktopOnly}>
                    <Headline level={2} className={classes.questionTitle}>
                      Frage {currentQuestion + 1}
                    </Headline>
                    <Headline level={3} className={classes.question}>
                      {activeQuestion.question}
                    </Headline>
                  </div>

                  <QuestionsBlock
                    data={newArr}
                    onSelect={onAnswerHandler}
                    answered={answered}
                  />
                  <div className={clsx(classes.explanationContainer)}>
                    {explanation && answered && (
                      <div
                        className={clsx(classes.explanation, {
                          show: answered,
                        })}
                      >
                        <Headline
                          level={4}
                          className={clsx(classes.explanationHeadline, {
                            wrong: answerCorrect !== true,
                          })}
                        >
                          {answerCorrect === true ? (
                            leadin !== null ? (
                              leadin
                            ) : (
                              <FormattedMessage id="quiz.answer.right" />
                            )
                          ) : leadin !== null ? (
                            leadin
                          ) : (
                            <FormattedMessage id="quiz.answer.wrong" />
                          )}
                        </Headline>
                        <Copy
                          richtext={explanation}
                          className={classes.explanationCopy}
                        />
                        {currentQuestion < all - 1 && (
                          <Button
                            className={classes.button}
                            onClick={nextQuestionHandler}
                          >
                            <FormattedMessage id="quiz.button.next" />
                          </Button>
                        )}

                        {currentQuestion === all - 1 && (
                          <Button
                            className={classes.button}
                            onClick={onShowResultHandler}
                          >
                            <FormattedMessage id="quiz.button.result" />
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {quizResult > 0 && (
                <>
                  <Headline level={2} className={classes.desktopOnly}>
                    {correct >= endscreenSuccessNumber ? (
                      <FormattedMessage id="quiz.headline.success" />
                    ) : (
                      <FormattedMessage id="quiz.headline.fail" />
                    )}
                  </Headline>

                  <ResultBlock
                    all={all}
                    correct={correct}
                    min={endscreenSuccessNumber}
                    successCopy={endscreenCopySuccess}
                    failedCopy={endscreenCopyFailed}
                  />

                  <Button className={classes.button} onClick={restartHandler}>
                    <FormattedMessage id="quiz.button.repeat" />
                  </Button>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

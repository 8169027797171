import React, { ReactElement } from 'react'

import Container from '@objects/container'
import FactBox from './types/factBox'
import ImportantInfoBox from './types/importantInfoBox'

export type InfoBoxProps = RvG.IReactDefaultProps & {
  type?: string
  image?: RvG.Contentful.IFluidImage
  imageAlt?: string
  references?: unknown[]
  headline?: string
  copy?: RvG.Contentful.BasicRichTextType | string
  relatedLinks?: RvG.Contentful.BasicRichTextType
  hasMaxWidth?: boolean
}

export default function InfoBox({
  type,
  ...props
}: InfoBoxProps): ReactElement {
  switch (type) {
    case 'InfoBox':
      return (
        <Container type="article">
          <ImportantInfoBox {...props} />
        </Container>
      )
    case 'Fact':
      return (
        <Container type="article">
          <FactBox {...props} />
        </Container>
      )
    default:
      return <></>
  }
}

import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Image from '@objects/image'
import Carousel from '@objects/carousel'
import Button from '@objects/button'
import clsx from 'clsx'
import _ from 'lodash'
import Youtube from '@objects/youtube'
import Copy from '@components/copy'
import Icon from '@objects/icon'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  popupWrapper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 12px)',
    maxWidth: theme.container.lg,
    maxHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: theme.spacing(12, 4),
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
      width: '100%',
      flexDirection: 'row',
    },
  },
  infoWrapper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
    paddingLeft: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  infoInner: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  infoHeadline: {
    ...theme.typography.h5,
  },
  close: {
    cursor: 'pointer',
    marginBottom: 'auto',
    textAlign: 'right',
    fontSize: '32px',
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(6.5),
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  galleryWrapper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
  },
  galleryInner: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    zIndex: theme.zIndex.modal + 1,
    padding: theme.spacing(0, 4),
    background: theme.palette.background.greyDark,
    '& .swiper-wrapper': {
      alignItems: 'center',
    },
  },
  galleryItem: {
    height: '100%',
    maxHeight: '80vh',
    width: '90%',
    margin: 'auto',
  },
  navBtn: {
    position: 'absolute !important' as 'absolute',
    top: '50%',
    zIndex: 1,
    transform: 'translateY(-50%)',
    width: '28px',
    height: '71px',
  },
  navBtnPrev: {
    left: theme.spacing(0),
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
  },
  navBtnNext: {
    right: theme.spacing(0),
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
  },
  downloadWrapper: {
    [theme.breakpoints.up('md')]: {
      minHeight: '50%',
    },
  },
  download: {},
  hide: {
    display: 'none',
  },
}))

export interface IGalleryProps {
  items?:
    | Array<RvG.Contentful.IAsset>
    | Array<RvG.Contentful.IContentfulYouTubeItem>
  download?: RvG.Contentful.IAsset
  itemsType?: string
  headline?: string
  description?: RvG.Contentful.BasicRichTextType
  handleClose?: () => void
}

const renderItems = (
  items: IGalleryProps['items'],
  itemsType: IGalleryProps['itemsType']
) => {
  const classes = useStyles()

  if ('youtube' === itemsType) {
    return (
      <React.Fragment>
        {items &&
          items[0] &&
          (items as Array<RvG.Contentful.IContentfulYouTubeItem>)[0]
            .youTubeId && (
            <Youtube
              youTubeId={
                (items as Array<RvG.Contentful.IContentfulYouTubeItem>)[0]
                  .youTubeId
              }
              thumbnail={
                (items as Array<RvG.Contentful.IContentfulYouTubeItem>)[0]
                  .thumbnail
              }
            />
          )}
      </React.Fragment>
    )
  }

  return (items as Array<RvG.Contentful.IAsset>).map((item, i) => {
    return (
      <div key={`galleryItem-${item.id}`}>
        <Image className={classes.galleryItem} image={item.fluid} />
      </div>
    )
  })
}

export default function Gallery({
  items,
  itemsType,
  headline,
  description,
  handleClose,
  download,
}: IGalleryProps) {
  const classes = useStyles()
  const [swiper, setSwiper] = useState<any>({})
  const muiTheme = useTheme()
  const isMedium = useMediaQuery(muiTheme.breakpoints.up('md'))

  return (
    <>
      <div className={classes.popupWrapper}>
        <div
          className={clsx(classes.close, { [classes.hide]: isMedium })}
          onClick={handleClose}
        >
          <Icon size={'inherit'} name={'Close'} />
        </div>
        <div className={classes.galleryWrapper}>
          <div className={classes.galleryInner}>
            <Carousel
              onSwiper={setSwiper}
              speed={600}
              loop={'youtube' !== itemsType}
            >
              {renderItems(items, itemsType)}
            </Carousel>

            {!!items && items.length > 1 && (
              <React.Fragment>
                <Button
                  type="icon"
                  icon="ChevronLeft"
                  className={clsx(classes.navBtn, classes.navBtnPrev)}
                  onClick={() => swiper.slidePrev()}
                />
                <Button
                  type="icon"
                  icon="ChevronRight"
                  className={clsx(classes.navBtn, classes.navBtnNext)}
                  onClick={() => swiper.slideNext()}
                />
              </React.Fragment>
            )}
          </div>
          {!!items && <div>{/*<span>x von {items.length}</span>*/}</div>}
        </div>
        <div className={classes.infoWrapper}>
          <div className={classes.infoInner}>
            <div
              className={clsx(classes.close, {
                [classes.hide]: !isMedium,
              })}
              onClick={handleClose}
            >
              <Icon size={'inherit'} name={'Close'} />
            </div>
            <h3 className={classes.infoHeadline}>{headline}</h3>
            {!!description && <Copy richtext={description} />}
            <div className={classes.downloadWrapper}>
              {download && (
                <div className={classes.download}>
                  <Button
                    type="download"
                    to={download.file?.url}
                    target="__blank"
                  >
                    <FormattedMessage id="button.download" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React, {
  useState,
  useRef,
  useEffect,
  ReactElement,
  KeyboardEvent,
} from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import SubNavItem from './horizontalSubNavItem'
import { INavItemData } from './drawerNav'
import { IMenuState } from './horizontalNavItem'

interface IHorizontalSubNav {
  className?: string
  items: INavItemData[]
  onNavigate: () => void
  returnFocus: (close: boolean) => void
  level: number
  menuState: IMenuState
  isVisible?: boolean
}

const useStyles = makeStyles((theme) => ({
  list: {
    listStyle: 'none',
    margin: 0,
    padding: theme.spacing(8, 0),
  },
  listLevel2: {
    position: 'relative',
    width: theme.spacing(60),
    height: '100%',
    background: theme.palette.background.light,
  },
  listLevel3: {
    display: 'none',
    position: 'absolute',
    top: 0,
    left: '100%',
    width: theme.spacing(83),
    '&$active': {
      display: 'block',
    },
  },
  active: {
    '@media (prefers-contrast: more)': {
      border: `1px solid ${theme.palette.text.hint}`,
      borderTop: 'none',
    },
  },
}))

export default function HorizontalSubNav({
  className,
  items,
  menuState,
  onNavigate,
  returnFocus,
  level,
  isVisible,
  ...props
}: IHorizontalSubNav): ReactElement {
  const classes = useStyles()
  const CompRef = useRef<HTMLUListElement>(null)
  const [focusIndex, setFocusIndex] = useState(-1)
  const [activeIndex, setActiveIndex] = useState(-1)

  function handleMenuKeyDown(ev: KeyboardEvent) {
    if (ev.key === 'Escape' || ev.key === 'ArrowLeft') {
      returnFocus(true)
      if (level > 2) {
        // if 2nd level, propagate ArrowLeft to first level and go to prev item there
        ev.stopPropagation()
      }
      ev.preventDefault()
    } else if (ev.key === 'ArrowDown') {
      const nextFocus = focusIndex + 1
      setFocusIndex(nextFocus === items.length ? -1 : nextFocus)
      ev.stopPropagation()
      ev.preventDefault() // page up/down
    } else if (ev.key === 'ArrowUp') {
      setFocusIndex(focusIndex === -1 ? items.length - 1 : focusIndex - 1)
      ev.stopPropagation()
      ev.preventDefault()
    }
  }

  useEffect(() => {
    if (!menuState.open) {
      setFocusIndex(-1)
      setActiveIndex(-1)
    } else if (menuState.trigger === 'keyUp') {
      // setIndex to pass focus to last list child when triggered by keyUp
      setFocusIndex(items.length - 1)
    } else if (menuState.trigger === 'keyDown') {
      // setIndex to pass focus to first list child when triggered by keyDown
      setFocusIndex(0)
    } else if (menuState.trigger === 'key') {
      // setIndex ot pass focus to first child when triggered by parent item ArrowRight (3rd level only)
      setFocusIndex(0)
    }
  }, [menuState])

  useEffect(() => {
    if (focusIndex === -1) {
      // go back to first level link / headline
      returnFocus(false)
    }
  }, [focusIndex])

  return (
    <ul
      ref={CompRef}
      role="menu"
      className={clsx(className, classes.list, {
        [classes.listLevel2]: level === 2,
        [classes.listLevel3]: level === 3,
        [classes.active]: menuState.open,
      })}
      onKeyDown={handleMenuKeyDown}
      {...props}
    >
      {items?.map((item, idx) => {
        return (
          <SubNavItem
            key={idx}
            item={item}
            isFocused={focusIndex === idx}
            isOpen={activeIndex === idx}
            level={2}
            onNavigate={onNavigate}
            isVisible={isVisible}
            transitionDelay={(0.6 / items.length) * idx}
            onMouseEnter={() => {
              setActiveIndex(idx)
            }}
          />
        )
      })}
    </ul>
  )
}

import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import Icon from '@objects/icon'

import FontSize from '@config/theme/definitions/fontSize'

const useStyles = makeStyles((theme) => ({
  quoteRoot: {
    marginBottom: theme.spacing(10),
  },
  blockquote: {
    paddingRight: theme.spacing(15),
    margin: 0,
    paddingLeft: theme.spacing(17),
    paddingTop: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(25),
    },
  },
  quote: {
    fontFamily: theme.typography.fontFamily2,
    margin: 0,
    fontSize: FontSize['xl'],
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      fontSize: FontSize['6xl'],
      margin: 0,
    },

    '&:before': {
      // content: '"„"',
    },
    '&:after': {
      // content: '"“"',
    },
  },
  author: {
    display: 'block',
    marginTop: theme.spacing(6),
    fontSize: FontSize['xs'],
    fontStyle: 'normal',
  },
  icon: {
    fontSize: '84px !important',
    position: 'absolute',
  },
}))

export type QuoteProps = RvG.IReactDefaultProps & {
  text: string
  author?: string
}

export default function Blockquote({
  text,
  author,
  className,
}: QuoteProps): ReactElement {
  const classes = useStyles()
  return (
    <div
      className={clsx(classes.quoteRoot, className)}
      data-testid={'block-quote'}
    >
      <Icon
        name={'Quote'}
        color={'primary'}
        size={'large'}
        className={classes.icon}
      />
      <blockquote className={classes.blockquote}>
        <p className={classes.quote}>{text}</p>
        {!!author && <cite className={classes.author}>{author}</cite>}
      </blockquote>
    </div>
  )
}

import React, { ReactElement, ReactNode } from 'react'
import Trunc from 'truncate-html'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 0,
    marginBottom: theme.spacing(8),
  },
  bold: {
    fontWeight: 'bold',
  },
}))

export type ParagraphProps = RvG.IReactDefaultProps & {
  truncate?: number
  truncateextend?: ReactNode
  bold?: boolean
  ariaRole?: string
}

export default function Paragraph({
  className,
  children,
  truncate,
  truncateextend,
  bold,
  ariaRole,
}: ParagraphProps): ReactElement {
  const classes = useStyles()

  function TruncateOrNot(text: React.ReactChildren | unknown) {
    if (truncate) {
      return Trunc(text, truncate, { byWords: true })
    } else {
      return text
    }
  }

  return (
    <p
      className={clsx(className, classes.root, {
        [classes.bold]: bold,
      })}
      role={ariaRole}
    >
      {!!children && TruncateOrNot(children)}
      {truncateextend}
    </p>
  )
}

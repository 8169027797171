import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import FontSize from '@config/theme/definitions/fontSize'
import Copy from '@components/copy'
import Icon from '@objects/icon'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  resultBlockRoot: {},
  headline: {},
  copy: {},
  resultBlock: {
    background: '#ffffff',
    position: 'relative',
    padding: 0,
    width: '100%',
    marginBottom: theme.spacing(4),
    border: `5px solid ${theme.palette.primary.main}`,
    borderTop: 'none',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '84px',
      borderTop: `5px solid ${theme.palette.primary.main}`,
      borderLeft: 'none',
    },
    // '&.failed': {
    //   '& .char': {
    //     background: '#ff1259',
    //   },
    //   borderTop: 'none',
    //   border: `5px solid #ff1259`,
    //   [theme.breakpoints.up('md')]: {
    //     paddingLeft: '100px',
    //     borderTop: `5px solid #ff1259`,
    //     borderLeft: 'none',
    //   },
    // },
  },
  headerIcon: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: theme.spacing(8),
    '& svg': {
      height: '84px',
      width: '84px',
    },
  },
  resultBlockChar: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    background: '#bfd107',
    width: '100%',
    height: '48px',
    fontWeight: 800,
    fontSize: FontSize['iconLarge'],
    '& svg': {
      width: '1.5em',
      height: '1.5em',
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      left: 0,
      width: '74px',
      height: '100%',
    },
  },
  resultBlockCopy: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    minHeight: '90px',
    fontWeight: 'bold',
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      // paddingLeft: '80px',
      // paddingRight: theme.spacing(8),
    },
  },
}))

export type IResultBlockProps = {
  all?: number
  correct?: number
  min?: number
  successCopy?: RvG.Contentful.BasicRichTextType | string
  failedCopy?: RvG.Contentful.BasicRichTextType | string
}

export default function ResultBlock({
  all,
  correct,
  min,
  successCopy,
  failedCopy,
}: IResultBlockProps): React.ReactElement {
  const classes = useStyles()

  const isCorrect = correct || 0

  return (
    <div className={clsx(classes.resultBlockRoot)}>
      {min && (
        <>
          <div
            className={clsx(classes.resultBlock, {
              failed: isCorrect < min,
            })}
          >
            <div className={clsx(classes.resultBlockChar, 'char')}>
              {isCorrect >= min && (
                <Icon name={'ChartCheck'} viewBox={'0 0 24 24'} />
              )}
              {isCorrect < min && (
                <Icon name={'ChartCheck'} viewBox={'0 0 24 24'} />
              )}
            </div>
            <div className={clsx(classes.resultBlockCopy, 'answer')}>
              {isCorrect >= min && (
                <span>
                  <FormattedMessage
                    id="quiz.copy.success"
                    values={{ correct: isCorrect, all }}
                  />
                </span>
              )}
              {isCorrect < min && (
                <span>
                  <FormattedMessage
                    id="quiz.copy.fail"
                    values={{ correct: isCorrect, all }}
                  />
                </span>
              )}
            </div>
          </div>

          <Copy
            richtext={isCorrect >= min ? successCopy : failedCopy}
            className={classes.copy}
          />
        </>
      )}
    </div>
  )
}

import { useContext } from 'react'

import {
  GlobalTextContext,
  IGlobalTextContext,
} from '@providers/globalTextProvider'

export default function useGlobalText(): IGlobalTextContext {
  const { getText } = useContext(GlobalTextContext)

  return { getText }
}

import RtTypes from '@contentful/rich-text-types'

import Headline, { HeadlineProps } from '@objects/headline'
import Blockquote, { QuoteProps } from '@objects/blockquote'
import Image, { ImageProps } from '@objects/image'
import Youtube, { YouTubeProps } from '@objects/youtube'
import Accordion, { AccordionProps } from '@objects/accordion'
import InfoBox, { InfoBoxProps } from '@components/infoBox'
import DownloadBar, { DownloadBarProps } from '@components/downloadBar'
import FixedModule, { FixedModuleProps } from '@components/fixedmodule'
import PictureGallery, { PictureGalleryProps } from '@components/pictureGallery'
import Table, { TableProps } from '@components/table'
import BigTeaser, { BigTeaserProps } from '@components/bigTeaser'
import QuizKnowledge, { IQuizKnowledgeProps } from '@components/quizKnowledge'
import DownloadShopTeaser, {
  DownloadShopTeaserProps,
} from '@components/downloadShopTeaser'
import TeaserSingle, { TeaserSingleProps } from '@components/teaserSingle'
import DownloadShopTeaserList, {
  DownloadShopTeaserListProps,
} from '@components/downloadShopTeaserList'
import YouTubeGallery, { YouTubeGalleryProps } from '@components/youTubeGallery'

function findParser(
  component: RvG.Contentful.ArticleModuleUnion
): ComponentParserType | undefined {
  const parser = ComponentParser.find((cmp) => {
    return component.__typename?.match(cmp.nodeType)
  })
  return parser
}

export function createTocList(
  articleContent: RvG.Contentful.ArticleRichTextType
): React.ReactElement {
  let parsedContent
  try {
    parsedContent = JSON.parse(articleContent.raw).content
  } catch (e) {
    parsedContent = []
  }
  return parsedContent
    ?.filter((component) => {
      let cmp = component.nodeType

      if (component.nodeType === 'embedded-entry-block') {
        const comp_id = component.data.target?.sys?.id
        const comp_ref = articleContent.references.find(
          (el) => el.contentful_id === comp_id
        )
        cmp = comp_ref?.__typename
      }
      return ['heading-2', 'ContentfulModuleAccordion', 'faqCategory'].includes(
        cmp
      )
    })
    .map((component) => {
      let transformedComponent = { ...component }

      switch (component.nodeType) {
        case 'heading-2':
          transformedComponent.__typename = 'heading'
          transformedComponent.level = '2'
          break
        case 'embedded-entry-block':
          const comp_id = component.data.target?.sys?.id
          const comp_ref = articleContent.references.find(
            (el) => el.contentful_id === comp_id
          )

          transformedComponent = {
            ...transformedComponent,
            ...comp_ref,
          }
          break
        default:
          transformedComponent.__typename = transformedComponent.nodeType
      }

      const props =
        findParser(transformedComponent)?.props(transformedComponent)
      return props
        ? {
            id: props.id,
            label: props?.headline || props.children[0],
          }
        : {}
    })
}

export type ComponentParserPropsUnion =
  | HeadlineProps
  | QuoteProps
  | ImageProps
  | YouTubeProps
  | AccordionProps
  | FixedModuleProps
  | PictureGalleryProps
  | DownloadBarProps
  | DownloadShopTeaserProps
  | DownloadShopTeaserListProps
  | TeaserSingleProps
type ComponentParserType = {
  nodeType: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: (props: any) => any
  props: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any
  ) => ComponentParserPropsUnion
}

const ComponentParser: Array<ComponentParserType> = [
  {
    nodeType: 'heading',
    component: Headline,
    props: (component: RvG.Contentful.Heading): HeadlineProps => {
      return {
        level: component.level,
        id: component.content
          ? (component.content[0] as RtTypes.Text)?.value
              .replace(/\s+/g, '')
              .toLowerCase()
          : '',
        children: component.content?.map((ct) => (ct as RtTypes.Text).value),
      }
    },
  },
  {
    nodeType: 'blockquote',
    component: Blockquote,
    props: (component: RtTypes.Quote): QuoteProps => {
      return {
        text: (component.content?.[0].content[0] as RtTypes.Text)?.value,
        author:
          component.content.length > 1
            ? (component.content?.[1].content[0] as RtTypes.Text)?.value
            : '',
      }
    },
  },
  {
    nodeType: 'ContentfulModuleBigTeaser',
    component: BigTeaser,
    props: (mod: RvG.Contentful.IContentfulModuleBigTeaser): BigTeaserProps => {
      return {
        type: mod.type,
        theme: mod.theme,
        layout: mod.layout,
        headline: mod.headline,
        imageFocalPoint: mod.imageFocalPoint,
        image: mod.image,
        mobileImage: mod.mobileImage,
        cta: mod.cta,
        copy: mod.copy,
      }
    },
  },
  {
    nodeType: 'ContentfulImage',
    component: Image,
    props: (component: RvG.Contentful.IImageRef): ImageProps => {
      return {
        image: component.image?.fluid,
        alt: component.image?.description,
        caption: component?.caption,
        copyright: component.copyrightNote,
        lightbox: true,
        autoFit: component.autoFit,
      }
    },
  },
  {
    nodeType: 'ContentfulModuleQuizKnowledge',
    component: QuizKnowledge,
    props: (
      mod: RvG.Contentful.IContentfulModuleQuizKnowledge
    ): IQuizKnowledgeProps => {
      return {
        image: mod.image,
        imageFocalPoint: mod.imageFocalPoint,
        headline: mod.headline,
        copy: mod.copy,
        endscreenImage: mod.endscreenImage,
        endscreenSuccessNumber: mod.endscreenSuccessNumber,
        endscreenCopySuccess: mod.endscreenCopySuccess,
        endscreenCopyFailed: mod.endscreenCopyFailed,
        questions: mod.questions,
      }
    },
  },
  {
    nodeType: 'ContentfulYouTubeItem',
    component: Youtube,
    props: (component: RvG.Contentful.IYouTubeRef): YouTubeProps => {
      return {
        title: component.title,
        youTubeId: component.youTubeId,
        description: component.description,
        thumbnail: component.thumbnail,
        alt: component.thumbnail?.description,
      }
    },
  },
  {
    nodeType: 'ContentfulModuleAccordion',
    component: Accordion,
    props: (component: RvG.Contentful.IAccordionRef): AccordionProps => {
      return {
        id: component.contentful_id,
        headline: component.headline,
        items: component.items?.map((item) => {
          return {
            headline: item.headline,
            copy: item.copy,
            id: `id-${item.contentful_id}`,
          }
        }),
      }
    },
  },
  {
    nodeType: 'ContentfulModuleTeaser',
    component: TeaserSingle,
    props: (
      component: RvG.Contentful.IContentfulModuleTeaser
    ): TeaserSingleProps => {
      return {
        teaserTitle: component.teaserTitle,
        teaserCopy: component.teaserCopy,
        teaserImage: component.teaserImage,
        fields: component.fields,
      }
    },
  },
  {
    nodeType: 'ContentfulModuleBox',
    component: InfoBox,
    props: (component: RvG.Contentful.IBoxRef): InfoBoxProps => {
      return {
        type: component.type,
        image: component.image?.image?.fluid,
        imageAlt: component.image?.image?.description,
        headline: component.headline,
        copy: component.copy,
        relatedLinks: component.relatedLinks,
      }
    },
  },
  {
    nodeType: 'ContentfulModuleFixed',
    component: FixedModule,
    props: (
      component: RvG.Contentful.IContentfulModuleFixed
    ): FixedModuleProps => {
      return {
        type: component.type,
        contentJson: component.contentJson,
      }
    },
  },
  {
    nodeType: 'ContentfulModulePictureGallery',
    component: PictureGallery,
    props: (
      component: RvG.Contentful.IContentfulModulePictureGallery
    ): PictureGalleryProps => {
      return {
        pictures: component.pictures,
      }
    },
  },
  {
    nodeType: 'ContentfulModuleTable',
    component: Table,
    props: (component: RvG.Contentful.IContentfulModuleTable): TableProps => {
      return {
        hasContainer: false,
        headline: component.headline,
        copy: component.copy,
        table: component.table,
        tableCellAlignment: component.tableCellAlignment,
        legend: component.legend,
      }
    },
  },
  {
    nodeType: 'ContentfulModuleDownloadButtons',
    component: DownloadBar,
    props: (
      component: RvG.Contentful.IContentfulModuleDownloadButtons
    ): DownloadBarProps => {
      return {
        img: component.image?.file,
        pdf: component.pdf?.file,
      }
    },
  },
  {
    nodeType: 'ContentfulModuleDownloadShopItem',
    component: DownloadShopTeaser,
    props: (component: RvG.Contentful.IContentfulModuleDownloadShopItem) => {
      return {
        id: component.id,
        headline: component.headline,
        copy: component.copy,
        thumbnails: component.thumbnails,
        file: component.file,
        amount: component.amount,
      }
    },
  },
  {
    nodeType: 'ContentfulModuleDownloadShopTeaserList',
    component: DownloadShopTeaserList,
    props: (
      component: RvG.Contentful.IContentfulModuleDownloadShopTeaserList
    ): DownloadShopTeaserListProps => {
      return {
        isArticle: true,
        headline: component.headline,
        sectionId: component.sectionId,
        downloadShopItems: component.downloadShopItems,
      }
    },
  },
  {
    nodeType: 'ContentfulModuleYouTubeGallery',
    component: YouTubeGallery,
    props: (
      component: RvG.Contentful.IContentfulModuleYouTubeGallery
    ): YouTubeGalleryProps => {
      return {
        headline: component.headline,
        copy: component.copy,
        items: component.items,
        isArticleGallery: true,
      }
    },
  },
]

export { findParser }

export default ComponentParser

import AvertaStdRegularSrc from '../../../static/font/AvertaStdRegular/font.woff'
import AvertaStdSemiboldSrc from '../../../static/font/AvertaStdSemibold/font.woff'
import AvertStdBoldSrc from '../../../static/font/AvertaStdBold/font.woff'
import DINProMediumSrc from '../../../static/font/DINProMedium/4ba8e512-e6fb-494f-afd3-a7b68b2e5efb.woff'

const AvertaStdRegular = {
  fontFamily: 'AvertaStd',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    url(${AvertaStdRegularSrc}) format('woff')
  `,
}

const AvertaStdSemibold = {
  fontFamily: 'AvertaStd',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
    url(${AvertaStdSemiboldSrc}) format('woff')
  `,
}

const AvertStdBold = {
  fontFamily: 'AvertaStd',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    url(${AvertStdBoldSrc}) format('woff')
  `,
}

const DINProMedium = {
  fontFamily: 'DINPro',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    url(${DINProMediumSrc}) format('woff')
  `,
}

export default [AvertaStdRegular, AvertaStdSemibold, AvertStdBold, DINProMedium]

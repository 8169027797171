import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Radio as FormRadio, FormControlLabel } from '@material-ui/core'
import { FieldInputProps, useField } from 'formik'
// import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'transparent',
    },

    '.has-error &': {
      color: theme.palette.error.dark,
    },
  },
  icon: {
    backgroundColor: theme.palette.background.main,
    borderRadius: 2,
    width: theme.spacing(6),
    height: theme.spacing(6),
    position: 'relative',

    '&.disabled': {
      backgroundColor: theme.palette.background.light,
    },
  },
  checkedIcon: {
    color: theme.palette.secondary.dark,
    position: 'absolute',
    top: '50%',
    left: theme.spacing(-0.5),
    transform: 'translateY(-45%)',
  },
}))

export type RadioProps = RvG.IReactDefaultProps & {
  value?: string | undefined
  name?: string
  disabled?: boolean | false
  checked?: boolean
  id?: string
  label: string
  onChange?: any
}

export default function Radio({
  className,
  name,
  error,
  ...props
}: RadioProps): ReactElement {
  const classes = useStyles()

  return (
    <>
      <FormControlLabel
        control={
          <FormRadio
            data-testid="radio"
            className={clsx(className, classes.checkbox, {
              error: error,
            })}
            name={name}
          />
        }
        {...props}
      />
    </>
  )
}

import React from 'react'
import Button from '@objects/button'
import useToggleComp from '@hooks/useToggleComp'
import Grid from '@material-ui/core/Grid'
import Img, { FluidObject } from 'gatsby-image'
import Copy from '@components/copy'
import Headline from '@objects/headline'
import Container from '@objects/container'
import { makeStyles } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { IDownloadShopOverlayProps } from '@objects/overlay/types/downloadShop'

export type DownloadShopTeaserListProps = RvG.IReactDefaultProps & {
  isArticle: boolean
  headline: string
  sectionId: string
  downloadShopItems: Array<RvG.Contentful.IContentfulModuleDownloadShopItem>
}

const useStyles = makeStyles((theme) => ({
  containerRoot: {
    paddingLeft: '0',
  },
  root: {
    padding: theme.spacing(2),
    maxWidth: '1104px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  img: {
    borderRadius: theme.spacing(1),
  },
  listGridItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  gridRoot: {
    border: `1px solid ${theme.palette.button.disabled}`,
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    maxWidth: '704px',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      padding: theme.spacing(6, 24),
    },
  },

  imgGridContainer: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },

  copyGridContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
    },
  },

  buttonGridContainer: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  copyRoot: {
    '& p': {
      margin: '0',
    },
  },
}))

export default function DownloadShopTeaserList({
  ...props
}: DownloadShopTeaserListProps) {
  const classes = useStyles()
  const muiTheme = useTheme()
  const { toggleOverlay } = useToggleComp()
  const isLg = useMediaQuery(muiTheme.breakpoints.up('lg'))

  const isArticle = props.isArticle

  const downloadOverlay = (item: IDownloadShopOverlayProps) => {
    toggleOverlay(true, {
      type: 'downloadShop',
      paperProps: { className: 'dark' },
      downloadProps: item,
      closeButton: 'hide',
    })
  }

  return (
    <>
      <Container className={isArticle ? classes.containerRoot : ''}>
        <Headline level={2}>{props.headline}</Headline>
        <Grid container>
          {props.downloadShopItems.map((item) => (
            <>
              <Grid className={classes.listGridItem} item>
                <Grid className={classes.gridRoot} container>
                  <Grid
                    className={classes.imgGridContainer}
                    item
                    xs={12}
                    lg={4}
                  >
                    <Img
                      fluid={
                        {
                          ...item.thumbnails[0].fluid,
                          aspectRatio: 152 / 116,
                        } as FluidObject
                      }
                      alt={''}
                      className={classes.img}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={8}
                    className={classes.copyGridContainer}
                  >
                    <Headline level={5}>{item.headline}</Headline>
                    {item.copy && (
                      <Copy richtext={item.copy} className={classes.copyRoot} />
                    )}
                    {isLg && (
                      <Button
                        type={'underlined'}
                        icon={'ChevronRight'}
                        iconPosition={'right'}
                        onClick={() => downloadOverlay(item)}
                      >
                        {item.amount.length > 0 ? (
                          <FormattedMessage id={'media.shop.openOverlay'} />
                        ) : (
                          <FormattedMessage id={'media.download.openOverlay'} />
                        )}
                      </Button>
                    )}
                  </Grid>
                  {!isLg && (
                    <Grid item xs={12} className={classes.buttonGridContainer}>
                      <Button
                        type={'underlined'}
                        icon={'ChevronRight'}
                        iconPosition={'right'}
                        onClick={() => downloadOverlay(item)}
                      >
                        {item.amount.length > 0 ? (
                          <FormattedMessage id={'media.shop.openOverlay'} />
                        ) : (
                          <FormattedMessage id={'media.download.openOverlay'} />
                        )}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>
      </Container>
    </>
  )
}

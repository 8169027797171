import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs'

import Icon from '@objects/icon'
import Container from '@objects/container'

export interface IBreadcrumb {
  label: string
  link?: string
}

export type BreadCrumbsProps = {
  crumbList: Array<IBreadcrumb>
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.breadcrumb,
  },
  link: {
    whiteSpace: 'nowrap',
    fontWeight: 700,
    position: 'relative',
    '&:after': {
      content: `''`,
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '0%',
      height: '2px',
      backgroundColor: theme.palette.button.secondaryTextHover,
      transition: `width 0.3s ${theme.transitions.easing.easeInOut}`,
    },
    '&:hover, &:focus': {
      '&:after': {
        width: '100%',
      },
    },
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'break-spaces',
  },
  home: {
    position: 'relative',
    display: 'inline-block',
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
    '&:focus': {
      color: theme.palette.text.invert,
      '&:before': {
        content: '""',
        position: 'absolute',
        top: theme.spacing(-2),
        left: theme.spacing(-2),
        right: theme.spacing(-2),
        bottom: theme.spacing(-2),
        borderRadius: '50%',
        background: theme.palette.background.focus,
      },
    },
    '&:hover': {
      color: theme.palette.primary.main,
      '&:before': {
        content: 'none !important',
      },
    },
    '& svg': {
      position: 'relative',
      verticalAlign: 'top',
    },
  },
}))

export default function BreadCrumbs({
  crumbList,
}: BreadCrumbsProps): ReactElement {
  const classes = useStyles()
  const intl = useIntl()

  function renderCrumbs() {
    return crumbList.map((crumb, i) => {
      if (crumb.link) {
        return (
          <Link
            key={`${crumb.label}${i}`}
            to={`${crumb.link}`}
            data-testid={'crumblink'}
            className={clsx(classes.link)}
          >
            {crumb.label}
          </Link>
        )
      } else {
        return (
          <span
            key={`${crumb.label}${i}`}
            data-testid={'crumblabel'}
            className={classes.label}
          >
            {crumb.label}
          </span>
        )
      }
    })
  }
  return (
    <Container type="breadcrumb" background="light">
      <MuiBreadcrumbs
        className={classes.root}
        color="inherit"
        aria-label={intl.formatMessage({ id: 'navigation.breadcrumb.label' })}
      >
        <Link className={classes.home} to="/">
          <Icon
            name="Home"
            aria-label={intl.formatMessage({
              id: 'navigation.breadcrumb.home',
            })}
            aria-hidden={false}
          />
        </Link>
        {renderCrumbs()}
      </MuiBreadcrumbs>
    </Container>
  )
}

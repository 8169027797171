import React, { ReactElement } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Add from '@material-ui/icons/Add'
import Create from '@material-ui/icons/Create'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import CloseIcon from '@material-ui/icons/Close'
import HomeIcon from '@material-ui/icons/Home'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined'
import SearchIcon from '@material-ui/icons/Search'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined'
import PhoneIcon from '@material-ui/icons/Phone'
import MailIcon from '@material-ui/icons/MailOutlined'
import YouTubeIcon from '@material-ui/icons/YouTube'
import TwitterIcon from '@material-ui/icons/Twitter'
import MenuIcon from '@material-ui/icons/Menu'

import About from '../../../static/img/svgIcons/about.inline.svg'
import Alert from '../../../static/img/svgIcons/alert.inline.svg'
import ArrowUp from '../../../static/img/svgIcons/arrow_up.inline.svg'
import ArrowDown from '../../../static/img/svgIcons/arrow_down.inline.svg'
import ArrowLeft from '../../../static/img/svgIcons/arrow_left.inline.svg'
import BarrierFree from '../../../static/img/svgIcons/barrier_free.inline.svg'
import Basket from '../../../static/img/svgIcons/basket.inline.svg'
import BasketColored from '../../../static/img/svgIcons/basket_colored.inline.svg'
import Checkmark from '../../../static/img/svgIcons/checkmark.inline.svg'
import ChartCheck from '../../../static/img/svgIcons/chart_check.inline.svg'
import ChartNoCheck from '../../../static/img/svgIcons/chart_nocheck.inline.svg'
import CheckCircle from '../../../static/img/svgIcons/check_circle.inline.svg'
import Descale from '../../../static/img/svgIcons/descale.inline.svg'
import Duration from '../../../static/img/svgIcons/dauer.inline.svg'
import Download from '../../../static/img/svgIcons/download.inline.svg'
import DownloadColored from '../../../static/img/svgIcons/download_colored.inline.svg'
import DownloadMaterial from '../../../static/img/svgIcons/download_material.inline.svg'
import EasyLang from '../../../static/img/svgIcons/easy_lang.inline.svg'
import Facebook from '../../../static/img/svgIcons/facebook.inline.svg'
import IlluBeginner from '../../../static/img/svgIcons/illu_anfaenger.inline.svg'
import IlluCommercial from '../../../static/img/svgIcons/illu_berufskraftfahrer.inline.svg'
import IlluBike from '../../../static/img/svgIcons/illu_fahrrad.inline.svg'
import IlluPedestrian from '../../../static/img/svgIcons/illu_fussgaenger.inline.svg'
import IlluChildren from '../../../static/img/svgIcons/illu_kinder.inline.svg'
import IlluMotorcycle from '../../../static/img/svgIcons/illu_motorrad.inline.svg'
import IlluCar from '../../../static/img/svgIcons/illu_pkw.inline.svg'
import IlluSeniors from '../../../static/img/svgIcons/illu_senioren.inline.svg'
import Layers from '../../../static/img/svgIcons/layers.inline.svg'
import Megaphone from '../../../static/img/svgIcons/megaphone.inline.svg'
import Quote from '../../../static/img/svgIcons/quote.inline.svg'
import Scale from '../../../static/img/svgIcons/scale.inline.svg'
import SearchEmpty from '../../../static/img/svgIcons/search_empty.inline.svg'
import StagePlay from '../../../static/img/svgIcons/button_stage_play.inline.svg'
import StagePause from '../../../static/img/svgIcons/button_stage_pause.inline.svg'
import Trash from '../../../static/img/svgIcons/trash.inline.svg'
import YouTubePlay from '../../../static/img/svgIcons/youtube_play.inline.svg'
import Instagram from '../../../static/img/svgIcons/instagram.inline.svg'
import MailFilled from '../../../static/img/svgIcons/mailfilled.inline.svg'
import NotFound from '../../../static/img/svgIcons/not_found.inline.svg'
import Gebaerdensprache from '../../../static/img/svgIcons/gebaerdensprache.inline.svg'

export type IconProps = {
  className?: string
  name?:
    | 'About'
    | 'Add'
    | 'Alert'
    | 'ArrowDown'
    | 'ArrowUp'
    | 'ArrowLeft'
    | 'Basket'
    | 'BarrierFree'
    | 'BasketColored'
    | 'Checkmark'
    | 'ChevronDown'
    | 'ChevronLeft'
    | 'ChartCheck'
    | 'ChartNoCheck'
    | 'ChevronRight'
    | 'ChevronUp'
    | 'Close'
    | 'Create'
    | 'Description'
    | 'Download'
    | 'Descale'
    | 'DownloadMaterial'
    | 'DownloadColored'
    | 'Duration'
    | 'Facebook'
    | 'CheckCircle'
    | 'Home'
    | 'IlluBeginner'
    | 'IlluBike'
    | 'IlluCar'
    | 'IlluChildren'
    | 'IlluCommercial'
    | 'IlluMotorcycle'
    | 'IlluPedestrian'
    | 'IlluSeniors'
    | 'Layers'
    | 'Mail'
    | 'Mailfilled'
    | 'Megaphone'
    | 'OpenInNew'
    | 'Phone'
    | 'Play'
    | 'Print'
    | 'Scale'
    | 'Search'
    | 'SearchEmpty'
    | 'StagePlay'
    | 'StagePause'
    | 'Speech'
    | 'Twitter'
    | 'YouTube'
    | 'Menu'
    | 'EasyLang'
    | 'Quote'
    | 'YouTubePlay'
    | 'Trash'
    | 'Instagram'
    | 'NotFound'
    | 'Gebaerdensprache'
  size?: 'inherit' | 'default' | 'small' | 'large'
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error'
  viewBox?: string
  fontSize?: number
}

export default function Icon({
  className,
  name,
  size,
  fontSize,
  color,
  viewBox,
}: IconProps): ReactElement | null {
  function renderIcon() {
    const svgProps = {
      fontSize: size,
      color: color,
      className: className,
    }
    viewBox && (svgProps['viewBox'] = viewBox)

    const illuProps = {
      ...svgProps,
      viewBox: '0 0 64 64',
      style: { fontSize: fontSize || 56 },
    }
    switch (name) {
      case 'About':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 48 48' }}>
            <About />
          </SvgIcon>
        )
      case 'Add':
        return (
          <SvgIcon {...svgProps}>
            <Add />
          </SvgIcon>
        )
      case 'Alert':
        return (
          <SvgIcon {...illuProps}>
            <Alert />
          </SvgIcon>
        )
      case 'ArrowDown':
        return (
          <SvgIcon {...svgProps}>
            <ArrowDown />
          </SvgIcon>
        )
      case 'ArrowUp':
        return (
          <SvgIcon {...svgProps}>
            <ArrowUp />
          </SvgIcon>
        )
      case 'ArrowLeft':
        return (
          <SvgIcon {...svgProps}>
            <ArrowLeft />
          </SvgIcon>
        )
      case 'BarrierFree':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 48 48' }}>
            <BarrierFree />
          </SvgIcon>
        )
      case 'Basket':
        return (
          <SvgIcon {...{ ...svgProps }}>
            <Basket />
          </SvgIcon>
        )
      case 'BasketColored':
        return (
          <SvgIcon {...{ ...svgProps }}>
            <BasketColored />
          </SvgIcon>
        )
      // return <ShoppingBasketIcon {...svgProps} />
      case 'Checkmark':
        return (
          <SvgIcon {...svgProps}>
            <Checkmark />
          </SvgIcon>
        )
      case 'ChevronDown':
        return <ExpandMoreIcon {...svgProps} />
      case 'ChevronLeft':
        return <ChevronLeftIcon {...svgProps} />
      case 'ChevronRight':
        return <ChevronRightIcon {...svgProps} />
      case 'ChevronUp':
        return <ExpandLessIcon {...svgProps} />
      case 'Close':
        return <CloseIcon {...svgProps} />
      case 'Create':
        return <Create {...svgProps} />
      case 'Description':
        return <DescriptionOutlinedIcon {...svgProps} />
      case 'Download':
        return (
          <SvgIcon {...svgProps}>
            <Download />
          </SvgIcon>
        )
      case 'DownloadColored':
        return (
          <SvgIcon {...svgProps}>
            <DownloadColored />
          </SvgIcon>
        )
      case 'DownloadMaterial':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 48 48' }}>
            <DownloadMaterial />
          </SvgIcon>
        )
      case 'Duration':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 16 16' }}>
            <Duration />
          </SvgIcon>
        )
      case 'EasyLang':
        return (
          <SvgIcon {...svgProps}>
            <EasyLang />
          </SvgIcon>
        )
      case 'Facebook':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 32 32' }}>
            <Facebook />
          </SvgIcon>
        )
      case 'Instagram':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 32 32' }}>
            <Instagram />
          </SvgIcon>
        )
      case 'Home':
        return <HomeIcon {...svgProps} />
      case 'IlluBeginner':
        return (
          <SvgIcon {...illuProps}>
            <IlluBeginner />
          </SvgIcon>
        )
      case 'IlluBike':
        return (
          <SvgIcon {...illuProps}>
            <IlluBike />
          </SvgIcon>
        )
      case 'IlluCar':
        return (
          <SvgIcon {...illuProps}>
            <IlluCar />
          </SvgIcon>
        )
      case 'IlluChildren':
        return (
          <SvgIcon {...illuProps}>
            <IlluChildren />
          </SvgIcon>
        )
      case 'IlluCommercial':
        return (
          <SvgIcon {...illuProps}>
            <IlluCommercial />
          </SvgIcon>
        )
      case 'IlluMotorcycle':
        return (
          <SvgIcon {...illuProps}>
            <IlluMotorcycle />
          </SvgIcon>
        )
      case 'IlluPedestrian':
        return (
          <SvgIcon {...illuProps}>
            <IlluPedestrian />
          </SvgIcon>
        )
      case 'IlluSeniors':
        return (
          <SvgIcon {...illuProps}>
            <IlluSeniors />
          </SvgIcon>
        )
      case 'Layers':
        return (
          <SvgIcon {...svgProps}>
            <Layers />
          </SvgIcon>
        )
      case 'Gebaerdensprache':
        return (
          <SvgIcon {...svgProps}>
            <Gebaerdensprache />
          </SvgIcon>
        )
      case 'Megaphone':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 48 48' }}>
            <Megaphone />
          </SvgIcon>
        )
      case 'Mail':
        return <MailIcon {...svgProps} />
      case 'Mailfilled':
        return <MailFilled {...svgProps} />
      case 'Menu':
        return <MenuIcon {...svgProps} />
      case 'OpenInNew':
        return <OpenInNewIcon {...svgProps} />
      case 'Phone':
        return <PhoneIcon {...svgProps} />

      case 'Print':
        return <PrintOutlinedIcon {...svgProps} />
      case 'Quote':
        return (
          <SvgIcon
            {...{
              ...svgProps,
              viewBox: '0 0 84 24',
            }}
          >
            <Quote />
          </SvgIcon>
        )
      case 'Scale':
        return (
          <SvgIcon {...svgProps}>
            <Scale />
          </SvgIcon>
        )
      case 'CheckCircle':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: viewBox ?? '0 0 94 92' }}>
            <CheckCircle />
          </SvgIcon>
        )
      case 'Descale':
        return (
          <SvgIcon {...svgProps}>
            <Descale />
          </SvgIcon>
        )
      case 'Search':
        return <SearchIcon {...svgProps} />
      case 'SearchEmpty':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 222 134' }}>
            <SearchEmpty />
          </SvgIcon>
        )
      case 'Speech':
        return <ForumOutlinedIcon {...svgProps} />
      case 'Twitter':
        return <TwitterIcon {...svgProps} />
      case 'YouTube':
        return <YouTubeIcon {...svgProps} />
      case 'YouTubePlay':
        return (
          <SvgIcon
            {...{
              ...svgProps,
              viewBox: '0 0 68 49',
            }}
            style={{
              width: '68px',
              height: '49px',
            }}
          >
            <YouTubePlay {...svgProps} />
          </SvgIcon>
        )
      case 'StagePlay':
        return (
          <SvgIcon
            {...{ ...svgProps, viewBox: '0 0 54 54' }}
            style={{
              width: '54px',
              height: '54px',
            }}
          >
            <StagePlay />
          </SvgIcon>
        )
      case 'StagePause':
        return (
          <SvgIcon
            {...{ ...svgProps, viewBox: '0 0 54 54' }}
            style={{
              width: '54px',
              height: '54px',
            }}
          >
            <StagePause />
          </SvgIcon>
        )
      case 'Trash':
        return (
          <SvgIcon {...{ ...svgProps }}>
            <Trash />
          </SvgIcon>
        )
      case 'ChartCheck':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <ChartCheck />
          </SvgIcon>
        )
      case 'ChartNoCheck':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <ChartNoCheck />
          </SvgIcon>
        )
      case 'NotFound':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: viewBox ?? '0 0 129 86' }}>
            <NotFound />
          </SvgIcon>
        )
      default:
        return null
    }
  }
  return renderIcon()
}

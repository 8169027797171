import React, { ReactElement } from 'react'
import Default from './variants/default'
import Content from './variants/content'

export type BigTeaserProps = RvG.IReactDefaultProps & {
  type: string
  imageFocalPoint?: RvG.Contentful.imageFocalPoint
  theme?: string
  layout?: string
  headline: string
  copy: RvG.Contentful.BasicRichTextType
  cta: RvG.Contentful.BasicRichTextType
  image: RvG.Contentful.IAsset
  mobileImage: RvG.Contentful.IAsset
}

export default function BigTeaser(props: BigTeaserProps): ReactElement {
  switch (props.type) {
    case 'Default':
      return <Default {...props} />
    default:
      return <Content {...props} />
  }
}

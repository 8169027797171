import React, { useEffect, useState, useRef, ReactElement } from 'react'
import { Link, navigate } from 'gatsby'
import clsx from 'clsx'
import { useTracking, useToggleComp } from '@hooks/index'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import useGlobalText from '@hooks/useGlobalText'

import Icon from '@objects/icon'
import SearchTextInput from '@objects/formfields/searchTextInput'
import Button from '@objects/button'

const useStyles = makeStyles((theme) => ({
  searchInputRoot: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      '& .MuiOutlinedInput-root .MuiInputBase-input': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },
  autoSuggestWrapper: {
    flex: 1,
    position: 'relative',
  },
  input: {
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(3),
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(3, 8),
      },
      height: '46px',
      boxSizing: 'border-box',
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.text.input}`,
      backgroundColor: theme.palette.background.main,
    },
    '& .Mui-focused': {
      '& .MuiInputBase-input': {
        borderStyle: 'dotted',
      },
    },
    '& .MuiInput-underline:after, & .MuiInput-underline:before': {
      display: 'none',
    },
  },
  button: {
    flex: '0 0 auto',
    marginLeft: theme.spacing(0),
    transition: 'none',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
    },
  },
  iconButton: {
    width: '46px',
    height: '46px',
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  suggest: {
    opacity: 0,
    visibility: 'hidden',
    transform: 'translateY(100%)',
    pointerEvents: 'none',
    zIndex: -1,

    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4, 0),
    },

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    fontWeight: 500,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[4],

    '&.has-results': {
      opacity: 1,
      visibility: 'visible',
      pointerEvents: 'inherit',
      zIndex: 10,
    },
    '& ul': {
      listStyleType: 'none',
      padding: '0',
      margin: '0',
      width: '100%',
    },
  },
  suggestLink: {
    padding: theme.spacing(3, 6, 3, 3),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 8),
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    position: 'relative',
    '& span': {
      transition: `border ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
      borderBottom: '2px solid transparent',
    },
    '&:focus, &:hover': {
      backgroundColor: theme.palette.background.light,
      '& span': {
        borderBottom: '2px solid currentColor',
      },
    },
  },
  icon: {
    position: 'absolute',
    right: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      right: theme.spacing(6),
    },
    top: '50%',
    transform: 'translateY(-50%)',
  },
}))

export type SearchInputProps = RvG.IReactDefaultProps & {
  searchfunc?: (searchquery: string) => void
  resultsAll?: RvG.SearchResult[]
  noautosuggest?: boolean
  onKeyEnter?: (event: React.KeyboardEvent) => void
  onSuggestClick?: (event: React.MouseEvent) => void
  isShown?: boolean
  searchquery?: string | string[]
}

export default function SearchInput({
  className,
  searchfunc,
  resultsAll,
  noautosuggest,
  searchquery,
  onKeyEnter,
  onSuggestClick,
  isShown,
}: SearchInputProps): ReactElement {
  const classes = useStyles()
  const theme = useTheme()
  const { getText } = useGlobalText()
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'))
  const { PushMessage } = useTracking()
  const { toggleOverlay } = useToggleComp()
  const inputRef = useRef<HTMLDivElement>(null)
  const suggestRef = useRef<HTMLDivElement>(null)
  const [forceSuggestHide, setForceSuggestHide] = useState(false)
  const [currentValue, setCurrentValue] = useState<string>('')
  const [TrackingTimeOut, setTrackingTimeOut] = useState<NodeJS.Timeout>()
  const [suggestPosition, setSuggestPosition] = useState(-1)

  function suggestClick(ev: React.MouseEvent) {
    if (onSuggestClick) onSuggestClick(ev)
  }

  function inputChangeEvent(ev: React.ChangeEvent<HTMLInputElement>) {
    if (TrackingTimeOut) clearTimeout(TrackingTimeOut)
    const value = ev.target.value
    setForceSuggestHide(value.length < 4)
    if (searchfunc) searchfunc(value)
    setSuggestPosition(-1)

    // put trackingevent in a timeout to prevent spamming unnessecary query-strings
    if (value.length > 3) {
      setTrackingTimeOut(
        setTimeout(() => {
          PushMessage('custom.search-pushed', {
            searchquery: value,
          })
        }, 1000)
      )
    }
    setCurrentValue(value)
  }

  function onInputKeydown(ev: React.KeyboardEvent) {
    if (ev.key === 'Enter' && currentValue) {
      navigate(`/suchergebnisse/#searchquery=${currentValue}`)
      if (onKeyEnter) onKeyEnter(ev)
    }
  }

  function onKeydown(ev: React.KeyboardEvent) {
    if (suggestRef?.current) {
      const links = suggestRef.current.querySelectorAll('a')
      if (
        ev.key === 'ArrowDown' ||
        (ev.key === 'Tab' && !ev.shiftKey && suggestPosition > -1)
      ) {
        const newpos = suggestPosition + 1
        if (newpos < links.length) {
          links[newpos].focus()
          setSuggestPosition(newpos)
          ev.preventDefault()
        }
      } else if (
        suggestPosition > -1 &&
        (ev.key === 'ArrowUp' || (ev.key === 'Tab' && ev.shiftKey))
      ) {
        const newpos = suggestPosition - 1
        if (newpos < 0) {
          // eslint-disable-next-line no-unused-expressions
          inputRef.current?.querySelector('input')?.focus()
        } else {
          links[newpos].focus()
        }
        setSuggestPosition(newpos)
        ev.preventDefault()
      }
    }
  }

  useEffect(() => {
    const input = inputRef?.current?.querySelector('input')
    if (searchquery && input && input.value !== searchquery) {
      setCurrentValue(searchquery)
      input.value = searchquery
    }
  }, [searchquery])

  useEffect(() => {
    if (isShown) {
      setTimeout(() => {
        inputRef?.current?.querySelector('input')?.focus()
      }, 200)
    }
  }, [isShown])

  function renderAutoSuggest() {
    if (!resultsAll?.length) return
    return [
      ...resultsAll.slice(0, 5).map((result, i) => {
        return (
          <li key={`search-result-item-${i}`} className={classes.suggestLink}>
            <Link to={result.path} onClick={suggestClick}>
              <span>{result.title}</span>
              <Icon className={classes.icon} name="ChevronRight" />
            </Link>
          </li>
        )
      }),
      resultsAll.length > 5 && (
        <li key="toallresults" className={classes.suggestLink}>
          <Link
            to={`/suchergebnisse/#searchquery=${currentValue}`}
            onClick={(ev: React.MouseEvent) => {
              toggleOverlay(false)
              suggestClick(ev)
            }}
          >
            <span>Alle {resultsAll.length} Ergebnisse Anzeigen</span>
          </Link>
        </li>
      ),
    ]
  }

  return (
    <div ref={inputRef} className={clsx(className, classes.searchInputRoot)}>
      <div className={classes.autoSuggestWrapper}>
        <SearchTextInput
          id="searchinput"
          className={classes.input}
          type="text"
          placeholder={getText('searchFormPlaceholder')}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
            inputChangeEvent(ev)
          }
          onKeyPress={(ev: React.KeyboardEvent) => onInputKeydown(ev)}
          onKeyDown={(ev: React.KeyboardEvent) => onKeydown(ev)}
          autoFocus
          autoComplete="off"
        />
        {!noautosuggest && (
          <div
            ref={suggestRef}
            className={clsx(classes.suggest, {
              'has-results': resultsAll?.length && !forceSuggestHide,
            })}
            onKeyDown={(ev) => onKeydown(ev)}
          >
            <ul>{renderAutoSuggest()}</ul>
          </div>
        )}
      </div>
      {isMediumUp ? (
        <Button
          className={classes.button}
          to={`/suchergebnisse#searchquery=${currentValue}`}
          onClick={(ev: React.MouseEvent) => suggestClick(ev)}
        >
          {getText('searchFormButtonText')}
        </Button>
      ) : (
        <Button
          type="icon"
          icon="Search"
          className={clsx(classes.button, classes.iconButton)}
          to={`/suchergebnisse#searchquery=${currentValue}`}
          onClick={(ev: React.MouseEvent) => suggestClick(ev)}
          invert
        />
      )}
    </div>
  )
}

import React, { ReactElement, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import Img from 'gatsby-image/withIEPolyfill'
import ParallaxImage from '@objects/parallaxImage'
import RvG from '@/types/rvg'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@objects/button'
import { FluidObject } from 'gatsby-image'

const useStyles = makeStyles((theme) => ({
  imageRoot: {
    margin: theme.spacing(6, 0, 15, 0),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(10, 0, 20, 0),
    },
    [theme.breakpoints.up('md')]: {
      '&$autoFitLeft': {
        marginTop: 0,
        marginBottom: 0,
        width: `calc((3/8 * 100%) + 2rem)`,
        float: 'left',
        paddingRight: '2rem',
        '&:before': {
          content: '""',
          display: 'table',
          width: '100%',
          clear: 'both',
          overflow: 'auto',
        },
        /*   '&:first-child': {
          marginTop: 0,
        }, */
      },

      '&$autoFitRight': {
        marginTop: 0,
        marginBottom: 0,
        width: `calc((3/8 * 100%) + 2rem)`,
        float: 'right',
        paddingLeft: '2rem',
        '&:before': {
          content: '""',
          display: 'table',
          width: '100%',
          clear: 'both',
          overflow: 'auto',
        },
        /*   '&:not(:first-child)': {
          marginTop: '40px',
        }, */
      },
    },
  },

  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.background.greyDark,
    opacity: 0,
    height: 0,
    zIndex: -1,
    transition: `opacity ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`,
    '.lightboxOpen &': {
      zIndex: theme.zIndex.modal,
      opacity: 0.8,
      height: '100%',
    },
  },
  lightboxOpen: {},
  lightboxImgWrapper: {
    '& $caption': {
      flexWrap: 'nowrap',
    },
  },
  imgWrapperInner: {
    position: 'relative',
    maxHeight: '90vh',
  },
  imgWrapper: {
    position: 'relative',
    '.lightboxOpen &': {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxHeight: '90vh',
      maxWidth: theme.container.lg,
      zIndex: theme.zIndex.modal + 1,
      padding: theme.spacing(0, 4),
      '& img': {
        maxHeight: '90vh',
      },
      [theme.breakpoints.down('lg')]: {},
    },
  },
  caption: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    ...theme.typography.caption,
    transition: `opacity 0s`,
    '.lightboxOpen &': {
      color: theme.palette.text.invert,
    },
    '&.captionIsVisible': {
      transition: `opacity ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`,
      opacity: 1,
    },
  },
  copyright: {
    ...theme.typography.helpertext,
    marginLeft: theme.spacing(4),
    textAlign: 'right',
  },
  scaleBtn: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: theme.spacing(0.5),
    '.lightboxOpen &': {
      right: theme.spacing(8),
    },
  },
  autoFitLeft: {},
  autoFitRight: {},
}))

type autoFitClass = `autoFitLeft` | `autoFitRight`

export type ImageProps = {
  className?: string
  image?: RvG.Contentful.IFluidImage
  imageMobile?: RvG.Contentful.IFluidImage
  alt?: string
  caption?: string
  copyright?: string
  parallax?: boolean
  staticPos?: boolean
  focalPoint?: RvG.Contentful.imageFocalPoint
  imgStyle?: {
    objectFit?: 'contain' | 'fill' | 'none' | 'cover' | 'scale-down' | undefined
    objectPosition?: string | undefined
    margin?: string
    clipPath?: string
  }
  lightbox?: boolean
  autoFit?: 'Left' | 'Right' | undefined
  lazyLoadingOff?: boolean
  ariaLabel?: string
}

const focalPointMap = {
  Center: '50% 50%',
  'Upper left': '25% 25%',
  'Upper center': '50% 25%',
  'Upper right': '75% 25%',
  'Lower left': '25% 75%',
  'Lower center': '50% 75%',
  'Lower right': '75% 75%',
}

export default function Image({
  image,
  imageMobile,
  alt,
  caption,
  copyright,
  className,
  parallax,
  staticPos,
  imgStyle,
  focalPoint,
  lightbox,
  autoFit,
  lazyLoadingOff,
  ariaLabel,
}: ImageProps): ReactElement {
  const classes = useStyles()
  const [overlay, setOverlay] = useState(false)
  const [placeholderHeight, setPlaceholderHeight] = useState(0)
  const lightBoxRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const sources = !!imageMobile
    ? [
        { ...imageMobile },
        {
          ...image,
          media: `(min-width: 768px)`,
        },
      ]
    : image

  const parallaxSources = !!imageMobile
    ? [
        imageMobile,
        {
          ...image,
          media: `(min-width: 768px)`,
        },
      ]
    : image

  function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      setOverlay(false)
    }
  }

  function toggleOverlay() {
    const { height } = lightBoxRef.current.getBoundingClientRect()
    setPlaceholderHeight(!overlay ? height : 0)
    setOverlay(!overlay)
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [])
  useEffect(() => {
    const htmlEl = document.getElementsByTagName('html')[0]
    const bodyEl = document.getElementsByTagName('body')[0]
    if (!overlay) {
      setPlaceholderHeight(0)
      htmlEl.style.overflow = ''
      bodyEl.style.overflow = ''
    } else {
      htmlEl.style.overflow = 'hidden'
      bodyEl.style.overflow = 'hidden'
    }
  }, [overlay])

  const aspectRatio = autoFit ? 1 : image?.aspectRatio
  const rescaledImage = {
    ...image,
    aspectRatio,
  }

  const sourcesRescaledImage = !!imageMobile
    ? [
        { ...imageMobile, aspectRatio, media: `(min-width: 0px)` },
        {
          ...image,
          aspectRatio,
          media: `(min-width: 768px)`,
        },
      ]
    : { image, aspectRatio, media: `(min-width: 0px)` }

  if (aspectRatio === 1) {
    imgStyle = imgStyle ? imgStyle : {}
    imgStyle['objectFit'] = 'contain'
  }

  let lazyLoading
  lazyLoadingOff === true ? (lazyLoading = { loading: 'eager' }) : ''

  return (
    <div
      className={clsx(className, classes.imageRoot, {
        lightboxOpen: overlay,
        [classes[`autoFit${autoFit}` as autoFitClass]]: autoFit,
      })}
      aria-label={ariaLabel}
      role="img"
    >
      {image && !parallax && lightbox && (
        <>
          <div className={classes.lightboxImgWrapper} ref={lightBoxRef}>
            <div
              className={classes.overlay}
              onClick={() => setOverlay(false)}
            />
            <div className={classes.imgWrapper}>
              <div className={classes.imgWrapperInner}>
                <Img
                  alt={alt}
                  fluid={
                    {
                      ...rescaledImage,
                      media: `(min-width: 0px)`,
                    } as FluidObject
                  }
                  backgroundColor={true}
                  style={{ position: staticPos ? 'static' : 'relative' }}
                  objectPosition={
                    !!focalPoint ? focalPointMap[focalPoint] : '50% 50%'
                  }
                  {...imgStyle}
                />
                <Button
                  type="icon"
                  icon={overlay ? 'Descale' : 'Scale'}
                  className={classes.scaleBtn}
                  onClick={toggleOverlay}
                  invert
                />
              </div>
              {(copyright || caption) && lightbox && (
                <div className={classes.caption}>
                  {caption && <div>{caption}</div>}
                  {copyright && (
                    <div className={classes.copyright}>{copyright}</div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div style={{ height: placeholderHeight }} />
        </>
      )}
      {image && !parallax && !lightbox && (
        <Img
          alt={alt}
          fluid={sources}
          backgroundColor={true}
          style={{ position: staticPos ? 'static' : 'relative' }}
          objectPosition={!!focalPoint ? focalPointMap[focalPoint] : '50% 50%'}
          {...imgStyle}
          {...lazyLoading}
        />
      )}
      {image && parallax && (
        <ParallaxImage scale={1.02} alt={alt} image={image} />
      )}
      {(copyright || caption) && !lightbox && (
        <div className={classes.caption}>
          {caption && <div>{caption}</div>}
          {copyright && <div className={classes.copyright}>{copyright}</div>}
        </div>
      )}
    </div>
  )
}

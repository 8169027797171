import React, { ReactElement, useState, useEffect, useRef } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'

import FontSize from '@config/theme/definitions/fontSize'

import Carousel from '@objects/carousel'
import Image, { ImageProps } from '@objects/image'
import Button from '@objects/button'

const heights = {
  xs: '221px',
  lg: '396px',
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(10, 0),
  },
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.background.greyDark,
    opacity: 0,
    height: 0,
    zIndex: -1,
    transition: `opacity ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`,
    '.carouselIsOpen &': {
      zIndex: theme.zIndex.modal,
      opacity: 0.8,
      height: '100%',
    },
  },
  image: {
    margin: 0,
    '& .gatsby-image-wrapper': {
      height: ({ autoHeight }: { autoHeight: boolean }) =>
        autoHeight ? 'auto' : heights.xs,
      [theme.breakpoints.up('lg')]: {
        height: ({ autoHeight }: { autoHeight: boolean }) =>
          autoHeight ? 'auto' : heights.lg,
      },
      '.carouselIsOpen &': {
        height: 'auto',
        [theme.breakpoints.up('md')]: {
          height: 'auto',
          maxHeight: '80vh',
        },
      },
    },
  },
  imageWrapper: {
    position: 'relative',
    backgroundColor: theme.palette.background.greyDark,
  },
  carousel: {
    position: 'relative',
    '.carouselIsOpen &': {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: theme.container.lg,
      zIndex: theme.zIndex.modal + 1,
      padding: theme.spacing(0, 4),
      [theme.breakpoints.down('lg')]: {
        '& .swiper-wrapper': {
          alignItems: 'center',
        },
      },
    },
  },
  caption: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
    opacity: 0,
    transition: `opacity 0s`,
    '.carouselIsOpen &': {
      color: theme.palette.text.invert,
    },
    '&.captionIsVisible': {
      transition: `opacity ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`,
      opacity: 1,
    },
    '&.copyrightNoteIsVisible': {
      transition: `opacity ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`,
      opacity: 1,
      justifyContent: 'space-between',
    },
  },
  captionIndicator: {
    fontWeight: 'bold',
    width: '60%',
    [theme.breakpoints.up('lg')]: {
      width: '20%',
      order: 1,
    },
  },
  captionDescription: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '60%',
      textAlign: 'center',
      order: 2,
    },
  },
  captionSource: {
    fontSize: FontSize.xs,
    width: '40%',
    textAlign: 'right',
    [theme.breakpoints.up('lg')]: {
      width: '20%',
      order: 3,
    },
  },
  navBtn: {
    position: 'absolute',
    top: ({ autoHeight }: { autoHeight: boolean }) =>
      autoHeight ? 'calc(50% - 26px)' : `calc(${heights.xs} / 2)`,
    [theme.breakpoints.up('lg')]: {
      top: ({ autoHeight }: { autoHeight: boolean }) =>
        autoHeight ? 'calc(50% - 26px)' : `calc(${heights.lg} / 2)`,
    },
    '.carouselIsOpen &': {
      top: 'calc(50% - 17px)',
      [theme.breakpoints.up('lg')]: {
        top: 'calc(auto / 2)',
      },
    },
    zIndex: 1,
    transform: 'translateY(-50%)',
    width: '28px',
    height: '71px',
  },
  navBtnPrev: {
    left: 0,
    '.carouselIsOpen &': {
      left: theme.spacing(4),
    },
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
  },
  navBtnNext: {
    right: 0,
    '.carouselIsOpen &': {
      right: theme.spacing(4),
    },
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
  },
  scaleBtn: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: theme.spacing(0.5),
  },
}))

type PictureItemProps = ImageProps & {
  copyrightNote?: string
  description?: string
  image: RvG.Contentful.IAsset
}

export type PictureGalleryProps = RvG.IReactDefaultProps & {
  pictures: Array<PictureItemProps>
  observer?: boolean
  observeParents?: boolean
  autoHeight?: boolean
}

export default function PictureGallery({
  pictures,
  observer,
  observeParents,
  autoHeight,
}: PictureGalleryProps): ReactElement {
  const classes = useStyles({ autoHeight: Boolean(autoHeight) })
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const carouselRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const [swiper, setSwiper] = useState<any>({})
  const [overlay, setOverlay] = useState(false)
  const [captionVisible, setCaptionVisible] = useState(true)
  const [placeholerHeight, setPlaceholerHeight] = useState(0)

  function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      exitOverlay()
    }
  }

  function exitOverlay() {
    setOverlay(false)
    setTimeout(() => {
      swiper.update() // ------> this solution
    }, 10)
  }

  function toggleOverlay() {
    const { height } = carouselRef.current.getBoundingClientRect()
    setPlaceholerHeight(!overlay ? height : 0)
    setOverlay(!overlay)
    setTimeout(() => {
      swiper.update() // ------> this solution
    }, 10)
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [])

  useEffect(() => {
    if (typeof swiper.allowTouchMove === 'boolean') {
      swiper.allowTouchMove = !isLarge
    }
  }, [isLarge])

  useEffect(() => {
    const htmlEl = document.getElementsByTagName('html')[0]
    const bodyEl = document.getElementsByTagName('body')[0]
    if (!overlay) {
      setPlaceholerHeight(0)
      htmlEl.style.overflow = ''
      bodyEl.style.overflow = ''
    } else {
      htmlEl.style.overflow = 'hidden'
      bodyEl.style.overflow = 'hidden'
    }
  }, [overlay])

  return (
    <section className={clsx(classes.root, { carouselIsOpen: overlay })}>
      <div className={classes.overlay} onClick={() => exitOverlay()} />
      <div ref={carouselRef} className={classes.carousel}>
        <Carousel
          onSwiper={setSwiper}
          speed={600}
          loop
          onSlideChangeTransitionStart={() => setCaptionVisible(false)}
          onSlideChangeTransitionEnd={() => setCaptionVisible(true)}
          autoHeight={false}
          observer={observer}
          observeParents={observeParents}
          centeredSlides={true}
        >
          {pictures.map((picture: PictureItemProps, i: number) => (
            <React.Fragment key={`picture-gallery-${i}`}>
              <div className={classes.imageWrapper}>
                <Image
                  className={classes.image}
                  image={picture.image.fluid}
                  alt={picture.image.description}
                  imgStyle={{
                    margin: '0',
                    objectFit: overlay ? 'contain' : 'contain',
                  }}
                />
                <Button
                  type="icon"
                  icon={overlay ? 'Descale' : 'Scale'}
                  className={classes.scaleBtn}
                  onClick={toggleOverlay}
                  invert
                />
              </div>

              {picture.caption && (
                <div
                  className={clsx(classes.caption, {
                    captionIsVisible: captionVisible,
                  })}
                >
                  <div className={classes.captionIndicator}>
                    Bild {i + 1} von {pictures.length}
                  </div>
                  {picture.copyrightNote && (
                    <div className={classes.captionSource}>
                      {picture.copyrightNote}
                    </div>
                  )}
                  {picture.caption && (
                    <div className={classes.captionDescription}>
                      {picture.caption}
                    </div>
                  )}
                </div>
              )}

              {!picture.caption && (
                <div
                  className={clsx(classes.caption, {
                    copyrightNoteIsVisible: captionVisible,
                  })}
                >
                  <div className={classes.captionIndicator}>
                    Bild {i + 1} von {pictures.length}
                  </div>
                  {picture.copyrightNote && (
                    <div className={classes.captionSource}>
                      {picture.copyrightNote}
                    </div>
                  )}
                  {picture.caption && (
                    <div className={classes.captionDescription}>
                      {picture.caption}
                    </div>
                  )}
                </div>
              )}
            </React.Fragment>
          ))}
        </Carousel>
        <Button
          type="icon"
          icon="ChevronLeft"
          className={clsx(classes.navBtn, classes.navBtnPrev)}
          onClick={() => swiper.slidePrev()}
        />
        <Button
          type="icon"
          icon="ChevronRight"
          className={clsx(classes.navBtn, classes.navBtnNext)}
          onClick={() => swiper.slideNext()}
        />
      </div>
      <div style={{ height: placeholerHeight }} />
    </section>
  )
}

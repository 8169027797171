import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import Headline from '@objects/headline'
import Image from '@objects/image'
import Icon from '@objects/icon'
import Copy from '@components/copy'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    borderRadius: theme.spacing(1),
    display: 'block',
    backgroundColor: theme.palette.background.light,
    color: theme.palette.text.primary,
    flex: 1,
    transition: `transform 0.4s ${theme.transitions.easing.easeOut}`,
    '&:hover': {
      transform: 'scale(1.02) translateZ(1px)',
      '& .gatsby-image-wrapper img': {
        transform: 'scale(1.1) translateZ(1px)',
      },
    },
  },
  rootLight: {
    backgroundColor: theme.palette.background.default,
  },
  rootHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  rootAutoHeight: {
    height: 'auto',
  },
  rootInteractive: {
    backgroundColor: 'transparent',
  },
  rootShowReadingTime: {
    position: 'relative',
    paddingBottom: '30px',
  },
  imageWrapper: {
    position: 'relative',
  },
  image: {
    margin: 0,
    '& .gatsby-image-wrapper  > div': {
      paddingBottom: '56.25% !important', // 16:9
    },
    '& .gatsby-image-wrapper  img': {
      transition: `transform 0.4s ${theme.transitions.easing.easeOut} !important`,
    },
  },
  imageInteractive: {
    '& .gatsby-image-wrapper': {
      height: '365px',
    },
  },
  imageHorizontal: {
    width: '228px',
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
    flexShrink: 0,
    '& .gatsby-image-wrapper': {
      height: '228px',
    },
  },
  imageModuleTeaser: {
    width: '276px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
    },
    flexShrink: 0,
    '& .gatsby-image-wrapper': {
      height: '228px',
    },
  },
  imageHalfAndHalf: {
    width: '50%',
    '& .gatsby-image-wrapper': {
      height: '228px',
    },
  },
  content: {
    position: 'relative',
    padding: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      height: '100%',
    },
  },
  contentInteractive: {
    position: 'absolute',
    bottom: '0',
    transform: 'translateY(100%) translateY(40px)',
    transition: 'none',
    opacity: 0,
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(4, 4, 8, 4),
    borderBottom: `${theme.spacing(1)}px solid ${theme.palette.primary.main}`,
    '&.current': {
      transform: 'translateY(0%)',
      opacity: 1,
      transition: `transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut},
      opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
    },
  },
  contentHorizontal: {
    flex: 1,
    flexShrink: 0,
    height: 'auto',
  },
  headline: {
    ...theme.typography.h4,
    '$rootShowReadingTime &': {
      fontSize: '18px',
      lineHeight: '23px',
      fontWeight: 500,
    },
  },
  copy: {
    '& p': {
      marginBottom: 0,
    },
    '$rootShowReadingTime &': {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  copyMobileLineClamp: {
    '& p': {
      boxOrient: 'vertical',
      lineClamp: 3,
      [theme.breakpoints.up('lg')]: {
        display: '-webkit-box',
        overflow: 'hidden',
        lineClamp: 4,
      },
    },
  },
  triangle: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: theme.spacing(10),
    zIndex: 1,
    width: '100%',
    transform: 'translateY(-100%)',
    backgroundImage: `linear-gradient(to right top, ${theme.palette.background.light} 0%, ${theme.palette.background.light} 50%, transparent 51%)`,
  },
  reference: {
    marginTop: theme.spacing(3),
    '& span': {
      fontWeight: (500 + '!important') as string,
      textDecoration: 'none !important',
      position: 'relative',
      display: 'inline-block',
      margin: theme.spacing(1.5, 0),
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: '-2px',
        left: 0,
        width: '100%',
        borderBottom: `${theme.palette.primary.main} 2px solid`,
      },
    },
  },
  readingTimeWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: '15px',
    left: '15px',
  },
  readingTimeIcon: {
    marginRight: '5px',
  },
  readingTimeCopy: {
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '16px',
  },
}))

export type TeaserProps = RvG.IReactDefaultProps & {
  headline?: string
  copy?: RvG.Contentful.BasicRichTextType
  image?: RvG.Contentful.IAsset
  fullPath?: string
  transitionDelay?: number
  hasTriangle?: boolean
  interactive?: boolean
  current?: boolean
  horizontal?: boolean
  light?: boolean
  halfAndHalf?: boolean
  showReadingTime?: boolean
  cta?: string
  fixedContentHeight?: number
  readingTime?: string
  ariaRole?: string
}

export default function Teaser({
  headline,
  copy,
  image,
  fullPath,
  transitionDelay,
  hasTriangle,
  interactive,
  current,
  horizontal,
  light = false,
  showReadingTime = false,
  halfAndHalf,
  cta,
  fixedContentHeight,
  readingTime,
  className,
  ariaRole,
}: TeaserProps): ReactElement {
  const classes = useStyles(current)
  const cmpclassnames = clsx(classes.root, className, {
    [classes.rootInteractive]: interactive,
    [classes.rootHorizontal]: horizontal,
    [classes.rootAutoHeight]: halfAndHalf,
    [classes.rootLight]: light,
    [classes.rootShowReadingTime]: showReadingTime,
  })
  const intl = useIntl()

  function renderTeaserInner() {
    return (
      <React.Fragment>
        <div
          className={clsx(classes.imageWrapper, {
            [classes.imageInteractive]: interactive,
            [classes.imageHorizontal]: horizontal && !halfAndHalf,
            [classes.imageModuleTeaser]:
              horizontal && !halfAndHalf && !fullPath,
            [classes.imageHalfAndHalf]: halfAndHalf,
          })}
        >
          {image?.fluid && (
            <Image
              className={clsx(classes.image, {
                [classes.imageInteractive]: interactive,
              })}
              image={image.fluid}
              imgStyle={{
                margin: '0',
              }}
            />
          )}
        </div>
        <div
          className={clsx(classes.content, {
            [classes.contentInteractive]: interactive,
            [classes.contentHorizontal]: horizontal,
            current,
          })}
          style={{
            transitionDelay: `${transitionDelay}ms`,
            height: `${fixedContentHeight}px`,
          }}
        >
          {hasTriangle && <div className={classes.triangle} />}
          <Headline className={classes.headline} level={3}>
            {headline}
          </Headline>
          {!!copy && (
            <Copy
              className={clsx(classes.copy, {
                [classes.copyMobileLineClamp]: horizontal,
              })}
              richtext={copy}
              ariaRole="paragraph"
            />
          )}
          {cta && (
            <div className={classes.reference} role="link" aria-label={cta}>
              <span>{cta}</span>
              <Icon name="ChevronRight" size="inherit" />
            </div>
          )}
        </div>
        {showReadingTime && readingTime && (
          <div className={classes.readingTimeWrapper}>
            <Icon
              className={classes.readingTimeIcon}
              name="Duration"
              size="inherit"
            />
            <span
              className={classes.readingTimeCopy}
              aria-label={intl.formatMessage({
                id: 'teaser.readingtime.aria.label',
              })}
            >
              {readingTime}
            </span>
          </div>
        )}
      </React.Fragment>
    )
  }

  return (
    <>
      {!!fullPath && (
        <Link
          data-testid={'teaser'}
          className={cmpclassnames}
          to={fullPath}
          role="link"
        >
          {renderTeaserInner()}
        </Link>
      )}
      {!fullPath && (
        <div data-testid={'teaser'} className={cmpclassnames} role={ariaRole}>
          {renderTeaserInner()}
        </div>
      )}
    </>
  )
}

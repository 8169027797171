import React, { ReactElement } from 'react'

import { ComponentParserPropsUnion, findParser } from './componentParser'

type ContentfulComponentProps = {
  component: RvG.Contentful.ArticleModuleUnion
}

export default function ContenfulComponent({
  component,
}: ContentfulComponentProps): ReactElement {
  function renderComponent() {
    const Parser = findParser(component)

    if (!Parser) {
      if (typeof window !== 'undefined') {
        console.log('Component not defined: ', component)
      }
      return null
    } else {
      // TODO: props type not correct
      return React.createElement<ComponentParserPropsUnion>(
        Parser.component,
        Parser.props(component)
      )
    }
  }
  return <>{renderComponent()}</>
}

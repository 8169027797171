import React, { ReactElement } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { InfoBoxProps } from './../index'

import Image from '@objects/image'
import Headline from '@objects/headline'

import FontSize from '@config/theme/definitions/fontSize'

const useStyles = makeStyles((theme) => ({
  boxRoot: {
    borderTop: `12px ${theme.palette.primary.main} solid`,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.greyDark,
    margin: theme.spacing(15, 0),
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      background: `linear-gradient(90deg, ${theme.palette.background.greyDark} 0%, ${theme.palette.background.greyDark} 75%, rgba(255,255,255,0) 75%, rgba(255,255,255,0) 100%)`,
    },
  },
  factImageWrapper: {
    flexGrow: 1,
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {},
  },
  factImage: {
    maxWidth: '100%',
    margin: 0,
    height: 0,
    paddingTop: '60%',
    [theme.breakpoints.up('lg')]: {
      paddingTop: '45%',
    },
  },
  factHeadlineWrapper: {
    backgroundColor: theme.palette.background.greyDark,
    padding: theme.spacing(8, 4),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(8),
      maxWidth: '45%',
    },
  },
  factHeadline: {
    color: theme.palette.text.invert,
    fontSize: FontSize['3xl'],
    lineHeight: 1.25,
  },
}))

export default function FactBox({
  className,
  image,
  imageAlt,
  headline,
}: InfoBoxProps): ReactElement {
  const classes = useStyles()

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

  const mobileImgStyle = {
    clipPath: 'polygon(0px 13%, 100% 0px, 100% 101%, 0px 101%)',
  }

  const desktopImgStyle = {
    clipPath: 'polygon(13% -1px, 100% -1px, 100% 101%, 0px 101%)',
  }

  return (
    <div className={clsx(className, classes.boxRoot)}>
      <div className={classes.factHeadlineWrapper}>
        {headline && (
          <Headline level={3} className={classes.factHeadline}>
            {headline}
          </Headline>
        )}
      </div>
      <div className={classes.factImageWrapper}>
        <Image
          className={classes.factImage}
          image={image}
          alt={imageAlt}
          staticPos
          imgStyle={isLarge ? desktopImgStyle : mobileImgStyle}
        />
      </div>
    </div>
  )
}

FactBox.defaultProps = {}

import React, { ReactElement } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import sendMessage from '@api/contact'
import Button from '@objects/button'
import FriendlyCaptcha from '@objects/formfields/friendlycaptcha'

import TextField from '@objects/formfields/textField'
import CheckboxField from '@objects/formfields/checkboxField'
import RichTextEditor from '@objects/formfields/richtext'
import { useToggleComp } from '@hooks/index'
import Container from '@objects/container'
import Headline from '@objects/headline'
import { navigate } from 'gatsby'
import ScrollToFormikFieldError from '@tools/ScrollToFormikFieldError'

const useStyles = makeStyles((theme) => ({
  headline: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(20),
    },
  },
  formContainer: {
    padding: theme.spacing(20, 0),
  },
  button: {
    margin: theme.spacing(7, 'auto', 0, 'auto'),
    display: 'inherit',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(7, 0, 0, 0),
    },
    '&:focus': {
      border: '2px dotted currentColor',
    },
  },
}))

export default function ContactForm(): ReactElement {
  const intl = useIntl()
  const classes = useStyles()
  const { toggleOverlay } = useToggleComp()
  const validationSchema = Yup.object({
    name: Yup.string().required(
      intl.formatMessage({ id: 'contact.name.error.required' })
    ),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'contact.email.error' }))
      .required(intl.formatMessage({ id: 'contact.email.error.required' })),
    text: Yup.string().required(
      intl.formatMessage({ id: 'contact.text.error.required' })
    ),
    termsOfPrivacy: Yup.boolean().oneOf(
      [true],
      intl.formatMessage({ id: 'contact.error.required' })
    ),
    friendlyCaptchaCode: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: 'contact.error.required' })),
  })

  type ContactFormValues = {
    name: string
    email: string
    text: string
    termsOfPrivacy: boolean
    friendlyCaptchaCode: string
  }

  const initialValues: ContactFormValues = {
    name: '',
    email: '',
    text: '',
    termsOfPrivacy: false,
    friendlyCaptchaCode: '',
  }

  //@TODO: unexpected type 'any'
  function _handleSubmit(
    values: ContactFormValues,
    { setSubmitting }: FormikHelpers<ContactFormValues>
  ): void {
    sendMessage(values)
      .then((response: Response) => {
        if (response.status === 202) {
          navigate('/kontakt/thanks/')
        } else {
          toggleOverlay(true, {
            type: 'contactError',
            trackingID: 'contactError',
            width: 'sm',
            dialogType: 'small',
            onAcceptance: () => toggleOverlay(false),
          })
        }
        setSubmitting(false)
      })
      .catch(() => {
        toggleOverlay(true, {
          type: 'contactError',
          trackingID: 'contactError',
          width: 'sm',
          dialogType: 'small',
          onAcceptance: () => toggleOverlay(false),
        })
        setSubmitting(false)
      })
  }

  return (
    <>
      <Container width={'sm'} type="section">
        <Headline className={classes.headline} level={1}>
          <FormattedMessage id={'contact.headline'} />
        </Headline>
        <p
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: 'contact.subline' }),
          }}
        />
      </Container>
      <Container
        className={classes.formContainer}
        width={'sm'}
        background={'light'}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_handleSubmit}
          validateOnBlur={false}
        >
          {({ isSubmitting }) => (
            <Form id="contact">
              <ScrollToFormikFieldError />
              <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="name.0"
                    name="name"
                    type="text"
                    variant="outlined"
                    label={intl.formatMessage({
                      id: 'contact.name.label',
                    })}
                    autoComplete="name"
                    customVariant={'form'}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    data-testid="mail-input"
                    id="email.0"
                    type="email"
                    name="email"
                    variant="outlined"
                    label={intl.formatMessage({
                      id: 'contact.email.label',
                    })}
                    autoComplete="email"
                    customVariant={'form'}
                  />
                </Grid>
                <Grid item xs={12} id="text.0">
                  <RichTextEditor
                    id="text"
                    name="text"
                    maxLength={750}
                    label={intl.formatMessage({
                      id: `contact.text.label`,
                    })}
                    showInfoText
                  />
                </Grid>
                <Grid item id="termsOfPrivacy.0">
                  <CheckboxField
                    name="termsOfPrivacy"
                    labelId="contact.privacy.label"
                    labelLink="dataprivacy.path"
                  />
                  <div id="friendlyCaptchaCode.0">
                    <FriendlyCaptcha name="friendlyCaptchaCode" />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={classes.button}
                    disabled={isSubmitting}
                    type="main"
                    formSubmit
                    aria-label={intl.formatMessage({ id: 'contact.button' })}
                  >
                    {intl.formatMessage({ id: 'contact.button' })}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

import React, { ReactElement } from 'react'
import Typography from '@material-ui/core/Typography'

export type HeadlineProps = RvG.IReactDefaultProps & {
  level?: number
  id?: string
  html?: string
}

export default function Headline({
  className,
  children,
  level,
  id,
  html,
  ...props
}: HeadlineProps): ReactElement {
  function renderChildren() {
    if (html) return null
    return children
  }
  function Level() {
    const Props = {
      id: id,
      className: className,
      ...(html && {
        dangerouslySetInnerHTML: {
          __html: html,
        },
      }),
      ...props,
    }
    switch (level) {
      case 1:
        return (
          <Typography variant={'h1'} {...Props} role="heading">
            {renderChildren()}
          </Typography>
        )
      case 3:
        return (
          <Typography variant={'h3'} {...Props} role="heading">
            {renderChildren()}
          </Typography>
        )
      case 4:
        return (
          <Typography variant={'h4'} {...Props} role="heading">
            {renderChildren()}
          </Typography>
        )
      case 5:
        return (
          <Typography variant={'h5'} {...Props} role="heading">
            {renderChildren()}
          </Typography>
        )
      default:
        return (
          <Typography variant={'h2'} {...Props} role="heading">
            {renderChildren()}
          </Typography>
        )
    }
  }
  return <>{Level()}</>
}

import React, { ReactElement, useState, useEffect, useRef } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import SwiperClass from 'swiper/types/swiper-class'

import Container from '@objects/container'
import InViewAnimation from '@objects/inViewAnimation'
import Youtube from '@objects/youtube'
import Headline from '@objects/headline'
import Copy from '@components/copy'
import Carousel from '@objects/carousel'
import Button from '@objects/button'
import Accordion from '@objects/accordion'

import FontSize from '@config/theme/definitions/fontSize'

const useStyles = makeStyles((theme) => ({
  isArticleGalleryRoot: {
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(8),
    },
  },
  root: {
    padding: theme.spacing(8, 0),
    background: `linear-gradient(0deg, ${theme.palette.background.light} 50%, ${theme.palette.background.default} 50%)`,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(20, 0),
    },
  },
  activeVideo: {
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(15),
    },
  },
  activeVideoText: {
    backgroundColor: theme.palette.background.greyDark,
    color: theme.palette.text.invert,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(8, 12, 1, 12),
    },
    '& .MuiButtonBase-root, & .MuiAccordionDetails-root': {
      backgroundColor: theme.palette.background.greyDark,
      color: theme.palette.text.invert,
      marginTop: 0,
    },
  },
  activeVideoTextCaption: {
    fontSize: FontSize['sm'],
  },
  carousel: {
    background: theme.palette.background.light,
    position: 'relative',
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(8, 12),
    },
  },
  carouselItem: {
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
  },
  navBtn: {
    position: 'absolute',
    top: '50%',
    zIndex: 1,
    width: '46px',
    height: '46px',
    borderRadius: theme.spacing(1),
  },
  navBtnPrev: {
    left: 0,
    transform: 'translate(50%, -50%)',
  },
  navBtnNext: {
    right: 0,
    transform: 'translate(-50%, -50%)',
  },
}))

export type YouTubeGalleryProps = RvG.IReactDefaultProps & {
  headline: string
  copy: RvG.Contentful.BasicRichTextType
  items?: Array<RvG.Contentful.IContentfulYouTubeItem>
  isArticleGallery?: boolean
}

export default function YouTubeGallery({
  headline,
  copy,
  items,
  isArticleGallery = false,
}: YouTubeGalleryProps): ReactElement {
  const youTubeRef = useRef<ReactElement | null | any>(null)
  const classes = useStyles()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const showNavigationButtons = isLarge && items && items.length > 4
  const [swiper, setSwiper] = useState<SwiperClass>()
  const [activeVideo, setActiveVideo] =
    useState<RvG.Contentful.IContentfulYouTubeItem | null>(
      items?.length ? items[0] : null
    )
  const [isBeginning, setIsBiginning] = useState<boolean>(true)
  const [isEnd, setIsEnd] = useState<boolean>(false)

  function onThumbnailClick(
    item: RvG.Contentful.IContentfulYouTubeItem,
    index: number
  ) {
    const activeIndex = swiper?.activeIndex || 0
    const slidesPerView = isLarge ? 4 : 1.5

    if (index <= activeIndex + 1) {
      swiper?.slidePrev()
    } else if (index >= activeIndex + slidesPerView - 2) {
      swiper?.slideNext()
    }

    setActiveVideo(item)
    youTubeRef?.current?.playVideo()
  }

  useEffect(() => {
    if (typeof swiper?.allowTouchMove === 'boolean') {
      swiper.allowTouchMove = !isLarge
    }
  }, [isLarge])

  function onSwiper(swiper: SwiperClass): void {
    setSwiper(swiper)
  }

  return (
    <div
      className={isArticleGallery ? classes.isArticleGalleryRoot : classes.root}
    >
      <Container type="nomargin" style={{ padding: isArticleGallery && 0 }}>
        {!isArticleGallery && (
          <Grid container>
            <Grid item xs={12} md={8}>
              <InViewAnimation
                group={[
                  <Headline key={`youtube-gallery-1`}>{headline}</Headline>,
                  <Copy key={`youtube-gallery-2`} richtext={copy} />,
                ]}
              />
            </Grid>
          </Grid>
        )}
        <div className={classes.activeVideo}>
          {activeVideo && (
            <InViewAnimation>
              {youTubeRef && (
                <Youtube
                  key={activeVideo.youTubeId}
                  ref={youTubeRef}
                  youTubeId={activeVideo.youTubeId}
                  thumbnail={activeVideo.thumbnail}
                  isGalleryItem
                />
              )}
            </InViewAnimation>
          )}
          {activeVideo && (
            <div className={classes.activeVideoText}>
              <InViewAnimation>
                {isLarge ? (
                  <>
                    <Headline level={5}>{activeVideo.title}</Headline>
                    <Copy
                      className={classes.activeVideoTextCaption}
                      richtext={activeVideo.description}
                    />
                  </>
                ) : (
                  <Accordion
                    id="youtube-video"
                    className={classes.activeVideoTextCaption}
                    items={[
                      {
                        id: 'item-1',
                        headline: activeVideo.title,
                        copy: activeVideo.description,
                      },
                    ]}
                  />
                )}
              </InViewAnimation>
            </div>
          )}
        </div>
        {!!items && items.length > 1 && (
          <div className={classes.carousel}>
            <InViewAnimation>
              <Carousel
                onSwiper={onSwiper}
                slidesPerView={isLarge ? 4 : 1.5}
                spaceBetween={24}
                slideToClickedSlide={true}
                a11y={{ enabled: false }}
                onActiveIndexChange={({
                  isBeginning,
                  isEnd,
                }: {
                  isBeginning: boolean
                  isEnd: boolean
                }) => {
                  setIsBiginning(isBeginning)
                  setIsEnd(isEnd)
                }}
              >
                {items.map(
                  (item: RvG.Contentful.IContentfulYouTubeItem, i: number) => (
                    <Youtube
                      key={`youtube-item-${i}`}
                      youTubeId={item.youTubeId}
                      thumbnail={item.thumbnail}
                      thumbnailOnly
                      onThumbnailClick={() => onThumbnailClick(item, i)}
                      className={classes.carouselItem}
                      isGalleryItem
                    />
                  )
                )}
              </Carousel>
              {!isBeginning && showNavigationButtons && (
                <Button
                  type="icon"
                  icon="ChevronLeft"
                  className={clsx(classes.navBtn, classes.navBtnPrev)}
                  onClick={() => swiper?.slidePrev()}
                />
              )}
              {!isEnd && showNavigationButtons && (
                <Button
                  type="icon"
                  icon="ChevronRight"
                  className={clsx(classes.navBtn, classes.navBtnNext)}
                  onClick={() => swiper?.slideNext()}
                />
              )}
            </InViewAnimation>
          </div>
        )}
      </Container>
    </div>
  )
}

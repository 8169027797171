import React, { ReactElement } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import Icon, { IconProps } from '@objects/icon'

const useStyles = makeStyles(() => ({
  socialIcon: {
    cursor: 'pointer',
    display: 'inline-block',
    border: 0,
    outline: 0,
    background: 'none',
    padding: 0,
    font: 'inherit',
    color: 'inherit',
  },
}))

export type SocialIconProps = RvG.IReactDefaultProps & {
  item: {
    name: string
    size?: IconProps['size']
    className?: string
    href?: string
    label?: string
    onClick?: () => void
  }
}

export default function SocialIcon({
  className,
  item,
  label,
}: SocialIconProps): ReactElement {
  const classes = useStyles()

  return item.href ? (
    <a
      href={item.href}
      rel="noopener noreferrer"
      target="_blank"
      className={clsx(className, classes.socialIcon)}
      data-testid="social-icon"
      aria-label={label}
    >
      <Icon className={item.className} name={item.name} size={item.size} />
    </a>
  ) : item.onClick ? (
    <button
      onClick={item.onClick}
      className={clsx(className, classes.socialIcon)}
      data-testid="social-icon-click"
    >
      <Icon className={item.className} name={item.name} size={item.size} />
    </button>
  ) : (
    <></>
  )
}

import React, { ReactElement, useEffect, useRef } from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import SimpleParallax from 'simple-parallax-js'

interface IParallaxImage extends RvG.IReactDefaultProps {
  outerClassName?: string
  image: RvG.Contentful.IFluidImage
  alt?: string
  scale: number
}

export default function ParallaxImage({
  className,
  outerClassName,
  image,
  alt,
  scale,
}: IParallaxImage): ReactElement {
  const CompRef = useRef<HTMLDivElement>(null) // Should be HTMLDivElement, somehow not working here
  const ParallaxRef = useRef<SimpleParallax | null>(null) // should be SimpleParallax, but not available

  useEffect(() => {
    import('simple-parallax-js')
      .then((simpleParallax) => {
        if (!CompRef.current?.querySelector('img')) return
        ParallaxRef.current = new simpleParallax.default(
          CompRef.current.querySelector('img') as HTMLImageElement, // casting here because we know from previous if, that we have her an HTMLImageElement
          {
            scale: scale,
            customWrapper: '.gatsby-image-wrapper',
          }
        )
      })
      .catch((e) => console.log(e))
    return () => {
      if (ParallaxRef.current) ParallaxRef.current.destroy()
    }
  }, [])

  return (
    <div ref={CompRef} className={outerClassName}>
      <Img
        className={className}
        imgStyle={{
          transform: `scale(${scale})`,
        }}
        fluid={{ ...image, media: `(min-width: 0px)` }}
        backgroundColor={true}
        alt={alt || ''}
      />
    </div>
  )
}

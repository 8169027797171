import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
  },
}))

export type VideoProps = {
  src: string
}

export default function Video({ src }: VideoProps): React.ReactElement {
  const classes = useStyles()
  return <video className={classes.root} src={src} controls></video>
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@objects/formfields'
import { useField } from 'formik'

export interface IMaterialShopFormProductDetailsSectionProps {
  id: string
  parts: {
    label?: string
    fields: {
      id: string
      label: string
      type: string
      isRequired?: boolean
    }[]
    values?: { [key: string]: string }
  }[]
}

const useStyles = makeStyles((theme) => ({
  section: {},
  sectionHeader: {
    fontWeight: 'bold',
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
  numberField: {
    display: 'flex',
    alignItems: 'center',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  field: {
    display: 'block',
    width: '100%',
  },
  inputField: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: 700,
    padding: '8px',
    width: '100%',
    boxSizing: 'border-box',
    height: '46px',
    maxWidth: '70px',
    textAlign: 'center',
  },
  inputLabel: {
    marginLeft: theme.spacing(2),
  },
}))

export default function MaterialShopFormProductDetailsSection({
  id,
  parts,
}: IMaterialShopFormProductDetailsSectionProps): React.ReactElement {
  const classes = useStyles()

  return (
    <>
      {parts.map((part, index) => (
        <div className={classes.section} key={index}>
          <p className={classes.sectionHeader}>{part.label}</p>
          <div className={classes.fields}>
            {part.fields.map((field) => {
              const fieldId = `${id}.${field.id}`
              const [formField] = useField(fieldId)
              return field.type === 'number' ? (
                <div className={classes.numberField} key={fieldId}>
                  <input
                    className={classes.inputField}
                    id={fieldId}
                    name={fieldId}
                    type={field.type}
                    value={formField.value}
                    onChange={formField.onChange}
                    required={field.isRequired}
                  />
                  <label className={classes.inputLabel} htmlFor={fieldId}>
                    {field.label}
                  </label>
                </div>
              ) : (
                <div className={classes.field} key={field.id}>
                  <TextField
                    fullWidth
                    id={fieldId}
                    name={fieldId}
                    type={field.type}
                    label={field.label}
                    variant={'outlined'}
                    customVariant={'form'}
                    required={field.isRequired}
                  />
                </div>
              )
            })}
          </div>
        </div>
      ))}
    </>
  )
}

import React, { ReactElement, useEffect, useRef } from 'react'
import { useField } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import FormHelperText from './helperText'
import { WidgetInstance } from 'friendly-challenge'

const useStyles = makeStyles((theme) => ({
  recaptchaRoot: {
    marginTop: theme.spacing(8),
    '& .frc-container': {
      width: '350px',
    },
    '& .frc-captcha': {
      background: 'rgb(245, 242, 240)',
      width: '350px',
      '& button': {
        justifyContent: 'center',
        width: '160px',
      },
    },
    '& .frc-text': {
      marginBottom: '4px',
    },
    '& .frc-banner': {
      display: 'none',
    },
    '& .frc-icon': {
      width: '48px',
      height: '48px',
    },
    '& .frc-button': {
      overflow: 'hidden',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      outline: 'none',
      fontFamily: 'inherit',
      lineHeight: 1.1,
      cursor: 'pointer',
      transition: `all ${theme.transitions.duration.shorter} ${theme.transitions.easing.easeInOut}`,
      position: 'relative',
      height: '100%',
      padding: theme.spacing(2, 6),
      ...theme.typography.buttonPrimary,
      backgroundColor: theme.palette.button.tertiary,
      border: `2px solid ${theme.palette.button.tertiary}`,
      borderRadius: '4px',
      color: theme.palette.button.tertiaryText,
    },

    '& .frc-content': {
      flexGrow: 'unset',
      '& button:after': {
        left: '0',
        marginLeft: theme.spacing(6),
        width: '0%',
        bottom: '5px',
        height: '2px',
        content: '""',
        position: 'absolute',
        transition: 'width 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        backgroundColor: '#000000',
      },
      '&:hover button:after': {
        width: '67%',
      },
      '&:hover button': {
        backgroundColor: theme.palette.button.tertiary,
      },
      '&:not(.disabled):hover button:after': {
        width: '67%',
      },

      '&.disabled': {
        backgroundColor: theme.palette.button.disabled,
        color: theme.palette.button.disabledText,
        borderColor: theme.palette.button.disabled,
        pointerEvents: 'none',
      },
      '&.disabled button': {
        borderColor: theme.palette.button.disabled,
        color: theme.palette.button.disabledText,
      },
    },
  },
  captchanote: {
    marginBottom: theme.spacing(2),
  },
  error: {
    marginTop: theme.spacing(3),
  },
}))

export type FriendlyCaptchaProps = RvG.IReactDefaultProps & {
  name: string
}

export default function FriendlyCaptcha({
  name,
}: FriendlyCaptchaProps): ReactElement {
  const classes = useStyles()
  const [field, meta, helper] = useField(name)
  const intl = useIntl()
  const container = useRef()
  const widget = useRef()

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        puzzleEndpoint: 'https://eu-api.friendlycaptcha.eu/api/v1/puzzle',
        language: intl.locale,
        doneCallback: doneCallback,
        sitekey: 'FCMUHQI5LPPHKHJR',
      })
    }

    return () => {
      if (widget.current != undefined) widget.current.reset()
    }
  }, [container])

  function doneCallback(solution: string | null) {
    helper.setValue(solution)
  }

  return (
    <div
      data-testid="friendlycaptcha"
      data-usercentrics="Friendly Captcha"
      className={classes.recaptchaRoot}
    >
      <div
        className="frc-captcha"
        ref={container}
        id={`friendly-captcha-${name}`}
      />
      {meta.touched && meta.error && (
        <FormHelperText className={classes.error} type="error">
          {meta.error}
        </FormHelperText>
      )}
    </div>
  )
}

import React, { ReactElement } from 'react'

import ScheduleOfFines from './types/scheduleoffines'
import NotFoundCopy from './types/notFoundCopy'
import ContactForm from './types/contactform'
import ReportBarrierForm from './types/reportbarrierform'

export type ItemTypes = {
  filter?: string
  cells?: Array<{ label: string }>
  label?: string
  copy?: string
}

export type ContentTypes = {
  Overspeeding: ItemTypes[]
  Distance: ItemTypes[]
  NotFoundInfo?: ItemTypes[]
}

export type FixedModuleProps = {
  type: string
  content: ContentTypes[]
  active?: string
  cta?: ReactElement | null
}

export default function FixedModule({
  type,
  content,
  active,
  cta,
}: FixedModuleProps): ReactElement {
  switch (type) {
    case 'bußgeldkatalog':
      return <ScheduleOfFines {...content} active={active} cta={cta} />
    case 'Kontaktformular':
      return <ContactForm />
    case 'barrieremeldenformular':
      return <ReportBarrierForm />
    case '404':
      return <NotFoundCopy {...content} />
    default:
      return <></>
  }
}

import React, { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@objects/button'

const useStyles = makeStyles((theme) => ({
  button: {
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    '& + &': {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(0),
      },
    },
  },
}))

export type DownloadBarProps = {
  img?: RvG.Contentful.IFile
  pdf?: RvG.Contentful.IFile
}

export default function DownloadBar(props: DownloadBarProps): ReactElement {
  const { img, pdf } = props
  const classes = useStyles()
  const regex = /[0-9a-z]+$/i

  return (
    <div>
      {img && (
        <Button type="download" className={classes.button} to={img.url}>
          <FormattedMessage
            id={
              img?.url?.toUpperCase().match(regex)?.[0] === 'ZIP'
                ? 'download.archive'
                : 'download.image'
            }
          />
        </Button>
      )}
      {pdf && (
        <Button type="download" className={classes.button} to={pdf.url}>
          <FormattedMessage id="download.pdf" />
        </Button>
      )}
    </div>
  )
}

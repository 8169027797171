import React, { ReactElement } from 'react'
import {
  default as ReactSelect,
  GroupTypeBase,
  StylesConfig,
  ValueType,
  Props as ReactSelectProps,
} from 'react-select'
import { makeStyles } from '@material-ui/core/styles'
import theme from '@config/theme'
import clsx from 'clsx'
import { useField } from 'formik'
import FormHelperText from '@objects/formfields/helperText'

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}))

const getSelectStyle = (settingsTheme?: string | undefined, error: boolean) => {
  const dark = () => {
    return settingsTheme && settingsTheme === 'dark'
  }

  const selectStyle: StylesConfig<
    Record<string, unknown>,
    true,
    GroupTypeBase<Record<string, unknown>>
  > = {
    menu: (styles) => {
      return {
        ...styles,
        backgroundColor: dark()
          ? theme().palette.background.greyDark
          : theme().palette.background.light,
        borderRadius: '0 0 4px 4px',
        overflow: 'hidden',
        menuTop: 'hidden',
        marginTop: 0,
        cursor: 'pointer',
        zIndex: 2,
      }
    },
    container: (styles: any) => ({
      ...styles,
      fontFamily: theme().typography.fontFamily2,
      borderBottom: `1px solid ${
        error ? theme().palette.error.dark : theme().palette.text.disabled
      }`,
    }),
    option: (styles: any, state: any) => {
      let color = ''
      if (state.isFocused || state.isSelected) {
        color = dark() ? theme().palette.text.primary : 'black'
      } else {
        color = dark() ? theme().palette.text.invert : 'black'
      }

      return {
        ...styles,
        position: 'relative',
        color,
        backgroundColor:
          state.isFocused || state.isSelected
            ? theme().palette.background.default
            : theme().palette.background.light,
        cursor: 'pointer',
        ':active': {
          backgroundColor: state.isSelected
            ? theme().palette.background.accentLight
            : 'none',
        },
      }
    },
    input: (styles: any) => ({
      ...styles,
      borderRadius: '4px',
      marginLeft: '15px',
    }),
    control: (styles, state) => ({
      ...styles,
      backgroundColor:
        settingsTheme === 'dark'
          ? theme().palette.background.greyDark
          : theme().palette.background.light,
      borderRadius: state.isFocused ? '4px 4px 0 0' : '4px',
      fontSize: '18px',
      minHeight: '46px',
      color: state.isFocused
        ? dark()
          ? theme().palette.text.invert
          : theme().palette.text.invert
        : dark()
        ? theme().palette.text.invert
        : theme().palette.text.primary,
      border: 'none',
      boxShadow: 'none',
      paddingLeft: theme().spacing(0),
      cursor: 'pointer',
      ':hover': {
        border: 'none',
        boxShadow: 'none',
      },
    }),
    valueContainer: (base) => ({
      ...base,
      paddingLeft: 0,
    }),
    singleValue: (base) => ({
      ...base,
      color: dark()
        ? theme().palette.text.invert
        : theme().palette.text.primary,
    }),
    placeholder: (base: any) => ({
      ...base,
      fontSize: '16px',
      fontFamily: theme().typography.fontFamily,
      fontWeight: 'regular',
      color: dark()
        ? theme().palette.text.invert
        : theme().palette.text.disabled,
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: dark()
        ? theme().palette.text.invert
        : theme().palette.text.primary,
      ':focus': {
        Svg: {
          color: 'red',
        },
      },
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
    indicatorsContainer: (styles) => ({
      ...styles,
      marginRight: theme().spacing(2),
    }),
  }

  return selectStyle
}

export type SelectInlineProps = RvG.IReactDefaultProps & {
  settings: ReactSelectProps
  name: string
  id?: string
}

export default function SelectInline({
  className,
  settings,
  name,
  id,
}: SelectInlineProps): ReactElement {
  const classes = useStyles()
  const [field, meta, { setValue }] = useField(name)
  const selectStyle = getSelectStyle(
    settings?.settingsTheme,
    meta.touched && !!meta.error
  )

  const onChange = ({ value }: { value: ValueType<OptionType, IsMulti> }) => {
    setValue(value)
  }
  //@TODO: formik + react select typing not playing well together...
  return (
    <>
      <ReactSelect
        id={id}
        className={clsx(classes.root, className)}
        data-testid={'select'}
        {...settings}
        styles={selectStyle}
        value={
          settings.options
            ? settings.options.find((option) => option.value === field.value)
            : ''
        }
        blurInputOnSelect
        isSearchable={false}
        onChange={onChange}
      />
      {meta.touched && !!meta.error && meta.error.length > 1 && (
        <FormHelperText type="error">{meta.error}</FormHelperText>
      )}
    </>
  )
}

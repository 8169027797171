import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'

import Copy from '@components/copy'
import Headline from '@objects/headline'
import Icon from '@objects/icon'
import { InfoBoxProps } from '..'
import useGlobalText from '@hooks/useGlobalText'

import FontSize from '@config/theme/definitions/fontSize'

const useStyles = makeStyles((theme) => ({
  boxRoot: {
    position: 'relative',
    padding: theme.spacing(8, 8, 8, 4),
    margin: theme.spacing(15, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(13, 15),
      margin: theme.spacing(20, 0),
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '20%',
    height: '100%',
    backgroundColor: theme.palette.background.light,
    zIndex: -1,
    transition: `width 1s ${theme.transitions.easing.easeInOut}`,
  },
  headline: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 8, 6, 0) + '!important',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 20, 8, 0) + '!important',
    },
  },
  relatedLinksHeadline: {
    fontSize: FontSize.lg,
    margin: 0,
  },
  copy: {
    '& p': {
      margin: theme.spacing(2, 0, 0, 0),
    },
  },
  reference: {},
  icon: {
    backgroundColor: theme.palette.background.greyDark,
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(8),
    },
  },
  inView: {
    width: '100%',
  },
  haxMaxWidth: {
    maxWidth: '702px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(16),
  },
}))

export default function ImportantInfoBox({
  className,
  headline,
  copy,
  relatedLinks,
  hasMaxWidth,
}: InfoBoxProps): ReactElement {
  const classes = useStyles()
  const { getText } = useGlobalText()
  const relatedLinksHeadline = getText('infoBoxReferences')
  const { ref, inView } = useInView({
    triggerOnce: true,
  })
  return (
    <div
      ref={ref}
      className={clsx(className, classes.boxRoot, {
        [classes.haxMaxWidth]: hasMaxWidth,
      })}
    >
      <div
        className={clsx(classes.background, {
          [classes.inView]: inView,
        })}
      />
      {headline && (
        <Headline level={4} className={classes.headline}>
          <Icon className={classes.icon} name={'Alert'} />
          {headline}
        </Headline>
      )}
      {copy && typeof copy === 'object' && <Copy richtext={copy} />}
      {copy && typeof copy === 'string' && copy}
      {relatedLinks && (
        <div className={classes.reference}>
          <div className={classes.relatedLinksHeadline}>
            {relatedLinksHeadline}
          </div>
          <Copy className={classes.copy} richtext={relatedLinks} />
        </div>
      )}
    </div>
  )
}

import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import useGlobalText from '@hooks/useGlobalText'

import Icon from '@objects/icon'
import Container from '@objects/container'
import Headline from '@objects/headline'
import SearchInput from '@objects/searchinput'
import useToggleComp from '@hooks/useToggleComp'
import { Index } from 'elasticlunr'

const useStyles = makeStyles((theme) => ({
  searchOverlayRoot: {
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(25),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(38),
    },
  },
  searchOverlayWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  searchOverlayContainer: {
    width: '100%',
  },
  searchOverlayContentRoot: {
    backgroundColor: theme.palette.background.light,
    flex: 1,
    paddingTop: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(20),
    },
  },
  headline: {
    marginBottom: theme.spacing(12),
  },
  item: {
    position: 'relative',
    paddingLeft: theme.spacing(8),
  },
  itemIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: theme.palette.primary.main,
  },
}))

export type SearchTypeProps = {
  index: Index<RvG.SearchResult>
}

function SearchType({ index }: SearchTypeProps): React.ReactElement {
  const intl = useIntl()
  const classes = useStyles()
  const { toggleOverlay, OverlayIsActive } = useToggleComp()
  const { getText } = useGlobalText()
  const [resultsAll, setResultsAll] = useState<RvG.SearchResult[]>()

  function search(searchquery: string) {
    setResultsAll(
      index
        .search(searchquery, { expand: true })
        .map(({ ref }) => index.documentStore.getDoc(ref))
        .filter((result) => result.locale === intl.locale)
    )
  }

  function Item({
    iconName,
    headline,
    copy,
  }: {
    iconName: string
    headline: string
    copy: string
  }) {
    return (
      <div className={classes.item}>
        <Icon name={iconName} className={classes.itemIcon} />
        <Headline level={5}>{headline}</Headline>
        {copy}
      </div>
    )
  }

  return (
    <div className={classes.searchOverlayRoot}>
      <div className={classes.searchOverlayWrapper}>
        <Container width="md" className={classes.searchOverlayContainer}>
          <SearchInput
            searchfunc={search}
            resultsAll={resultsAll}
            onSuggestClick={() => toggleOverlay(false)}
            onKeyEnter={() => toggleOverlay(false)}
            isShown={OverlayIsActive}
          />
        </Container>
        <div className={classes.searchOverlayContentRoot}>
          <Container width="md" className={classes.searchOverlayContainer}>
            <Headline level={3} className={classes.headline}>
              {getText('searchOverlayHeadline')}
            </Headline>
            <Grid container spacing={8} justifyContent="center" role="group">
              <Grid item xs={12} md={4} role="menuitem">
                <Item
                  iconName={getText('searchOverlayItem1Icon')}
                  headline={getText('searchOverlayItem1Headline')}
                  copy={getText('searchOverlayItem1Copy')}
                />
              </Grid>
              <Grid item xs={12} md={4} role="menuitem">
                <Item
                  iconName={getText('searchOverlayItem2Icon')}
                  headline={getText('searchOverlayItem2Headline')}
                  copy={getText('searchOverlayItem2Copy')}
                />
              </Grid>
              <Grid item xs={12} md={4} role="menuitem">
                <Item
                  iconName={getText('searchOverlayItem3Icon')}
                  headline={getText('searchOverlayItem3Headline')}
                  copy={getText('searchOverlayItem3Copy')}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  )
}

SearchType.propTypes = {
  index: PropTypes.object.isRequired,
}

export default SearchType

const axios = require('axios')

export default async function sendMessage(
  values: Record<string, unknown>
): Promise<any> {
  const response = await axios.post(
    process.env.GATSBY_CONTACT_SERVICE_URL + '/api/contact',
    values
  )
  return response
}

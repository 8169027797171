import React, { ReactElement } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import ContentfulComponentArray from '@tools/ContentfulComponentArray'

const useStyles = makeStyles((theme) => ({
  copyRoot: {},
  article: {
    ...theme.mixins.article,
  },
  accordion: {
    ...theme.mixins.accordion,
  },
  teaser: {
    ...theme.typography.teaser,
  },
  button: {},
  simple: {},
  default: {},
}))

export type CopyProps = {
  className?: string
  id?: string
  type?: 'default' | 'simple' | 'button' | 'teaser' | 'article'
  theme?: 'dark' | 'light'
  ariaRole?: string
  richtext:
    | RvG.Contentful.BasicRichTextType
    | RvG.Contentful.ArticleRichTextType
}

export default function Copy({
  className,
  richtext,
  type = 'default',
  theme,
  id,
  ariaRole,
}: CopyProps): ReactElement {
  const classes = useStyles()

  if (type === 'simple') {
    return <ContentfulComponentArray componentarray={richtext} simple />
  }

  if (type === 'button') {
    return (
      <div id={id} className={clsx(className, classes.copyRoot)} role="button">
        <ContentfulComponentArray
          componentarray={richtext}
          button
          theme={theme}
        />
      </div>
    )
  }

  return (
    <div
      id={id}
      className={clsx(className, classes.copyRoot, {
        [classes[type]]: type !== 'default',
      })}
      role={ariaRole}
    >
      <ContentfulComponentArray componentarray={richtext} />
    </div>
  )
}

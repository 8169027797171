import React, { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import FontSize from '@config/theme/definitions/fontSize'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@objects/container'
import Button from '@objects/button'
import Headline from '@objects/headline'
import { Theme } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import Icon from '@objects/icon'

export type NotFoundCopyProps = RvG.IReactDefaultProps & {
  headline?: string
  label?: string
  to?: string
}

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    pageRoot: {
      marginTop: theme.spacing(15),
    },
    contentRoot: {
      textAlign: 'center',
      paddingTop: theme.spacing(10),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: theme.spacing(0),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(21),
        marginBottom: theme.spacing(0),
      },
    },
    copy: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(12.5),
    },
    headerIcon: {
      color: theme.palette.primary.main,
      textAlign: 'center',
      marginBottom: theme.spacing(8),
      '& svg': {
        height: 'auto',
        width: '123px',
      },
    },
    underlinedBtn: {
      borderRadius: 0,
      padding: theme.spacing(0, 0, 1),
      fontSize: FontSize['base'],
      '& svg': {
        height: FontSize['3lg'],
        width: 'auto',
      },
    },
    centeredBtn: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
)

export default function NotFoundCopy({
  ...props
}: NotFoundCopyProps): ReactElement {
  const { NotFoundInfo } = props
  const classes = useStyles()

  return (
    <>
      <Container className={classes.contentRoot} type="section">
        <div className={classes.headerIcon}>
          <Icon name={'NotFound'} viewBox={'0 0 129 86'} />
        </div>
        <Headline level={1} className={classes.headline}>
          <FormattedMessage
            id="fontFound.headline"
            defaultMessage={
              NotFoundInfo.find(({ label }) => label === 'headline').copy
            }
            values={{ break: <br /> }}
          />
        </Headline>
        <Button
          className={clsx(classes.underlinedBtn, classes.centeredBtn)}
          type={'underlined'}
          to={NotFoundInfo.find(({ label }) => label === 'buttonHomeUrl').copy}
        >
          <FormattedMessage
            id={
              NotFoundInfo.find(({ label }) => label === 'buttonHomeLabel').copy
            }
          />
        </Button>

        <Button
          className={clsx(classes.underlinedBtn, classes.centeredBtn)}
          type={'underlined'}
          to={
            NotFoundInfo.find(({ label }) => label === 'buttonSearchUrl').copy
          }
        >
          <FormattedMessage
            id={
              NotFoundInfo.find(({ label }) => label === 'buttonSearchLabel')
                .copy
            }
          />
        </Button>
      </Container>
    </>
  )
}

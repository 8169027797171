import React, {
  useState,
  useRef,
  useEffect,
  ReactElement,
  ChangeEvent,
} from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import clsx from 'clsx'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

import Copy from '@components/copy'
import Icon from '@objects/icon'
import Headline from '@objects/headline'

import FontSize from '@config/theme/definitions/fontSize'
import Palette from '@config/theme/palette'

export type AccordionProps = {
  className?: string
  id?: string
  items?: IAccordionItem[]
  headline?: string
}

export interface IAccordionItem {
  id: string
  headline: string
  copy: RvG.Contentful.BasicRichTextType
}

const useStyles = makeStyles((theme) => ({
  headline: {
    marginBottom: theme.spacing(8),
  },
}))

const Accordion = withStyles(() => ({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion)

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: Palette.background?.light,
    marginTop: theme.spacing(4),
    minHeight: '70px',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0, 4),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 8),
      '&:hover': {
        backgroundColor: Palette.background?.grey,
        '& .MuiAccordionSummary-content': {
          textDecoration: `underline solid ${Palette.background?.greyDark} 1px`,
        },
      },
    },
    '&$expanded': {
      minHeight: '70px',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  content: {
    fontSize: FontSize.base,
    fontWeight: 'bold',
    '&$expanded': {
      margin: theme.spacing(3, 0),
    },
  },
  expanded: {},
}))(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: Palette.background?.light,
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    padding: theme.spacing(2, 4, 0, 4),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2, 8, 0, 8),
    },
  },
}))(MuiAccordionDetails)

export default function CustomAccordion({
  className,
  id,
  items,
  headline,
}: AccordionProps): ReactElement {
  const classes = useStyles()
  const location = useLocation()
  const compRef = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState<string | string[]>('')

  // open accordion from url hash
  useEffect(() => {
    const query = queryString.parse(location.hash)[`item`]
    if (query) {
      const el = compRef.current?.querySelector(`#${query}`)
      if (el) {
        setExpanded(query)
        setTimeout(() => {
          window.scrollTo({
            top: el.getBoundingClientRect().y - 150,
          })
        }, 200)
      }
    }
  }, [])

  function onItemChange(panelId: string, isExpanded: boolean) {
    let curPath = location.pathname
    const query = queryString.parse(location.hash)
    if (curPath && panelId.length) {
      if (isExpanded) {
        query[`item`] = panelId
      } else {
        delete query[`item`]
      }
      curPath += `#${queryString.stringify(query)}`
      navigate(curPath, {
        replace: true,
        state: {
          preventScroll: true,
        },
      })
    }
  }

  function handleChange(
    event: ChangeEvent<unknown>,
    isExpanded: boolean,
    panelId: string
  ) {
    onItemChange(panelId, isExpanded)
    setExpanded(isExpanded ? panelId : '')
  }

  return (
    <div ref={compRef} id={id} className={clsx(className)}>
      {headline && (
        <Headline className={classes.headline} level={2}>
          {headline}
        </Headline>
      )}

      {items?.map((item: IAccordionItem, i: number) => {
        return (
          <Accordion
            expanded={expanded === item.id}
            onChange={(event, expanded) => {
              handleChange(event, expanded, item.id)
            }}
            data-accordion-component={'AccordionItem'}
            key={i}
            data-testid="faqitem"
          >
            <AccordionSummary
              aria-controls={`${item.id}-content`}
              id={`${item.id}`}
              expandIcon={
                !!item.copy ? <Icon name="ChevronDown" color="inherit" /> : ''
              }
            >
              {item.headline}
            </AccordionSummary>

            {!!item.copy ? (
              <AccordionDetails>
                {item.copy && <Copy richtext={item.copy} />}
              </AccordionDetails>
            ) : (
              ''
            )}
          </Accordion>
        )
      })}
    </div>
  )
}

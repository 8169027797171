import React, { ReactElement, ReactNode } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  root: {},
  underlined: {
    ...theme.mixins.linkStyleUnderlined,
  },
}))

export type LinkProps = {
  children?: ReactNode
  className?: string
  to: string
  isExternal?: boolean | false
  underlined?: boolean | false
  hideIcon?: boolean | false
  onClick?: (event: any) => void
}

export default function Link({
  className,
  children,
  to,
  isExternal,
  hideIcon,
  underlined,
  ...props
}: LinkProps): ReactElement {
  const classes = useStyles()

  return isExternal ? (
    <a
      className={clsx(className, classes.root, {
        [classes.underlined]: underlined,
      })}
      href={to}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      {children}
      {!hideIcon && <Icon name="OpenInNew" size="inherit" />}
    </a>
  ) : (
    <GatsbyLink
      className={clsx(className, classes.root, {
        [classes.underlined]: underlined,
      })}
      to={to}
      {...props}
    >
      {children}
      {!hideIcon && <Icon name="ChevronRight" size="inherit" />}
    </GatsbyLink>
  )
}

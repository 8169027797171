import { useContext } from 'react'

import {
  BasketComponentContext,
  IBasketContext,
} from '@providers/basketProvider'

export default function useBasket(): IBasketContext {
  const {
    addItem,
    removeItem,
    clearBasket,
    setDeleted,
    basket,
    pathToCart,
    pathToCheckout,
    pathToShop,
  } = useContext(BasketComponentContext)

  return {
    addItem,
    clearBasket,
    basket,
    pathToCart,
    pathToCheckout,
    pathToShop,
    removeItem,
    setDeleted,
  }
}

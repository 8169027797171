import React, { ReactElement } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'

import NavItem from './drawerNavItem'
import Icon from '@objects/icon'
import Search from '@components/search'

export interface INavItemData {
  title: string
  path: string
  icon?: string
  nextlevel?: INavItemData[]
  teaserCopy?: RvG.Contentful.BasicRichTextType
}

export interface IDrawerNav extends RvG.IReactDefaultProps {
  items: INavItemData[]
  openState: boolean
  toggleDrawer: (open: boolean) => void
  searchIndex: RvG.PageHelpers.SearchIndex
}

const useStyles = makeStyles((theme) => ({
  listLevel1: {
    lineHeight: 1,
  },
  listLevel2: {
    paddingTop: theme.spacing(2),
    background: theme.palette.background.light,
    flex: 1,
  },
  listLevel3: {
    paddingTop: theme.spacing(2),
    background: theme.palette.background.light,
    flex: 1,
  },
  drawer: {
    width: '100vw',
    height: '100%',
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '40vw',
    },
  },
  action: {
    border: 0,
    outline: 0,
    background: 0,
    padding: theme.spacing(2),
  },
  meta: {
    flex: 1,
    margin: theme.spacing(12, 12, 7, 12),
  },
  actions: {
    alignSelf: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
  },
}))

export default function DrawerNav({
  children,
  className,
  items,
  openState,
  toggleDrawer,
  searchIndex,
}: IDrawerNav): ReactElement {
  const classes = useStyles()

  function renderNavList(items: INavItemData[], level: number) {
    return (
      <>
        <List
          component="div"
          className={classes[`listLevel${level + 1}`]}
          disablePadding
          role="menubar"
        >
          {items.map((item) => {
            return (
              <NavItem
                level={level}
                key={item.path}
                to={item.path}
                title={item.title}
                drawerClass={classes.drawer}
                onClick={toggleDrawer}
              >
                {item.nextlevel &&
                  item.nextlevel.length > 0 &&
                  renderNavList(item.nextlevel, level + 1)}
              </NavItem>
            )
          })}
        </List>
      </>
    )
  }

  return (
    <SwipeableDrawer
      data-testid="drawer"
      className={className}
      anchor={'right'}
      open={openState}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      PaperProps={{
        classes: {
          root: classes.drawer,
        },
      }}
    >
      <div className={classes.actions}>
        <Search
          className={classes.action}
          searchIndex={searchIndex}
          onClick={() => toggleDrawer(false)}
        />
        <button className={classes.action} onClick={() => toggleDrawer(false)}>
          <Icon name="Close" />
        </button>
      </div>
      {renderNavList(items, 0)}
      <div className={classes.meta}>{children}</div>
    </SwipeableDrawer>
  )
}

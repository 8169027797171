import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'

import Container from '@objects/container'
import Headline from '@objects/headline'
import Copy from '@components/copy'
import Image from '@objects/image'

import { BigTeaserProps } from '../'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(10, 0),
  },
  container: {
    position: 'relative',
  },
  imageWrapper: {
    width: '100%',
    '.isTrailing &': {
      textAlign: 'right',
    },
  },
  image: {
    display: 'inline-block',
    width: '100%',
    margin: 0,
    '.isTrailing &': {
      margin: '-20% 0 0 auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: '55%',
    },
  },
  contentWrapper: {
    width: '85%',
    position: 'relative',
    margin: '-10% 0 0 auto',
    zIndex: 1,
    '.isTrailing &': {
      margin: '0',
    },
    [theme.breakpoints.up('lg')]: {
      width: '55%',
      margin: '-20% 0 0 auto',
      transform: 'translateY(10%)',
      transition: `transform 0.3s ${theme.transitions.easing.easeInOut}`,
      '.isTrailing &': {
        margin: '0% 0 0 0',
      },
    },
  },
  contentInner: {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(4, 4, 4, 15),
    '.isTrailing &': {
      padding: theme.spacing(4, 15, 4, 4),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(15, 23),
      borderRadius: theme.spacing(0, 2, 2, 0),
      '.isTrailing &': {
        padding: theme.spacing(15, 23),
        borderRadius: theme.spacing(2, 0, 0, 2),
      },
    },
  },
  copy: {
    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
  cta: {
    '& a': {
      ...theme.mixins.linkStyleUnderlined,
      fontWeight: '500',
    },
    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
  headlineAnim: {
    opacity: 0,
    transform: 'translateY(10%)',
    transition: `opacity 1s ${theme.transitions.easing.easeInOut} 0s, transform 1s ${theme.transitions.easing.easeInOut} 0s`,
  },
  copyAnim: {
    opacity: 0,
    transform: 'translateY(10%)',
    transition: `opacity 1s ${theme.transitions.easing.easeInOut} 0.2s, transform 1s ${theme.transitions.easing.easeInOut} 0.2s`,
  },
  ctaAnim: {
    opacity: 0,
    transform: 'translateY(10%)',
    transition: `opacity 1s ${theme.transitions.easing.easeInOut} 0.4s, transform 1s ${theme.transitions.easing.easeInOut} 0.4s`,
  },
  inView: {
    transform: 'translateY(0%)',
    '& $copyAnim, & $headlineAnim, & $ctaAnim': {
      transform: 'translateY(0%)',
      opacity: 1,
    },
  },
  contentSiding: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    width: '50px',
    top: 0,
    left: theme.spacing(-6),
    transform: 'skew(4.8deg)',
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.light,

    '.isTrailing &': {
      left: 'auto',
      right: theme.spacing(-6),
      transform: 'skew(-4.8deg)',
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
    },
  },
}))

type TeaserImageProps = {
  image: RvG.Contentful.IAsset
  focalPoint?: RvG.Contentful.imageFocalPoint
}

type TeaserContentProps = {
  headline: string
  copy: RvG.Contentful.BasicRichTextType
  cta: RvG.Contentful.BasicRichTextType
}

function TeaserImage({ image, focalPoint }: TeaserImageProps) {
  const classes = useStyles()
  const intl = useIntl()
  return (
    <div className={clsx(classes.imageWrapper)}>
      <Image
        className={classes.image}
        image={image.fluid}
        focalPoint={focalPoint}
        imgStyle={{ margin: '0' }}
        ariaLabel={intl.formatMessage({ id: 'teaser.image.aria.label' })}
      />
    </div>
  )
}

function TeaserContent({ headline, copy, cta }: TeaserContentProps) {
  const classes = useStyles()
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  return (
    <div
      ref={ref}
      className={clsx(classes.contentWrapper, {
        [classes.inView]: inView,
      })}
    >
      <div className={clsx(classes.contentInner)}>
        <Headline className={classes.headlineAnim}>{headline}</Headline>
        <Copy
          className={clsx(classes.copy, classes.copyAnim)}
          richtext={copy}
          ariaRole="paragraph"
        />
        <Copy className={clsx(classes.cta, classes.ctaAnim)} richtext={cta} />
      </div>
      <div className={clsx(classes.contentSiding)} />
    </div>
  )
}

export default function BigTeaserDefault({
  layout,
  headline,
  copy,
  cta,
  imageFocalPoint,
  image,
}: BigTeaserProps): ReactElement {
  const classes = useStyles()
  const isTrailing = layout === 'Trailing'
  const intl = useIntl()

  return (
    <section
      className={clsx(classes.root, { isTrailing })}
      role="region"
      aria-label={intl.formatMessage({ id: 'teaser.aria.label' })}
    >
      <Container className={classes.container} type="nomargin">
        {isTrailing ? (
          <>
            <TeaserContent headline={headline} copy={copy} cta={cta} />
            <TeaserImage image={image} focalPoint={imageFocalPoint} />
          </>
        ) : (
          <>
            <TeaserImage image={image} focalPoint={imageFocalPoint} />
            <TeaserContent headline={headline} copy={copy} cta={cta} />
          </>
        )}
      </Container>
    </section>
  )
}

import React, { useState, useEffect, ReactElement } from 'react'
import { Index } from 'elasticlunr'
import clsx from 'clsx'
import useGlobalText from '@hooks/useGlobalText'

import { makeStyles } from '@material-ui/core/styles'

import useToggleComp from '@hooks/useToggleComp'
import Icon from '@objects/icon'

export interface ISearch {
  className: string
  searchIndex: RvG.PageHelpers.SearchIndex
  onClick?: () => void
}

const useStyles = makeStyles((theme) => ({
  search: {
    display: 'inline-flex',
    outline: 0,
    border: 0,
    background: 'none',
    padding: 0,
    whiteSpace: 'nowrap',
    '&:focus': {
      outline: '1px dotted #000',
    },
  },
  icon: {
    marginTop: '1px', // optical height
  },
  text: {
    ...theme.typography.navigation,
    display: 'none',
    [theme.breakpoints.up('xl')]: {
      display: 'inline-block',
      marginLeft: theme.spacing(2),
    },
  },
}))

export default function Search({
  className,
  searchIndex,
  onClick,
}: ISearch): ReactElement {
  const { toggleOverlay } = useToggleComp()
  const classes = useStyles()
  const { getText } = useGlobalText()

  const [index, setIndex] = useState<Index<RvG.SearchResult>>(
    null as unknown as Index<RvG.SearchResult>
  )

  function showOverlay(show: boolean) {
    onClick && onClick()
    toggleOverlay(show, {
      type: 'search',
      trackingID: 'suche',
      className: '',
      index: index,
      dialogType: 'fullscreen',
    })
  }

  useEffect(() => {
    if (!index && searchIndex) setIndex(Index.load(searchIndex))
  }, [])

  return (
    <button
      className={clsx(className, classes.search)}
      onClick={() => showOverlay(true)}
    >
      <Icon name="Search" className={classes.icon} />
      <span className={classes.text}>{getText('searchText')}</span>
    </button>
  )
}

import React, { useEffect, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import Icon from '@objects/icon'
import Carousel from '@objects/carousel'
import Button from '@objects/button'
import Copy from '@components/copy'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Image from '@objects/image'
import useSize from '@hooks/useSize'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import MaterialShopFormProductDetails from '../../../../components/materialShop/materialShopFormProductDetails'

export interface IMaterialShopOverlayProps {
  id: string
  item: RvG.Contentful.IContentfulModuleMaterialShopItem
  handleClose?: () => void
}

export interface IMaterialShopSelectOpt {
  value: string | number
  label: string | number
}

const useStyles = makeStyles((theme) => ({
  popupWrapper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 12px)',
    maxWidth: theme.container.lg,
    overflow: 'scroll',
    // maxHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: theme.spacing(12, 4),
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100vh',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
      width: '100%',
      flexDirection: 'row',
    },
  },
  infoWrapper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      paddingLeft: theme.spacing(8),
    },

    display: 'flex',
    flexDirection: 'column',
  },
  infoInner: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  infoHeadline: {
    ...theme.typography.h4,
    fontWeight: 700,
    marginBottom: '24px',
  },
  infoDescription: {
    '& > p:last-child': {
      marginBottom: 0,
    },
  },
  close: {
    cursor: 'pointer',
    marginBottom: 'auto',
    textAlign: 'right',
    fontSize: '32px',
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(6.5),
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  galleryWrapper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: 0,
      width: '50%',
    },
  },
  galleryInner: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    zIndex: theme.zIndex.modal + 1,
    padding: theme.spacing(0, 4),
    background: theme.palette.background.greyDark,
    '& .swiper-wrapper': {
      alignItems: 'center',
    },
  },
  galleryItem: {
    height: '100%',
    maxHeight: '80vh',
    width: '90%',
    margin: 'auto',
  },
  navBtn: {
    position: 'absolute !important' as 'absolute',
    top: '50%',
    zIndex: 1,
    transform: 'translateY(-50%)',
    width: '28px',
    height: '71px',
  },
  navBtnPrev: {
    left: theme.spacing(0),
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
  },
  navBtnNext: {
    right: theme.spacing(0),
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
}))

export default function MaterialShopOverlay(
  props: IMaterialShopOverlayProps
): React.ReactElement {
  const { id, item, handleClose } = props
  const { title, description, images } = item
  const classes = useStyles()
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const size = useSize(wrapperRef)
  const [styles, setStyles] = useState<CSSProperties>()
  const [swiper, setSwiper] = useState<any>({})
  const muiTheme = useTheme()
  const isMedium = useMediaQuery(muiTheme.breakpoints.up('md'))

  useEffect(() => {
    if (!size) {
      return
    }
    const screenHeight = window.innerHeight
    const { height } = size

    if (height < screenHeight) {
      return
    }

    setStyles({
      top: '0',
      transform: 'translate(-50%, 0)',
      height: screenHeight,
    })
  }, [size])

  const renderImages = ({ images }: { images: RvG.Contentful.IAsset[] }) => {
    return images?.map((thumbnail: RvG.Contentful.IAsset) => {
      return (
        <div key={`DLThumb-${thumbnail.id}`}>
          <Image className={classes.galleryItem} image={thumbnail.fluid} />
        </div>
      )
    })
  }

  return (
    <div className={classes.popupWrapper} ref={wrapperRef} style={styles}>
      <div
        className={clsx(classes.close, { [classes.hide]: isMedium })}
        onClick={handleClose}
      >
        <Icon size={'inherit'} name={'Close'} />
      </div>
      <div className={classes.galleryWrapper}>
        <div className={classes.galleryInner}>
          <Carousel onSwiper={setSwiper} speed={600} loop={true}>
            {renderImages({ images })}
          </Carousel>
          {images?.length > 1 && (
            <>
              <Button
                type="icon"
                icon="ChevronLeft"
                className={clsx(classes.navBtn, classes.navBtnPrev)}
                onClick={() => swiper.slidePrev()}
              />
              <Button
                type="icon"
                icon="ChevronRight"
                className={clsx(classes.navBtn, classes.navBtnNext)}
                onClick={() => swiper.slideNext()}
              />
            </>
          )}
        </div>
      </div>
      <div className={classes.infoWrapper}>
        <div className={classes.infoInner}>
          <div
            className={clsx(classes.close, {
              [classes.hide]: !isMedium,
            })}
            onClick={handleClose}
          >
            <Icon size={'inherit'} name={'Close'} />
          </div>
          <h3 className={classes.infoHeadline}>{title}</h3>
          {description && (
            <Copy className={classes.infoDescription} richtext={description} />
          )}
          <MaterialShopFormProductDetails
            id={id}
            item={item}
            onSubmit={() => handleClose && handleClose()}
          />
        </div>
      </div>
    </div>
  )
}

import React, { ReactElement, useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import {
  default as ReactSelect,
  GroupTypeBase,
  StylesConfig,
  Props as ReactSelectProps,
  OptionProps,
} from 'react-select'
import { makeStyles } from '@material-ui/core/styles'
import theme from '@config/theme'
import clsx from 'clsx'

const useStyles = makeStyles(() => {
  const dark = (settingsTheme: string) => {
    return settingsTheme === 'dark'
  }

  return {
    root: {
      cursor: 'pointer',
      '& .select__option': {
        position: 'relative',
        backgroundColor: 'none',
        color: ({ settingsTheme }: { settingsTheme: string }) =>
          dark(settingsTheme)
            ? theme().palette.text.invert
            : theme().palette.text.secondary,
        cursor: 'pointer',
        ':active': {
          backgroundColor: ({ settingsTheme }: { settingsTheme: string }) =>
            dark(settingsTheme)
              ? theme().palette.background.accentLight
              : 'none',
        },
        padding: theme().spacing(2, 5.5),
        '&.is-visible': {
          opacity: 1,
          transition: `opacity 1s ${theme().transitions.easing.easeOut}`,
        },
        '&:hover': {
          color: ({ settingsTheme }: { settingsTheme: string }) =>
            dark(settingsTheme)
              ? theme().palette.text.primary
              : theme().palette.text.secondary,
          backgroundColor: ({ settingsTheme }: { settingsTheme: string }) =>
            dark(settingsTheme) ? theme().palette.background.accent : 'none',
        },
        '&.is-focussed': {
          color: ({ settingsTheme }: { settingsTheme: string }) =>
            dark(settingsTheme)
              ? theme().palette.text.primary
              : theme().palette.text.secondary,
          backgroundColor: ({ settingsTheme }: { settingsTheme: string }) =>
            dark(settingsTheme) ? theme().palette.background.accent : 'none',
        },
      },
    },
  }
})

const getSelectStyle = (settingsTheme: string) => {
  const dark = () => {
    return settingsTheme === 'dark'
  }

  const selectStyle: StylesConfig<
    Record<string, unknown>,
    true,
    GroupTypeBase<Record<string, unknown>>
  > = {
    menu: (styles) => {
      return {
        ...styles,
        backgroundColor: dark() ? theme().palette.background.greyDark : 'white',
        borderRadius: '0 0 4px 4px',
        overflow: 'hidden',
        menuTop: 'hidden',
        marginTop: 0,
        cursor: 'pointer',
        zIndex: 10,
        '@media (prefers-contrast: more)': {
          border: `2px solid ${theme().palette.background.greyDark}`,
        },
      }
    },
    menuList: (styles: any) => ({
      ...styles,
      maxHeight: 440,
    }),
    container: (styles: any) => ({
      ...styles,
      fontFamily: theme().typography.fontFamily2,
    }),
    input: (styles: any) => ({
      ...styles,
      borderRadius: '4px',
      marginLeft: '15px',
    }),
    control: (styles, state) => ({
      ...styles,
      backgroundColor:
        settingsTheme === 'dark'
          ? theme().palette.background.greyDark
          : 'white',
      borderRadius: state.isFocused ? '4px 4px 0 0' : '4px',
      fontSize: '18px',
      minHeight: '46px',
      color: state.isFocused
        ? dark()
          ? theme().palette.text.invert
          : theme().palette.text.invert
        : dark()
        ? theme().palette.text.invert
        : theme().palette.text.primary,
      border: state.isFocused ? `1px solid white` : 'none',
      outline: state.isFocused
        ? `2px dotted ${theme().palette.background.greyDark}`
        : 'none',

      boxShadow: 'none',
      paddingLeft: theme().spacing(3),
      cursor: 'pointer',
      ':hover': {
        border: 'none',
        boxShadow: 'none',
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: dark()
        ? theme().palette.text.invert
        : theme().palette.text.primary,
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: dark()
        ? theme().palette.text.invert
        : theme().palette.text.primary,
    }),
    dropdownIndicator: (styles: any, state: any) => ({
      ...styles,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      transition: 'transform 0.15s ease-out',
      color: dark()
        ? theme().palette.text.invert
        : theme().palette.text.primary,
      ':focus': {
        Svg: {
          color: 'red',
        },
      },
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
    indicatorsContainer: (styles) => ({
      ...styles,
      marginRight: theme().spacing(2),
    }),
  }

  return selectStyle
}

export type SelectProps = RvG.IReactDefaultProps & {
  settings: ReactSelectProps
  onChange?: (item: any) => void
}

export default function Select({
  className,
  settings,
  ...props
}: SelectProps): ReactElement {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const classes = useStyles({
    settingsTheme: settings.settingsTheme ?? 'default',
  })
  const selectStyle = getSelectStyle(settings.settingsTheme ?? 'default')
  const intl = useIntl()

  useEffect(() => {
    if (menuIsOpen) {
      const options = ref?.current?.querySelectorAll('.select__option') || []
      for (let i = 0; i < options.length; i++) {
        setTimeout(() => {
          options[i].classList.add('is-visible')
        }, 50 * i)
      }
    }
  }, [menuIsOpen])

  const Options = (optionProps: OptionProps<any, any>) => {
    const { innerProps, children, innerRef, isFocused, isSelected } =
      optionProps
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={clsx({
          select__option: true,
          'is-focussed': isFocused,
        })}
        role="menuitem"
        aria-label={children}
        aria-selected={isSelected}
      >
        {children}
      </div>
    )
  }

  return (
    <div
      ref={ref}
      role="menu"
      aria-label={intl.formatMessage({ id: 'select.aria.label' })}
      aria-expanded={menuIsOpen ? 'true' : 'false'}
      aria-live={menuIsOpen ? 'assertive' : null}
    >
      <ReactSelect
        placeholder={intl.formatMessage({
          id: 'formfields.select.placeholder',
        })}
        {...settings}
        {...props}
        className={clsx(classes.root, className)}
        classNamePrefix="select"
        data-testid={'select'}
        styles={selectStyle}
        blurInputOnSelect
        isSearchable={false}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        components={{ Option: Options }}
        // menuIsOpen
      />
    </div>
  )
}

import React, { ReactElement, useMemo } from 'react'
import { Link } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'
import { INavItemData } from '@components/navigation/drawerNav'
import { Grid, Theme, useMediaQuery } from '@material-ui/core'

import useGlobalText from '@hooks/useGlobalText'
import SocialIcon from '@objects/socialicon'
import Container from '@objects/container'
import EndPlate from '@components/endPlate'
import BasketBar from '@objects/basketBar'
import clsx from 'clsx'
import useBasket from '@hooks/useBasket'
import { useLocation } from '@reach/router'
import { useStore } from '@nanostores/react'
import {
  $materialShopCart,
  $materialShopPaths,
} from '@services/stores/materialShop'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  footerRoot: {
    '& ul': {
      listStyleType: 'none',
      padding: '0',
      margin: '0',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-evenly',
    },
  },
  logos: {
    display: 'flex',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    '& li': {
      '&:not(:first-child)': {
        marginLeft: theme.spacing(6),
      },
    },
  },
  logo: {
    display: 'inline-block',
  },
  logoImg: {
    maxWidth: '100%',
    verticalAlign: 'top',
  },
  logoRvg: {
    width: theme.spacing(40),
  },
  logoMehrAchtung: {
    width: theme.spacing(20),
  },
  logoBmvi: {
    width: theme.spacing(20),
  },
  logoDvr: {
    height: theme.spacing(10),
  },
  footerWrapper: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(8),
  },
  footer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  nav: {
    ...theme.typography.navigationMeta,
  },
  navLinks: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  navLink: {
    position: 'relative',
    display: 'block',
    marginBottom: theme.spacing(6),
    borderBottom: '2px solid transparent',
    minWidth: 'fit-content',
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      marginBottom: 0,
    },
    '&:after': {
      content: `''`,
      position: 'absolute',
      left: 0,
      bottom: -2,
      width: '0%',
      height: '2px',
      backgroundColor: theme.palette.button.secondaryTextHover,
      transition: `width 0.3s ${theme.transitions.easing.easeInOut}`,
    },
    '&:hover': {
      '&:after': {
        width: '100%',
      },
    },
    '&:focus': {
      borderBottom: '2px dotted currentColor',
      outline: 'none',
    },
    '&:not(:first-child)': {
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(5),
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(15),
      },
    },
  },
  social: {
    textAlign: 'right',
    marginTop: theme.spacing(-3),
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  socialIcons: {
    alignItems: 'center',
  },
  socialIcon: {
    display: 'inline-block',
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(6),
      },
    },
    '&:hover': {
      color: theme.palette.text.hint,
    },
    '&:focus': {
      borderBottom: '2px dotted currentColor',
      outline: 'none',
    },
  },
  youtube: {
    fontSize: '50px',
    marginTop: '-7px',
  },
  instagram: {
    marginTop: '4px',
  },
  copyright: {
    color: theme.palette.text.input,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      marginTop: theme.spacing(15),
    },
  },
}))

export type FooterProps = {
  navItems: Array<INavItemData>
}

export default function Footer({ navItems }: FooterProps): ReactElement {
  const classes = useStyles()
  const { getText } = useGlobalText()
  const { basket, pathToCart, pathToCheckout, pathToShop } = useBasket()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const intl = useIntl()

  const materialShopCart = useStore($materialShopCart)
  const materialShopPaths = useStore($materialShopPaths)
  const hideBasketUrls = [
    pathToCart,
    pathToCheckout,
    materialShopPaths.cart,
    materialShopPaths.checkout,
    materialShopPaths.thanks,
    materialShopPaths.login,
  ]
  const location = useLocation()
  const basketBarConfig = useMemo(() => {
    if (
      !location.pathname.includes(pathToShop) &&
      materialShopCart.items.length > 0
    ) {
      return {
        hide:
          hideBasketUrls.includes(location.pathname) ||
          location.pathname.includes(pathToShop),
        count: materialShopCart.items.length,
        pathToCart: materialShopCart.path,
      }
    }

    return {
      hide:
        basket.length < 1 ||
        hideBasketUrls.includes(location.pathname) ||
        materialShopPaths.shop.includes(location.pathname),
      count: basket.length,
      pathToCart: pathToCart,
    }
  }, [basket, materialShopCart, pathToCart, location.pathname])

  return (
    <footer
      className={classes.footerRoot}
      role="contentinfo"
      aria-label={intl.formatMessage({ id: 'footer.aria.label' })}
    >
      <EndPlate />
      <Container className={classes.logos} type="nomargin">
        <ul>
          <li>
            <Link className={classes.logo} to="/">
              <img
                className={clsx(classes.logoImg, classes.logoRvg)}
                src="/img/logo/rvg_logo.svg"
                alt="RvG Logo"
              />
            </Link>
          </li>
          <li>
            <a
              className={classes.logo}
              href="https://www.mehrachtung.de"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={clsx(classes.logoImg, classes.logoMehrAchtung)}
                src="/img/logo/mehrAchtung.svg"
                alt="mehrAchtung Logo"
              />
            </a>
          </li>
          <li>
            <a
              className={classes.logo}
              href="https://bmdv.bund.de/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={clsx(classes.logoImg, classes.logoBmvi)}
                src="/img/logo/bmvi.svg"
                alt="BMVI Logo"
              />
            </a>
          </li>
          <li>
            <a
              className={classes.logo}
              href="https://www.dvr.de"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={clsx(classes.logoImg, classes.logoDvr)}
                src="/img/logo/DVR_Logo.png"
                alt="DVR Logo"
              />
            </a>
          </li>
        </ul>
      </Container>
      <Container
        className={classes.footerWrapper}
        background="light"
        type="nomargin"
      >
        <Grid component="footer" className={classes.footer} container>
          <Grid className={classes.nav} item md={9}>
            <nav
              aria-label={intl.formatMessage({
                id: 'footer.navigation.aria.label',
              })}
            >
              <ul className={classes.navLinks}>
                {navItems.map((item, i) => {
                  return (
                    <li key={i} className={classes.navLink}>
                      <Link to={item.path}>{item.title}</Link>
                    </li>
                  )
                })}
              </ul>
            </nav>
          </Grid>
          <Grid
            className={classes.social}
            container
            item
            xs={isDesktop && 5}
            md={3}
            wrap={'nowrap'}
          >
            <nav
              aria-label={intl.formatMessage({
                id: 'footer.socialmedia.links.aria.label',
              })}
            >
              <ul className={classes.socialIcons}>
                <li className={classes.socialIcon}>
                  <SocialIcon
                    label="Instagram Logo Icon"
                    item={{
                      name: 'Instagram',
                      href: getText('instagramLink'),
                      size: 'large',
                      className: classes.instagram,
                    }}
                  />
                </li>
                <li className={classes.socialIcon}>
                  <SocialIcon
                    label="Facebook Logo Icon"
                    item={{
                      name: 'Facebook',
                      href: getText('facebookLink'),
                      size: 'large',
                    }}
                  />
                </li>
                <li className={clsx(classes.socialIcon)}>
                  <SocialIcon
                    label="Youtube Logo Icon"
                    item={{
                      name: 'YouTube',
                      href: getText('youtubeLink'),
                      size: 'inherit',
                      className: classes.youtube,
                    }}
                  />
                </li>
              </ul>
            </nav>
          </Grid>
          <Grid
            component="p"
            className={classes.copyright}
            item
            xs={12}
            aria-label={intl.formatMessage({
              id: 'footer.copyright.aria.label',
            })}
          >
            {getText('copyright')}
          </Grid>
        </Grid>
        <BasketBar {...basketBarConfig} />
      </Container>
    </footer>
  )
}

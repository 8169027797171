import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import 'intersection-observer'
import { InView } from 'react-intersection-observer'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  inViewWrapper: {
    // height: '100%',
    opacity: 0.1,
    transform: 'translateY(40px)',
    transition: `transform 0s ${theme.transitions.easing.easeInOut}, opacity 0s ${theme.transitions.easing.easeInOut}`,
  },
  noTransition: {
    transition: `transform 0s ${theme.transitions.easing.easeInOut}, opacity 0s ${theme.transitions.easing.easeInOut}`,
  },
  inView: {
    '&$inViewWrapper': {
      opacity: 1,
      transform: 'none',
      // transform: 'translateY(0)',
      transition: `transform 1s ${theme.transitions.easing.easeInOut}, opacity 1s ${theme.transitions.easing.easeInOut}`,
    },
  },
  groupItem: {
    position: 'relative',
  },
}))

export type InViewAnimationProps = {
  children?: React.ReactNode
  group?: React.ReactNode[]
  style?: Record<string, unknown>
  className?: string
}

function InViewAnimation({
  children,
  group,
  style,
  className,
}: InViewAnimationProps): ReactElement {
  const classes = useStyles()
  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <div
          ref={ref}
          className={clsx(
            classes.inViewWrapper,
            {
              [classes.noTransition]: group,
              [classes.inView]: inView,
            },
            className
          )}
          style={style}
        >
          {group
            ? group.map((groupItem, i) => (
                <div
                  key={`inview-${i}`}
                  className={clsx(classes.inViewWrapper, classes.groupItem, {
                    [classes.inView]: inView,
                  })}
                  // 0s, 0.2s, 0.4s, 0.6s, ...
                  style={{ transitionDelay: `${i / 5}s`, zIndex: 100 - i }}
                >
                  {groupItem}
                </div>
              ))
            : children}
        </div>
      )}
    </InView>
  )
}

export { InView }
export default InViewAnimation

import { Theme } from '@material-ui/core'
import { MixinsOptions } from '@material-ui/core/styles/createMixins'

export default function Mixins(theme: Theme): MixinsOptions {
  const a = {
    cursor: 'pointer',
    color: theme.palette.error.main,
    fontWeight: 'bold',
    transition: 'color 0.2s ease-in-out',
    '&:focus': {
      color: theme.palette.text.primary,
      textDecoration: 'underline',
    },
    '&:hover': {
      color: theme.palette.error.light,
      textDecoration: 'none',
    },
  }

  return {
    article: {
      '& > ul, & > ol, & > p': {
        marginTop: 0,
        marginBottom: theme.spacing(8),
        [theme.breakpoints.up('lg')]: {
          marginBottom: theme.spacing(10),
        },
        '&:last-child': {
          marginBottom: 0,
        },
      },
      '& > h2, & > h3, & > h4': {
        margin: theme.spacing(15, 0, 6, 0),
        [theme.breakpoints.up('lg')]: {
          margin: theme.spacing(10, 0, 8, 0),
        },
      },
    },
    accordion: {
      '& > ul, & > ol, & > p': {
        marginTop: 0,
        marginBottom: theme.spacing(3),
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    overlayBase: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      content: '""',
    },
    linkStyle: {
      a,
    },
    linkStyleUnderlined: {
      position: 'relative',
      '&:focus': {
        outline: 'none',
        '& > span': {
          borderBottom: '2px dotted currentColor',
        },
      },
      paddingBottom: theme.spacing(1),
      backgroundImage: `linear-gradient(to bottom, transparent 20%, ${theme.palette.primary.main} 21%)`,
      backgroundPosition: '0 100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'calc(100% - 15px) 2px',
      transition: `background-size 0.3s ${theme.transitions.easing.easeInOut}`,
      '& > span': {
        paddingBottom: theme.spacing(1),
        backgroundImage: `linear-gradient(to bottom, transparent 20%, ${theme.palette.text.secondary} 21%)`,
        backgroundPosition: '0 100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '0% 2px',
        transition: `background-size 0.3s ${theme.transitions.easing.easeInOut}`,
      },
      '&:hover': {
        '& > span': {
          backgroundSize: '100% 2px',
        },
      },
    },
    containerSpacing: {
      marginBottom: theme.spacing(20),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(25),
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(30),
      },
    },
  }
}

import React, { ReactElement, useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Fade } from '@material-ui/core'
import { TypeButton } from '@material-ui/core/styles/createMuiTheme'
import Icon, { IconProps } from '@objects/icon'
import FontSize from '@config/theme/definitions/fontSize'
import { useIntl } from 'react-intl'

import Link from '@objects/link'
import { Link as GatsbyLink } from 'gatsby'

export const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(11.5),
    padding: theme.spacing(0.5),
    borderRadius: '100px',
    overflow: 'hidden',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    outline: 'none',
    background: 'none',
    fontFamily: 'inherit',
    lineHeight: 1.1,
    cursor: 'pointer',
    transition: `all ${theme.transitions.duration.shorter} ${theme.transitions.easing.easeInOut}`,
    position: 'relative',
    '& span': {
      position: 'relative',
      padding: theme.spacing(0.5, 0, 1),
    },
    '& span:after': {
      content: `''`,
      position: 'absolute',
      left: 0,
      bottom: -2,
      width: '0%',
      height: '2px',
      backgroundColor: theme.palette.button.secondaryTextHover,
      transition: `width 0.3s ${theme.transitions.easing.easeInOut}`,
    },
    '&:not(.disabled):hover span:after': {
      width: '100%',
    },
    '&.disabled': {
      backgroundColor: theme.palette.button.disabled,
      color: theme.palette.button.disabledText,
      borderColor: theme.palette.button.disabled,
      pointerEvents: 'none',
    },
    '&.disabled span': {
      borderColor: theme.palette.button.disabled,
      color: theme.palette.button.disabledText,
    },
  },
  fullwidth: {
    width: '100%',
  },
  main: {
    padding: theme.spacing(2, 6),
    ...theme.typography.buttonPrimary,
    backgroundColor: theme.palette.button.main,
    border: `2px solid ${theme.palette.button.main}`,
    borderRadius: '4px',
    color: theme.palette.button.mainText,
    '& span': {
      padding: theme.spacing(0, 0, 1),
    },
    '& span:before': {
      content: 'none',
      position: 'absolute',
      width: 'calc(100% - 4px)',
      height: 'calc(100% - 4px)',
      top: '2px',
      left: '2px',
      border: `1px solid ${theme.palette.button.mainTextHover}`,
      borderRadius: '4px',
      display: 'block',
    },
    '&:focus span:before': {
      content: `''`,
    },
    '&:not(.disabled):hover span': {
      borderColor: theme.palette.button.mainTextHoverInvert,
    },
    '& span:after': {
      backgroundColor: theme.palette.button.mainTextHover,
    },

    '&$invert': {
      backgroundColor: theme.palette.button.mainInvert,
      border: `2px solid ${theme.palette.button.mainInvert}`,
      color: theme.palette.button.mainTextInvert,

      '& span': {
        borderBottom: `2px solid ${theme.palette.button.mainInvert}`,
      },
      '& span:before': {
        border: `1px solid ${theme.palette.button.mainTextHoverInvert}`,
      },
      '&:not(.disabled):hover span': {
        borderColor: theme.palette.button.mainTextHoverInvert,
      },
    },

    '&$light': {
      backgroundColor: theme.palette.button.mainWhiteInvert,
      border: `2px solid ${theme.palette.button.mainWhiteInvert}`,
      color: theme.palette.button.mainWhiteTextInvert,

      '& span': {
        borderBottom: `2px solid ${theme.palette.button.mainWhiteInvert}`,
      },
      '& span:before': {
        border: `1px solid ${theme.palette.button.mainWhiteTextHoverInvert}`,
      },
      '& span:after': {
        backgroundColor: theme.palette.button.mainWhiteTextHoverInvert,
      },
      '&:not(.disabled):hover span': {
        borderColor: theme.palette.button.mainWhiteInvert,
      },
    },
    // '&:not(.disabled):hover span': {
    //   borderColor: theme.palette.button.mainTextHover,
    // },
  },
  invert: {},
  secondary: {
    height: '100%',
    padding: theme.spacing(2, 6),
    ...theme.typography.buttonPrimary,
    backgroundColor: theme.palette.button.secondary,
    border: `2px solid ${theme.palette.button.secondary}`,
    borderRadius: '4px',
    color: theme.palette.button.secondaryText,
    '& span': {
      borderBottom: `2px solid ${theme.palette.button.secondary}`,
      padding: theme.spacing(0, 0, 1),
    },
    '& span:before': {
      content: 'none',
      position: 'absolute',
      width: 'calc(100% - 4px)',
      height: 'calc(100% - 4px)',
      top: '2px',
      left: '2px',
      border: `1px solid ${theme.palette.button.secondaryTextHover}`,
      borderRadius: '4px',
      display: 'block',
    },
    '&:focus span:before': {
      content: `''`,
    },
  },
  tertiary: {
    height: '100%',
    padding: theme.spacing(2, 6),
    ...theme.typography.buttonPrimary,
    backgroundColor: theme.palette.button.tertiary,
    border: `2px solid ${theme.palette.button.tertiary}`,
    borderRadius: '4px',
    color: theme.palette.button.tertiaryText,
    '& span': {
      borderBottom: `2px solid ${theme.palette.button.tertiary}`,
      padding: theme.spacing(0, 0, 1),
    },
    '& span:before': {
      content: 'none',
      position: 'absolute',
      width: 'calc(100% - 4px)',
      height: 'calc(100% - 4px)',
      top: '2px',
      left: '2px',
      border: `1px solid ${theme.palette.button.tertiaryTextHover}`,
      borderRadius: '4px',
      display: 'block',
    },
    '&:focus span:before': {
      content: `''`,
    },
  },
  text: {
    ...theme.typography.buttonText,
    ...theme.typography.link,
    padding: 0,
    display: 'inline',
    color: theme.palette.text.default,
  },
  icon: {
    height: '54px',
    width: '54px',
    padding: 0,
    borderRadius: 0,
    backgroundColor: ({ invert }: StyleProps): string =>
      invert
        ? theme.palette.button.tertiaryText
        : theme.palette.button.tertiary,
    color: ({ invert }: StyleProps): string =>
      invert ? theme.palette.button.mainText : theme.palette.text.default,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      height: FontSize['4xl'],
      width: 'auto',
    },
    '& span:after': {
      width: '0 !important',
    },
    '& span:before': {
      content: 'none',
      position: 'absolute',
      width: 'calc(100% - 4px)',
      height: 'calc(100% - 4px)',
      top: '2px',
      left: '2px',
      border: `1px solid ${theme.palette.button.tertiaryTextHover}`,
      borderRadius: '4px',
      display: 'block',
    },
    '&:focus span:before': {
      content: `''`,
    },
    '&:not(.disabled):hover': {
      backgroundColor: ({ invert }: StyleProps): string =>
        invert
          ? theme.palette.button.tertiary
          : theme.palette.button.tertiaryText,
      color: ({ invert }: StyleProps): string =>
        invert
          ? theme.palette.button.tertiaryText
          : theme.palette.button.mainText,
      '& path.fill': {
        fill: ({ invert }: StyleProps): string =>
          invert
            ? theme.palette.button.tertiaryText
            : theme.palette.button.mainText,
      },
    },
  },
  play: {
    padding: theme.spacing(7),
    '& svg': {
      transform: 'translateX(10%)',
    },
  },
  underlined: {
    '& span': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(0, 0, 1),
      fontSize: FontSize['base'],
    },
    '& svg': {
      height: FontSize['3lg'],
      width: 'auto',
    },
  },
  backtop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 1,
    height: theme.spacing(11.5),
    width: theme.spacing(11.5),
    right: theme.spacing(8),
    bottom: theme.spacing(8),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(4),
      bottom: theme.spacing(3),
    },
    '&:focus': {
      '&::after': {
        content: '""',
        display: 'block',
        height: theme.spacing(10),
        width: theme.spacing(10),
        borderRadius: theme.spacing(1),
        top: '3px',
        left: '3px',
        position: 'absolute',
        border: `${theme.palette.background.focus} solid 1px`,
      },
    },
    '&:not(.disabled):hover': {
      backgroundColor: theme.palette.button.quaternaryHover,
      color: theme.palette.button.mainText,
    },
  },
  download: {
    // height: '100%',
    padding: theme.spacing(2, 6),
    ...theme.typography.buttonPrimary,
    backgroundColor: theme.palette.button.main,
    borderRadius: '4px',
    color: theme.palette.button.mainText,

    '& svg': {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    '& span:after': {
      backgroundColor: theme.palette.button.main,
    },
  },
  linkClasses: {
    '& ~ &': {
      marginTop: theme.spacing(2),
    },
  },
  iconLeft: {
    marginRight: theme.spacing(2),
    verticalAlign: '-2px',
  },
  iconRight: {
    marginLeft: theme.spacing(2),
    verticalAlign: '-2px',
  },
  disabled: {},
  disabledText: {},
  mainHover: {},
  mainText: {},
  mainTextHover: {},
  secondaryHover: {},
  secondaryText: {},
  secondaryTextHover: {},
  tertiaryHover: {},
  tertiaryText: {},
  tertiaryTextHover: {},
  light: {},
  label: {},
}))

interface IWrapper {
  children?: React.ReactChild | React.ReactNode | string
  className?: string
  hidden?: boolean | false
  to?: string
  backtop?: boolean | false
  isExternal?: boolean | false
  disabled?: boolean | false
  icon?: IconProps['name']
  iconFontSize?: number
  iconPosition?: 'left' | 'right'
  type:
    | keyof TypeButton
    | 'icon'
    | 'play'
    | 'underlined'
    | 'download'
    | 'label'
    | 'main'
  formSubmit?: boolean | false
  fullwidth?: boolean | false
  isVisible?: boolean | true
  invert?: boolean | false
  light?: boolean | false
  hideLinkIcon?: boolean | false
}

type StyleProps = {
  invert?: boolean
}

function Wrapper({
  children,
  className,
  type,
  fullwidth,
  icon,
  iconPosition,
  iconFontSize,
  hidden,
  isExternal,
  to,
  formSubmit,
  backtop,
  isVisible,
  hideLinkIcon,
  invert,
  light,
  ...props
}: IWrapper): ReactElement {
  const classes = useStyles({ invert })

  const btnClasses = clsx(className, classes.root, classes[type], {
    [classes.play]: type === 'icon' && icon === 'Play',
    [classes.fullwidth]: fullwidth,
    disabled: props.disabled,
    [classes.invert]: invert,
    [classes.light]: light,
  })

  const intl = useIntl()

  function scrollTop(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    // Changes focus to "#main" div at page top
    const id = e.currentTarget.getAttribute('href')
    if (typeof id === 'string') {
      const target = document.querySelector(id)
      if (target instanceof HTMLElement) {
        target.focus()
      }
    }
  }

  function scrollTo(to: string) {
    const el = document.querySelector(to)
    if (el) {
      window.scrollTo({
        top: el.getBoundingClientRect().y + window.scrollY - 100,
        behavior: 'smooth',
      })
    }
  }

  function renderIcon() {
    if (type === 'icon' && !!icon) {
      return <Icon name={icon} size="large" color="inherit" />
    } else {
      const name = icon ? icon : 'ChevronDown'

      const buttonClass =
        iconPosition !== 'right' ? classes.iconLeft : classes.iconRight

      if (name) {
        return (
          <Icon
            name={name}
            className={buttonClass}
            size="small"
            color="inherit"
            fontSize={iconFontSize}
          />
        )
      }
    }

    return null
  }

  if (hidden) {
    return <></>
  } else if (to && type !== 'icon' && type !== 'download') {
    if (to.startsWith('#')) {
      return (
        <button
          type="button"
          className={btnClasses}
          onClick={() => scrollTo(to)}
          data-testid={'buttonscroll'}
          {...props}
        >
          <span>{children}</span>
        </button>
      )
    }
    return (
      <Link
        to={to}
        className={clsx(btnClasses, classes.linkClasses)}
        isExternal={isExternal}
        hideIcon={hideLinkIcon}
        {...props}
      >
        {icon && iconPosition !== 'right' && renderIcon()}
        <span>{children}</span>
        {icon && iconPosition === 'right' && renderIcon()}
      </Link>
    )
  } else if (backtop) {
    return (
      <Fade in={isVisible} timeout={1000}>
        <a
          type="button"
          className={clsx(btnClasses, classes.backtop)}
          data-testid={'buttonbacktop'}
          {...props}
          onClick={scrollTop}
          aria-label={intl.formatMessage({
            id: 'navigation.backtotop.label',
          })}
          href="#main"
        >
          <Icon name="ChevronUp" size="default" />
        </a>
      </Fade>
    )
  } else if (type === 'icon') {
    if (to) {
      return (
        <GatsbyLink
          to={to}
          className={clsx(btnClasses, classes.icon)}
          {...props}
        >
          <span>{renderIcon()}</span>
        </GatsbyLink>
      )
    }
    return (
      <button
        type="button"
        className={clsx(btnClasses, classes.icon)}
        data-testid={'buttonicon'}
        {...props}
      >
        <span>{renderIcon()}</span>
      </button>
    )
  } else if (to && type === 'download') {
    return (
      <a
        className={clsx(btnClasses, classes.main)}
        href={to}
        target="_blank"
        rel="noreferrer"
      >
        <Icon name="Download" size="small" />
        <span>{children}</span>
      </a>
    )
  } else if ('label' === type) {
    return (
      <label
        className={clsx(btnClasses, classes.main)}
        data-testid={'buttondefault'}
        {...props}
      >
        {icon && iconPosition !== 'right' && renderIcon()}
        <span>{children}</span>
        {icon && iconPosition === 'right' && renderIcon()}
      </label>
    )
  } else {
    return (
      <button
        type={formSubmit ? 'submit' : 'button'}
        className={btnClasses}
        data-testid={'buttondefault'}
        {...props}
      >
        {icon && iconPosition !== 'right' && renderIcon()}
        <span>{children}</span>
        {icon && iconPosition === 'right' && renderIcon()}
      </button>
    )
  }
}

/**
 *
 * @description You can set "to" for internal links, "href" for external links or "link",
 * when object should determine by the given string, if it is internal or external.
 * If link is set and link includes "www." it is considered as external.
 * Otherwise if link is set on link does NOT include "www." it is considered as internal.
 */
export type ButtonProps = React.HTMLProps<HTMLButtonElement> & {
  children?: React.ReactChild | React.ReactNode | string
  className?: string
  hidden?: boolean | false
  to?: string
  backtop?: boolean | false
  isExternal?: boolean | false
  icon?: IconProps['name']
  iconPosition?: string | undefined
  type?:
    | keyof TypeButton
    | 'icon'
    | 'play'
    | 'underlined'
    | 'download'
    | 'label'
    | 'main'
  iconFontSize?: number
  formSubmit?: boolean | false
  fullwidth?: boolean | false
  disabled?: boolean | false
  invert?: boolean | false
  light?: boolean | false
  hideLinkIcon?: boolean | false
}

export default function Button({
  className,
  children,
  hidden,
  to,
  backtop,
  isExternal,
  icon,
  iconPosition,
  iconFontSize,
  type,
  formSubmit,
  fullwidth,
  ...props
}: ButtonProps): ReactElement {
  const [isVisible, setVisibility] = useState(false)

  useEffect(() => {
    if (!backtop) return
    setVisibility(false)
    document.addEventListener('scroll', toggleVisibility)
    return () => {
      document.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  function toggleVisibility() {
    if (window.pageYOffset > 50) {
      setVisibility(true)
    } else {
      setVisibility(false)
    }
  }

  return (
    <Wrapper
      className={className}
      hidden={hidden}
      to={to}
      backtop={backtop}
      icon={icon}
      iconPosition={iconPosition}
      type={type || 'main'}
      formSubmit={formSubmit}
      fullwidth={fullwidth}
      isVisible={isVisible}
      isExternal={isExternal}
      data-testid="button"
      iconFontSize={iconFontSize}
      {...props}
    >
      {children}
    </Wrapper>
  )
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@objects/button'
import Icon from '@objects/icon'
import clsx from 'clsx'
import Container from '@objects/container'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  basketBarRoot: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100vw',
    background: theme.palette.primary.main,
    zIndex: 1000,
    padding: theme.spacing(6, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8, 0),
    },
  },
  basketBarInner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  hideBasketBar: {
    display: 'none',
  },
  basketInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(3),
    '& svg .color': {
      fill: theme.palette.text.primary,
    },
    '& > span': {
      display: 'inline-block',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(0),
    },
  },
  basketLink: {},
  strong: {
    fontWeight: 'bold',
  },
}))

type BasketPropsType = {
  hide: boolean
  count: number
  pathToCart: string
}

export default function BasketBar({
  hide,
  count,
  pathToCart,
}: BasketPropsType): React.ReactElement {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <aside
      className={clsx(classes.basketBarRoot, {
        [classes.hideBasketBar]: hide,
      })}
      aria-label={intl.formatMessage({ id: 'shopping.cart.aria.label' })}
    >
      <Container className={classes.basketBarInner} type={'nomargin'}>
        <div className={classes.basketInfo}>
          <span>
            <Icon name={'Basket'} />
            {intl.formatMessage(
              { id: 'basketbar.count' },
              {
                count: count,
                a: function a(val) {
                  return <span className={classes.strong}>{val}</span>
                },
              }
            )}
          </span>
        </div>
        <div className={classes.basketLink}>
          <Button to={pathToCart}>
            {intl.formatMessage({ id: 'basketbar.button' })}
          </Button>
        </div>
      </Container>
    </aside>
  )
}

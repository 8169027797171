import React, { useState, useRef } from 'react'
import clsx from 'clsx'
import Img from 'gatsby-image'
import Copy from '@components/copy'

import { makeStyles } from '@material-ui/core/styles'

import { useStyles as useButtonStyles } from '@objects/button'
import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  ytRootGallery: {},
  ytRoot: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1024px',
      margin: '80px auto',
    },
  },
  ytWrapper: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: 'calc(9 / 16 * 100%)',
    overflow: 'hidden',
    '$thumbWrapper, & iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    '& .uc-embedding-container': {
      position: 'absolute',
      zIndex: 100,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '& img': {
        display: 'none',
      },
    },
  },
  thumbWrapper: {
    position: 'relative',
    color: theme.palette.secondary.dark,
  },
  playBtn: {
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  youTubeIcon: {
    fontSize: theme.spacing(20),
  },
  playText: {
    margin: theme.spacing(2, 0, 0, 0),
    ...theme.typography.buttonPrimary,
  },
  caption: {
    display: 'flex',
    marginTop: theme.spacing(2),
    ...theme.typography.caption,
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
  copyright: {
    marginLeft: 'auto',
  },
}))

export type YouTubeProps = RvG.IReactDefaultProps & {
  title?: string
  youTubeId?: string
  copyright?: string
  description?: RvG.Contentful.BasicRichTextType
  thumbnail?: RvG.Contentful.IAsset
  alt?: string
  thumbnailOnly?: boolean
  onThumbnailClick?: () => void
  isGalleryItem?: boolean
}

const YouTube = ({
  className,
  youTubeId,
  copyright,
  description,
  thumbnail,
  alt,
  thumbnailOnly,
  onThumbnailClick,
  isGalleryItem = false,
}: YouTubeProps) => {
  const classes = useStyles()
  const ytiFrame = useRef()
  const buttonClasses = useButtonStyles({})
  const [showiFrame, setShowiFrame] = useState(false)

  function initVideo() {
    if (typeof onThumbnailClick === 'function') {
      onThumbnailClick()
    }
    if (!thumbnailOnly) {
      ytiFrame?.current?.setAttribute(
        'src',
        ytiFrame.current.getAttribute('src') + '?autoplay=1'
      )
      setShowiFrame(true)
    }
  }

  return (
    <div
      className={clsx(
        isGalleryItem ? classes.ytRootGallery : classes.ytRoot,
        className
      )}
      data-testid={'youtube-video'}
    >
      <div className={classes.ytWrapper}>
        <iframe
          ref={ytiFrame}
          src={`https://www.youtube.com/embed/${youTubeId}`}
          frameBorder="0"
          allowFullScreen
        ></iframe>
        {!showiFrame && (
          <div className={classes.thumbWrapper} onClick={initVideo}>
            <Img
              fluid={
                thumbnail?.fluid
                  ? {
                      ...thumbnail.fluid,
                      media: `(min-width: 0px)`,
                    }
                  : {
                      aspectRatio: 1.7778,
                      src: `https://img.youtube.com/vi/${youTubeId}/maxresdefault.jpg`,
                      srcSet: `https://img.youtube.com/vi/${youTubeId}/maxresdefault.jpg`,
                      sizes: '',
                      media: `(min-width: 0px)`,
                    }
              }
              alt={alt || `Youtube Video ${youTubeId}`}
              backgroundColor={true}
            />
            <button className={clsx(buttonClasses.root, classes.playBtn)}>
              <Icon name="YouTubePlay" className={classes.youTubeIcon} />
            </button>
          </div>
        )}
      </div>
      {(description || copyright) && (
        <div className={classes.caption}>
          {description && <Copy richtext={description} />}
          {copyright && <div className={classes.copyright}>{copyright}</div>}
        </div>
      )}
    </div>
  )
}

export default YouTube

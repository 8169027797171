import React, { ReactElement } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

export type MetatagsProps = {
  meta: {
    metaTitle?: string
    metaDescription?: string
    metaOgtitle?: string
    metaOgdescription?: string
    metaOgimage?: string
    canonical?: string
    noIndex?: boolean
  }
  locale?: string
}

export default function Metatags({
  meta,
  locale,
}: MetatagsProps): ReactElement {
  const metaTagObject = []

  meta?.metaDescription &&
    metaTagObject.push({
      name: 'description',
      content: meta.metaDescription,
    })
  meta?.metaOgtitle &&
    metaTagObject.push({
      property: 'og:title',
      content: meta.metaOgtitle,
    })
  metaTagObject.push({
    name: 'twitter:card',
    content: 'summary',
  })
  meta?.metaOgdescription &&
    metaTagObject.push({
      property: 'og:description',
      content: meta.metaOgdescription,
    })
  meta?.metaOgimage &&
    metaTagObject.push({
      property: 'og:image',
      content: meta.metaOgimage,
    })
  meta?.metaOgimage &&
    metaTagObject.push({
      property: 'og:image:secure',
      content: meta.metaOgimage,
    })
  meta?.metaOgimage &&
    metaTagObject.push({
      property: 'twitter:image',
      content: meta.metaOgimage,
    })

  return (
    <GatsbySeo
      title={meta?.metaTitle}
      htmlAttributes={{
        language: locale,
      }}
      metaTags={metaTagObject}
      canonical={meta?.canonical}
      noindex={!!meta?.noIndex}
    />
  )
}

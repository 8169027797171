import React, { ReactElement } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useField, FieldInputProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from './checkbox'
import FormHelperText from './helperText'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    ...theme.typography.helpertext,
    marginRight: 'auto',
    '&.error': {
      color: theme.palette.error.main,
    },
  },
  checkboxOption: {
    alignItems: 'flex-start',
    ...theme.typography.checkboxOption,
    '&.error': {
      color: theme.palette.error.main,
    },
    '& $label': {
      lineHeight: 1.3,
      padding: theme.spacing(3, 0),
    },
  },
  link: {
    ...theme.mixins.linkStyleUnderlined,
    backgroundSize: '100% 2px',
    fontWeight: 700,
  },
  label: {},
}))

export type CheckboxFieldProps = Partial<FieldInputProps<''>> & {
  name: string
  label?: string | React.ReactElement
  labelId?: string
  labelLink?: string
  labelLinkB?: string
  value?: string
  isArray?: boolean | false
}

export default function CheckboxField(props: CheckboxFieldProps): ReactElement {
  const classes = useStyles()
  const intl = useIntl()
  const { label, labelId, labelLink, labelLinkB, value, isArray } = props
  const [field, meta] = useField(props)

  function getLabel() {
    if (label) {
      return label
    } else if (labelId) {
      return (
        <FormattedMessage
          id={labelId}
          values={{
            a: function terms(terms: string) {
              return labelLink ? (
                <a
                  className={classes.link}
                  href={intl.formatMessage({
                    id: labelLink,
                  })}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{terms}</span>
                </a>
              ) : (
                terms
              )
            },
            b: function terms(terms: string) {
              return labelLink ? (
                <a
                  className={classes.link}
                  href={intl.formatMessage({
                    id: labelLinkB,
                  })}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{terms}</span>
                </a>
              ) : (
                terms
              )
            },
          }}
        />
      )
    } else {
      return ''
    }
  }

  return (
    <>
      <FormControlLabel
        classes={{
          root: isArray ? classes.checkboxOption : classes.checkbox,
          label: classes.label,
        }}
        checked={isArray ? field.value.includes(value) : field.value}
        control={
          <Checkbox
            {...field}
            value={value}
            error={meta.touched && !!meta.error}
          />
        }
        label={getLabel()}
      />
      {meta.touched && meta.error && !isArray && (
        <FormHelperText type="error" name={props.name}>
          {meta.error}
        </FormHelperText>
      )}
    </>
  )
}

import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { FormHelperText as HelperText } from '@material-ui/core'

import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  helperTextRoot: {
    color: theme.palette.text.input,
    margin: theme.spacing(1, 0),
    ...theme.typography.helpertext,
    '&.error': {
      color: theme.palette.error.dark,
    },
    '& a': {
      ...theme.typography.link,
    },
  },
  spaced: {
    margin: theme.spacing(2, 4, 0, 0),
  },
  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.input,
    verticalAlign: '-5px',
  },
}))

export type FormHelperTextProps = RvG.IReactDefaultProps & {
  spaced?: boolean
  type?: string
  name?: string
}

export default function FormHelperText({
  children,
  className,
  spaced,
  type,
  name,
}: FormHelperTextProps): ReactElement {
  const classes = useStyles()
  const itemDescId = `${name
    ?.replace(/\s+|sections\[0\]./g, '')
    .toLowerCase()}_error_desc`

  return (
    <HelperText
      className={clsx(className, classes.helperTextRoot, {
        error: type === 'error',
        [classes.spaced]: spaced,
      })}
      style={{
        fontWeight: '700',
      }}
      aria-describedby={itemDescId}
    >
      {type === 'info' && <Icon className={classes.icon} name="Info" />}
      {children}
    </HelperText>
  )
}

import React, {
  useState,
  useRef,
  useEffect,
  ReactElement,
  KeyboardEvent,
} from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { INavItemData } from './drawerNav'
import NavItem from './horizontalNavItem'
import { useIntl } from 'react-intl'

interface IHorizontalNav {
  className: string
  items: INavItemData[]
  onOpen: (open: boolean) => void
}

const useStyles = makeStyles((theme) => ({
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
  },

  level3: {
    display: 'none',
    position: 'absolute',
    right: 0,
    top: -1,
    bottom: 0,
    padding: theme.spacing(5),
    overflow: 'hidden',
    '.active + &': {
      display: 'block',
    },
  },
  active: {
    display: 'block',
  },
}))

export default function HorizontalNav({
  className,
  items,
  onOpen,
}: IHorizontalNav): ReactElement {
  const classes = useStyles()
  const CompRef = useRef<HTMLElement>(null)
  const [focusIndex, setFocusIndex] = useState(-1)
  const [activeIndex, setActiveIndex] = useState(-1)
  const intl = useIntl()

  useEffect(() => {
    onOpen(activeIndex !== -1)
  }, [activeIndex])

  function handleClickOutside() {
    setFocusIndex(-1)
    setActiveIndex(-1)
  }

  function handleKeyDown(e: KeyboardEvent) {
    const curIndex = focusIndex === -1 ? 0 : focusIndex
    if (e.key === 'ArrowRight') {
      setFocusIndex((curIndex + 1) % items.length)
    } else if (e.key === 'ArrowLeft') {
      setFocusIndex(curIndex === 0 ? items.length - 1 : curIndex - 1)
    }
  }

  function renderNavList(items: INavItemData[]) {
    return items.map((item, i) => {
      return (
        <NavItem
          key={i}
          item={item}
          tabIndex={focusIndex === i || (i === 0 && focusIndex === -1) ? 0 : -1}
          isOpen={activeIndex === i}
          isFocused={focusIndex === i}
          toggleMenu={(open) => {
            setActiveIndex(!open || activeIndex === i ? -1 : i)
          }}
        />
      )
    })
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        handleClickOutside()
      }}
    >
      <nav ref={CompRef} className={clsx(className)}>
        <ul
          data-testid="list"
          className={classes.list}
          role="menu"
          aria-label={intl.formatMessage({
            id: 'horizontal.nav.list.aria.label',
          })}
          onKeyDown={handleKeyDown}
        >
          {renderNavList(items)}
        </ul>
      </nav>
    </ClickAwayListener>
  )
}

import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { INavItemData } from '@components/navigation/drawerNav'

import Icon from '@objects/icon'

export interface IMetaNav {
  className?: string
  items: INavItemData[]
  showLogos?: boolean
  toggleDrawer?: (open: boolean) => void
}

const useStyles = makeStyles((theme) => ({
  metaRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    [theme.breakpoints.up('xl')]: {
      height: 'auto',
      display: 'block',
    },
  },
  link: {
    margin: theme.spacing(4, 0),
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(0, 3),
    },
  },
  label: {
    display: 'inline-block',
    position: 'relative',
    borderBottom: '2px solid transparent',
    '&:after': {
      content: `''`,
      position: 'absolute',
      left: 0,
      bottom: -5,
      width: '0%',
      height: theme.spacing(0.5),
      backgroundColor: theme.palette.button.secondaryTextHover,
      transition: `width 0.3s ${theme.transitions.easing.easeInOut}`,
    },
    '&:hover, &$active': {
      '&:after': {
        width: '100%',
      },
    },
    '&:focus': {
      borderBottom: `2px dotted ${theme.palette.button.secondaryTextHover}`,
      outline: 'none',
      '&:after': {
        width: '0',
      },
    },
  },
  icon: {
    verticalAlign: theme.spacing(-1.5),
    marginRight: theme.spacing(1),
    color: theme.palette.text.hint,
  },
  logos: {
    display: 'flex',
    margin: theme.spacing('auto', -8, 0, -8),
  },
  logo: {
    marginRight: theme.spacing(4),
    '&:first-child': {
      marginRight: theme.spacing(10),
    },
  },
  logoImg: {
    height: theme.spacing(7),
    verticalAlign: 'top',
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing(10),
    },
  },
}))

export default function MetaNav({
  className,
  items,
  showLogos,
  toggleDrawer,
}: IMetaNav): ReactElement {
  const classes = useStyles()

  function closeMobileDrawer() {
    toggleDrawer && toggleDrawer(false)
  }

  return (
    <div className={clsx(className, classes.metaRoot)}>
      {items.map((item, i: number) => {
        return (
          <Link
            className={classes.link}
            key={i}
            to={item.path}
            onClick={closeMobileDrawer}
          >
            {item.icon && <Icon className={classes.icon} name={item.icon} />}
            <span className={classes.label}>{item.title}</span>
          </Link>
        )
      })}
      {showLogos && (
        <div className={classes.logos}>
          <Link className={classes.logo} to="/" onClick={closeMobileDrawer}>
            <img
              className={classes.logoImg}
              src="/img/logo/rvg_logo.svg"
              alt="Logo von Runter vom Gas"
            />
          </Link>
          <a
            className={classes.logo}
            href="https://bmdv.bund.de/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.logoImg}
              src="/img/logo/bmvi.svg"
              alt="Logo des Bundesministeriums für Digitales und Verkehr"
            />
          </a>
          <a
            className={classes.logo}
            href="https://www.dvr.de"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.logoImg}
              src="/img/logo/dvr.svg"
              alt="Logo des deutschen Verkehrssicherheitsrats (DVR)"
            />
          </a>
        </div>
      )}
    </div>
  )
}

import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { TypeBackground } from '@material-ui/core/styles/createPalette'

const useStyles = makeStyles((theme) =>
  createStyles({
    containerRoot: {
      ...theme.mixins.containerSpacing,
      backgroundColor: (props: ContainerProps): string | undefined =>
        props.background
          ? theme.palette.background[props.background]
          : undefined,
    },
    contentRoot: {
      maxWidth: (props: ContainerProps): string =>
        theme.container[props.width || 'lg'],
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: (props: ContainerProps): number =>
          theme.spacing(4 + (props.sidebar ? 92 : 0)),
      },
    },
    padded: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(20),
      marginTop: 0,
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(20),
        paddingBottom: theme.spacing(25),
      },
    },
    article: {
      ...theme.mixins.article,
      marginBottom: 0,
    },
    breadcrumb: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      marginBottom: 0,
    },
    nomargin: {
      marginBottom: 0,
    },
    top: {
      marginTop: theme.spacing(10),
    },
    spaced: {
      margin: theme.spacing(5, 'auto'),
    },
  })
)

export type ContainerProps = RvG.IReactDefaultProps & {
  padded?: boolean
  type?: 'article' | 'breadcrumb' | 'nomargin' | 'section'
  width?: keyof Theme['container']
  background?: keyof TypeBackground
  sidebar?: boolean
}

export default function Container({
  children,
  className,
  padded,
  type,
  width,
  background,
  sidebar,
  ...props
}: ContainerProps): React.ReactElement {
  const classes = useStyles({ width, sidebar, background })

  function RenderInner() {
    if (background || padded) {
      return <div className={clsx(classes.contentRoot)}>{children}</div>
    } else {
      return <>{children}</>
    }
  }

  let ContainerType

  switch (type) {
    case 'article':
      ContainerType = 'article'
      break
    case 'section':
      ContainerType = 'section'
      break
    default:
      ContainerType = 'div'
  }

  return (
    <ContainerType
      className={clsx(className, classes.containerRoot, {
        [classes.contentRoot]: !padded && !background,
        [type ? classes[type] : '']: !!type,
      })}
      {...props}
    >
      {RenderInner()}
    </ContainerType>
  )
}

import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'

import Headline from '@objects/headline'
import Copy from '@components/copy'
import Image from '@objects/image'

import { BigTeaserProps } from '../'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  contentWrapper: {
    position: 'relative',
    backgroundColor: ({ isLightTheme }: { isLightTheme: boolean }) =>
      isLightTheme
        ? theme.palette.background.light
        : theme.palette.background.greyDark,
    color: ({ isLightTheme }: { isLightTheme: boolean }) =>
      isLightTheme ? theme.palette.text.default : theme.palette.text.invert,

    [theme.breakpoints.up('lg')]: {
      width: '50%',
      height: 'auto',
      minHeight: '300px',
      borderBottomRightRadius: theme.spacing(2),
      transform: `translateY(10%)`,
      transition: `transform 1s ${theme.transitions.easing.easeInOut}`,
    },
  },
  contentInner: {
    padding: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(4),
      paddingLeft: `calc(100vw / 2 - ${theme.container.lg} / 2)`,
      paddingRight: theme.spacing(10),
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
      transform: 'translateY(-10%)',
      transition: `transform 0.6s ${theme.transitions.easing.easeInOut}`,
    },
  },
  headlineAnim: {
    opacity: 0,
    transform: 'translateY(10%)',
    transition: `opacity 1s ${theme.transitions.easing.easeInOut} 0s, transform 1s ${theme.transitions.easing.easeInOut} 0s`,
  },
  copyAnim: {
    opacity: 0,
    transform: 'translateY(10%)',
    transition: `opacity 1s ${theme.transitions.easing.easeInOut} 0.2s, transform 1s ${theme.transitions.easing.easeInOut} 0.2s`,
  },
  ctaAnim: {
    opacity: 0,
    transform: 'translateY(10%)',
    transition: `opacity 1s ${theme.transitions.easing.easeInOut} 0.4s, transform 1s ${theme.transitions.easing.easeInOut} 0.4s`,
  },
  inView: {
    '&$contentWrapper': {
      [theme.breakpoints.up('lg')]: {
        transform: `translateY(${theme.spacing(-4)}px)`,
      },
    },
    '& $contentInner': {
      transform: 'translateY(0%)',
    },
    '& $copyAnim, & $headlineAnim, & $ctaAnim': {
      transform: 'translateY(0%)',
      opacity: 1,
    },
  },
  contentSiding: {
    position: 'absolute',
    top: theme.spacing(-10),
    height: theme.spacing(10),
    zIndex: 1,
    width: '100%',
    backgroundImage: ({ isLightTheme }: { isLightTheme: boolean }) =>
      isLightTheme
        ? `linear-gradient(to right top, ${theme.palette.background.light} 0%, ${theme.palette.background.light} 50%, transparent 51%)`
        : `linear-gradient(to right top, ${theme.palette.background.greyDark} 0%, ${theme.palette.background.greyDark} 50%, transparent 51%)`,
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      width: '50px',
      top: 0,
      right: theme.spacing(-6),
      transform: 'skew(-6deg)',
      transition: `transform 1s ${theme.transitions.easing.easeInOut} 0.2s`,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      backgroundColor: ({ isLightTheme }: { isLightTheme: boolean }) =>
        isLightTheme
          ? theme.palette.background.light
          : theme.palette.background.greyDark,
    },
  },
  copy: {
    ...theme.typography.body1,
  },
  image: {
    margin: '0 !important',
    [theme.breakpoints.down('md')]: {
      '& .gatsby-image-wrapper': {
        height: 'auto !important',
      },
    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      overflow: 'hidden',
    },
  },
}))

export default function BigTeaserContent({
  headline,
  copy,
  cta,
  imageFocalPoint,
  image,
  mobileImage,
  theme,
}: BigTeaserProps): ReactElement {
  const isLightTheme = theme === 'Light'
  const classes = useStyles({ isLightTheme })
  const muiTheme = useTheme()
  const isLarge = useMediaQuery(muiTheme.breakpoints.up('sm'))
  const contentRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const imageRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const { ref, inView, entry } = useInView({
    triggerOnce: true,
  })
  const intl = useIntl()

  function handleResize() {
    const { height } = contentRef.current.getBoundingClientRect()
    const img: HTMLElement | null = imageRef.current.querySelector(
      '.gatsby-image-wrapper'
    )
    if (img) {
      img.style.height = height + 'px'
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <section
      className={classes.root}
      role="region"
      aria-label={intl.formatMessage({ id: 'big.teaser.aria.label' })}
    >
      <div ref={imageRef}>
        <Image
          className={classes.image}
          /* image={[
            mobileImage?.fluid,
            { ...image.fluid, media: `(min-width:768px)` },
          ]} */
          image={image.fluid}
          focalPoint={imageFocalPoint}
          imgStyle={{ margin: '0' }}
          ariaLabel={intl.formatMessage({ id: 'big.teaser.image.aria.label' })}
        />
      </div>
      <div
        ref={contentRef}
        className={clsx(classes.contentWrapper, {
          [classes.inView]: inView,
        })}
      >
        <div ref={ref} className={clsx(classes.contentInner)}>
          <Headline className={classes.headlineAnim}>{headline}</Headline>
          <Copy
            className={clsx(classes.copy, classes.copyAnim)}
            richtext={copy}
          />
          <Copy
            className={clsx(classes.copy, classes.ctaAnim)}
            richtext={cta}
            type="button"
            theme={isLightTheme ? 'light' : 'dark'}
          />
        </div>
        <div className={classes.contentSiding} />
      </div>
    </section>
  )
}

import React from 'react'
import Button from '@objects/button'
import useToggleComp from '@hooks/useToggleComp'
import Grid from '@material-ui/core/Grid'
import Img, { FluidObject } from 'gatsby-image'
import Copy from '@components/copy'
import Headline from '@objects/headline'
import { makeStyles } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { IDownloadShopOverlayProps } from '@objects/overlay/types/downloadShop'

export type DownloadShopTeaserProps = RvG.IReactDefaultProps &
  IDownloadShopOverlayProps

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.button.disabled}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(6, 24),
    },
  },
  imgGridContainer: {
    // paddingRight: theme.spacing(3),
    // [theme.breakpoints.up('lg')]: {
    //   paddingRight: theme.spacing(8),
    // }
  },
  copyGridContainer: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
    },
  },
}))

export default function DownloadShopTeaser({
  ...props
}: DownloadShopTeaserProps) {
  const classes = useStyles()
  const { toggleOverlay } = useToggleComp()
  const muiTheme = useTheme()
  const isLg = useMediaQuery(muiTheme.breakpoints.up('lg'))

  const downloadOverlay = () => {
    toggleOverlay(true, {
      type: 'downloadShop',
      paperProps: { className: 'dark' },
      downloadProps: {
        ...props,
      },
      closeButton: 'hide',
    })
  }

  return (
    <>
      <Grid className={classes.root} container>
        <Grid className={classes.imgGridContainer} item xs={6} lg={4}>
          <Img
            fluid={
              {
                ...props.thumbnails[0].fluid,
                aspectRatio: 152 / 116,
              } as FluidObject
            }
            alt={''}
          />
        </Grid>
        <Grid item xs={6} lg={8} className={classes.copyGridContainer}>
          <Headline level={5}>{props.headline}</Headline>
          {props.copy && <Copy richtext={props.copy} />}
          {isLg && (
            <Button
              type={'underlined'}
              icon={'ChevronRight'}
              iconPosition={'right'}
              onClick={downloadOverlay}
            >
              {props.amount?.length > 0 ? (
                <FormattedMessage id={'media.shop.openOverlay'} />
              ) : (
                <FormattedMessage id={'media.download.openOverlay'} />
              )}
            </Button>
          )}
        </Grid>
        {!isLg && (
          <Grid item xs={12}>
            <Button
              type={'underlined'}
              icon={'ChevronRight'}
              iconPosition={'right'}
              onClick={downloadOverlay}
            >
              {props.amount?.length > 0 ? (
                <FormattedMessage id={'media.shop.openOverlay'} />
              ) : (
                <FormattedMessage id={'media.download.openOverlay'} />
              )}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  )
}

import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { useToggleComp } from '@hooks/index'
import { FormattedMessage } from 'react-intl'
import Container from '@objects/container'
import Grid from '@material-ui/core/Grid'
import Image from '@objects/image'
import Headline from '@objects/headline'
import Copy from '@components/copy'
import Button from '@objects/button'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  rootQuizz: {
    position: 'relative',
    padding: theme.spacing(10, 0),
    background: theme.palette.grey[100],
    '&.with-image': {
      background: 'none',
    },
  },
  contentInner: {
    '&.with-image': {
      paddingLeft: 0,
      paddingRight: 0,
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
  },

  contentWrapper: {
    position: 'relative',
  },
  imageContent: {
    [theme.breakpoints.up('md')]: {
      // maxWidth: '40%',
    },
  },
  image: {
    width: '100%',
    margin: 0,
  },
  textContent: {},
  textContentInner: {
    position: 'relative',
    // padding: theme.spacing(15, 24),
    marginTop: '-30px',
    background: theme.palette.grey[100],
    padding: theme.spacing(6, 8),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 16),
      marginTop: '0px',
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
    },
    '& div:first-child': {
      display: 'block',
    },
  },
  textContentClip: {
    display: 'none',
    top: 0,
    transform: 'skew(-4.8deg)',
    right: '-40px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    [theme.breakpoints.up('md')]: {
      right: 'initial',
      left: '-40px',
      transform: 'skew(4.8deg)',
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    width: '80px',
    height: '100%',
    zIndex: 1,
    position: 'absolute',
    background: theme.palette.grey[100],
  },
  headline: {},
  copy: {},
  endscreenImage: {},

  button: {
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    '& + &': {
      marginLeft: theme.spacing(3),
    },
    '& span::after': {
      background: theme.palette.text.primary,
    },
  },
}))

export type IQuizKnowledgeProps = RvG.IReactDefaultProps & {
  type?: string
  image?: RvG.Contentful.IAsset
  imageFocalPoint?: RvG.Contentful.imageFocalPoint
  headline?: string
  copy?: RvG.Contentful.BasicRichTextType
  endscreenImage?: RvG.Contentful.IAsset
  endscreenSuccessNumber?: number
  endscreenCopySuccess?: RvG.Contentful.BasicRichTextType | string
  endscreenCopyFailed?: RvG.Contentful.BasicRichTextType | string
  questions?: Array<RvG.Contentful.IContentfulModuleQuizKnowledgeQuestion>
}

export default function QuizKnowledge({
  image,
  imageFocalPoint,
  headline,
  copy,
  endscreenImage,
  endscreenSuccessNumber,
  endscreenCopySuccess,
  endscreenCopyFailed,
  questions,
}: IQuizKnowledgeProps): ReactElement {
  const classes = useStyles()
  const theme = useTheme()
  const { toggleOverlay } = useToggleComp()

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <section
      className={clsx(classes.rootQuizz, { 'with-image': image })}
      id={`quiz-${headline
        ?.toLocaleLowerCase()
        .replace(/\s/g, '')
        .replace(/[^0-9a-zA-Z:,]+/g, '')}`}
    >
      <Container
        className={clsx(classes.contentInner, { 'with-image': image })}
        type="nomargin"
      >
        <Grid
          container
          alignItems="center"
          spacing={0}
          className={classes.contentWrapper}
        >
          {image && (
            <Grid
              alignItems="center"
              item
              xs={12}
              md={4}
              className={classes.imageContent}
            >
              <Image
                image={
                  {
                    ...image.fluid,
                    aspectRatio: isSmall ? 4 / 3 : 3 / 5,
                  } as RvG.Contentful.IFluidImage
                }
                focalPoint={imageFocalPoint || 'Center'}
                alt="Quiz-Head"
                className={classes.image}
              />
            </Grid>
          )}
          <Grid
            item
            xs={10}
            md={image ? 8 : 12}
            className={clsx({ 'with-image': image })}
          >
            <div className={classes.textContentInner}>
              <div className={classes.textContentClip} />
              {headline && (
                <Headline className={classes.headline}>{headline}</Headline>
              )}

              {copy && (
                <Copy
                  className={clsx(classes.copy, classes.copy)}
                  richtext={copy}
                />
              )}

              <Button
                className={classes.button}
                onClick={() => {
                  toggleOverlay(true, {
                    type: 'quiz knowledge',
                    quizKnowledgeProps: {
                      endscreenImage,
                      endscreenSuccessNumber,
                      endscreenCopySuccess,
                      endscreenCopyFailed,
                      questions,
                    },
                  })
                }}
              >
                <FormattedMessage id="quiz.button.start" />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

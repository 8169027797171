import { Theme } from '@material-ui/core'
import { Overrides } from '@material-ui/core/styles/overrides'
import FontFamily from '../definitions/fontFamily'

export default function BaselineOverrides(
  theme: Theme
): Overrides['MuiCssBaseline'] {
  return {
    '@global': {
      '@font-face': FontFamily,
      body: {},
      a: {
        color: 'inherit',
        textDecoration: 'none',
        transitionProperty: 'all',
        transitionDuration: theme.transitions.duration.standard,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
      },
      '.uc-embedding-accept': {
        background: `${theme.palette.button.tertiary} !important`,
        color: `${theme.palette.button.tertiaryText} !important`,
        '&:hover': {
          background: `${theme.palette.button.tertiaryHover} !important`,
          color: `${theme.palette.button.tertiaryTextHover} !important`,
        },
      },
      '.uc-embedding-more-info': {
        background: `${theme.palette.button.main} !important`,
        color: `${theme.palette.button.mainText} !important`,
        '&:hover': {
          background: `${theme.palette.button.mainHover} !important`,
          color: `${theme.palette.button.mainTextHover} !important`,
        },
      },
    },
  }
}

import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  input: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 0,
      },
      '&.Mui-focused': {
        '& .MuiInputBase-input': {
          borderColor: theme.palette.text.input,
        },
      },
      '&.Mui-error': {
        '& .MuiInputBase-input': {
          borderColor: theme.palette.error.dark,
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.dark,
        },
      },
      '&.Mui-disabled': {
        '& .MuiInputBase-input': {
          backgroundColor: theme.palette.background.light,
          borderColor: theme.palette.text.input,
        },
      },
    },
    '& .MuiInputBase-input': {
      appearance: 'none',
      ...theme.typography.input,
    },
    '& .Mui-focused': {
      '& .MuiInputBase-input': {},
    },
    '& .MuiInput-underline:after, & .MuiInput-underline:before': {},
    '& input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0px 1000px rgba(0,0,0,0) inset',
      transition: 'background-color 5000s ease-in-out 0s',
    },
  },
}))

export type TextInputProps = React.HTMLProps<HTMLInputElement> &
  TextFieldProps & {
    className?: string | ''
    id: string
    error?: string | boolean | false
    type?: string | 'text'
  }

export default function TextInput({
  className,
  id,
  type,
  ...props
}: TextInputProps): ReactElement {
  const classes = useStyles()

  return (
    <TextField
      className={clsx(className, classes.input)}
      data-testid="input"
      id={id}
      type={type}
      {...props}
    />
  )
}
